import * as React from 'react';
import { SVGProps } from 'react';

export const TextUnderlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect x="4" y="26" width="24" height="2" />
    <path d="M16,23a7,7,0,0,1-7-7V5h2V16a5,5,0,0,0,10,0V5h2V16A7,7,0,0,1,16,23Z" />
    <rect
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      className="cls-1"
      width="32"
      height="32"
      fill="none"
    />
  </svg>
);
