import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import classes from './TooltipFilter.module.scss';
import { Button } from 'components/shared/Button';
import { SortOneIcon } from 'components/shared/Icons/SortOneIcon';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

type TooltipFilterProps = {
  input?: React.ReactNode;
  open?: boolean;
  hideSort?: boolean;
  onSortAscending?: () => void;
  onSortDescending?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
  additionalClass?: boolean;
  position?: 'top' | 'bottom' | 'right' | 'left';
  t: TFunction;
};

function TooltipFilter(props: TooltipFilterProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onClickOutSide = function (event: Event) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        props.onClose?.();
      }
    };

    document.addEventListener('mousedown', onClickOutSide);

    return () => document.removeEventListener('mousedown', onClickOutSide);
  }, [contentRef, props.onClose]);

  return (
    <div ref={wrapperRef}>
      <TooltipContainer
        openOnClick
        open={props.open}
        text={
          <div ref={contentRef}>
            {!props.hideSort && (
              <div
                className={clsx(
                  classes.sort,
                  !props.input && classes['border-none']
                )}
              >
                <Button
                  theme="text"
                  className={classes.sort__item}
                  onClick={props.onSortAscending}
                >
                  <SortOneIcon />
                  <span>{props.t('Sort ascending')}</span>
                </Button>
                <Button
                  theme="text"
                  className={clsx(
                    classes.sort__item,
                    classes['sort__item--down']
                  )}
                  onClick={props.onSortDescending}
                >
                  <SortOneIcon />
                  <span>{props.t('Sort descending')}</span>
                </Button>
              </div>
            )}
            {props.input && (
              <div
                className={clsx(
                  classes.content,
                  props.hideSort && classes['border-none']
                )}
              >
                {props.input}
              </div>
            )}
          </div>
        }
        position={props.position || 'bottom'}
        className={classes.tooltipContainer}
        classNameTooltip={clsx(classes.tooltip, props.additionalClass)}
      >
        <div ref={triggerRef}>{props.children}</div>
      </TooltipContainer>
    </div>
  );
}

export default TooltipFilter;
