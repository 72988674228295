import { CopyIcon } from 'components/shared/Icons/CopyIcon';
import classes from './BusinessFooter.module.scss';
import { BusinessFooterProps } from './BusinessFooter.props';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const BusinessFooter = ({ partner, handleCopy }: BusinessFooterProps) => {
  const { t } = useTranslation();
  const [copiedId, setCopiedId] = useState<string | null>(null);

  const showTooltip = (id: string) => {
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 3000);
  };

  return (
    <div className={classes.footer}>
      <div className={classes.title}>{t('Legal entities')}</div>
      <div className={classes.description}>
        {t('Your legal entities signed agreements with KIT Global')}
      </div>

      <ul className={classes.companyList}>
        {partner?.legal_entities?.map((item: any) => (
          <li key={item.name} className={classes.companyItemWrapper}>
            <div className={classes.companyItem}>
              <div>
                <p className={classes.companyNameLabel}>
                  {t('Legal entity for Account')}
                </p>
                <p className={classes.companyName}>{item.name}</p>
              </div>
              <div className={classes.copyWrapper}>
                <CopyIcon
                  className={classes.copyIcon}
                  onClick={() => {
                    handleCopy(item.name);
                    showTooltip(`name-${item.name}`);
                  }}
                />
                {copiedId === `name-${item.name}` && (
                  <div className={classes.tooltip}>{t('Copied')}</div>
                )}
              </div>
            </div>
            <div className={classes.companyItem}>
              <div>
                <p className={classes.companyNameLabel}>{t('GSTIN')}</p>
                <p className={classes.companyName}>{item.tax_id}</p>
              </div>
              <div className={classes.copyWrapper}>
                <CopyIcon
                  className={classes.copyIcon}
                  onClick={() => {
                    handleCopy(item.tax_id);
                    showTooltip(`tax-${item.tax_id}`);
                  }}
                />
                {copiedId === `tax-${item.tax_id}` && (
                  <div className={classes.tooltip}>{t('Copied')}</div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BusinessFooter;
