import { RegistrationContainer } from '../containers/Registration';
import '../styles/main.scss';
import { RegistrationRUContainer } from 'containers/RegistrationRU';
import { RUSSIAN_LOCALE } from 'utils';

// todo добавить функцию локализации
export const Registration = () => {
  if (RUSSIAN_LOCALE && process.env.REACT_APP_ENABLE_KID) {
    return <RegistrationRUContainer />;
  }

  return (
    <>
      <RegistrationContainer />
    </>
  );
};
