import { AdminGradientIcon } from './AdminGradientIcon';
import { AdminGradientIconActive } from './AdminGradientIconActive';
import { ArrowDownIcon } from './ArrowDownIcon';
import { ArrowIcon } from './ArrowIcon';
import { ArrowPagination } from './ArrowPagination';
import { ArrowRightIcon } from './ArrowRightIcon';
import { BasketGradientIcon } from './BasketGradientIcon';
import { CalendarGradientIcon } from './CalendarGradientIcon';
import { EmptyIcon } from './EmptyIcon';
import { InfoIcon } from './InfoIcon';
import { HintIcon } from './HintIcon';
import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';
import { CrossIcon } from './CrossIcon';
import { CheckIcon } from './CheckIcon';
import FilterMobileTableIcon from './FilterMobileTableIcon';
import { EditIcon } from './EditIcon';
import { DotIcon } from './DotIcon';
import { HelpIcon } from './HelpIcon';
import { RemoveIcon } from './RemoveIcon';
import { PaperClipIcon } from './PaperClipIcon';
import { SearchIcon } from './SearchIcon';
import { SearchIconAlt } from './SearchIconAlt';
import { InfoIconAlt } from './AltInfoIcon';
import { H1Icon } from './H1Icon';
import { H2Icon } from './H2Icon';
import { H3Icon } from './H3Icon';
import { BulletListIcon } from './BulletListIcon';
import { NumberListIcon } from './NumberListIcon';
import { QuotesIcon } from './QuotesIcon';
import { RedoIcon } from './RedoIcon';
import { TextBoldIcon } from './TextBoldIcon';
import { TextClearFormat } from './TextClearFormat';
import { TextItalicIcon } from './TextItalicIcon';
import { TextStrikeIcon } from './TextStrikeIcon';
import { TextUnderlineIcon } from './TextUnderlineIcon';
import { UndoIcon } from './UndoIcon';
import { TextEditorClearIcon } from './TextEditorClearIcon';
import { ExclamationIcon } from './ExclamationIcon';
import { ClockIcon } from './ClockIcon';
import { CheckIconSecond } from './CheckIconSecond';

export default {
  AdminGradientIcon,
  AdminGradientIconActive,
  ArrowDownIcon,
  ArrowIcon,
  ArrowPagination,
  ArrowRightIcon,
  BasketGradientIcon,
  CalendarGradientIcon,
  EmptyIcon,
  InfoIcon,
  HintIcon,
  MinusIcon,
  PlusIcon,
  CrossIcon,
  CheckIcon,
  ClockIcon,
  FilterMobileTableIcon,
  EditIcon,
  DotIcon,
  HelpIcon,
  RemoveIcon,
  PaperClipIcon,
  SearchIcon,
  SearchIconAlt,
  InfoIconAlt,
  H1Icon,
  H2Icon,
  H3Icon,
  BulletListIcon,
  NumberListIcon,
  QuotesIcon,
  RedoIcon,
  TextBoldIcon,
  TextClearFormat,
  TextItalicIcon,
  TextStrikeIcon,
  TextUnderlineIcon,
  UndoIcon,
  TextEditorClearIcon,
  ExclamationIcon,
  CheckIconSecond,
};
