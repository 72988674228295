import { LoginContainer } from 'containers/Login';
import '../styles/main.scss';
import { LoginContainerRu } from 'containers/LoginRu';
import { RUSSIAN_LOCALE } from 'utils';

// todo добавить функцию локализации
export const Login = () => {
  if (process.env.REACT_APP_ENABLE_KID === 'true') {
    if (RUSSIAN_LOCALE) {
      return <LoginContainerRu />;
    }
  }

  return <LoginContainer />;
};
