import MobileFilterModal from 'components/common/MobileFilterModal';
import { Button } from 'components/shared/Button';
import CheckboxFilterList from 'components/shared/CheckboxFilterList';
import StyledDatePicker from 'components/shared/DatePicker';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import FilterMobileTableIcon from 'components/shared/Icons/FilterMobileTableIcon';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import { Input } from 'components/shared/Input';
import { Pagination } from 'components/shared/Pagination';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { getPaymentDateMessage } from '../invoiceData';
import classes from './InvoicesList.module.scss';
import { InvoicesListProps } from './InvoicesList.props';
import { useTranslation } from 'react-i18next';
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';

function InvoicesList({
  invoices,
  handleGlobalSearch,
  handleSort,
  handlePeriodChange,
  pageCount,
  handlePaymentClick,
}: InvoicesListProps) {
  const { t } = useTranslation();
  const sortingOptions = [
    { value: 'date_asc', label: t('New ones first') },
    { value: 'date_desc', label: t('Old ones first') },
    { value: 'amount_asc', label: t('Ascending price') },
    { value: 'amount_desc', label: t('Descending price') },
  ];

  const actionFilterOptions = [
    { value: 'paid', label: t('Paid') },
    { value: 'not_paid', label: t('Pay now') },
  ];

  const [checkedActions, setCheckedActions] = useState<string[]>([]);
  const [startPeriod, setStartPeriod] = useState<Date | null>(null);
  const [endPeriod, setEndPeriod] = useState<Date | null>(null);
  const [selectedSort, setSelectedSort] = useState<{
    value: string;
    label: string;
  }>(sortingOptions[0]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);

  const callbacks = {
    onPeriodChange: ([newStart, newEnd]: [Date | null, Date | null]) => {
      setStartPeriod(newStart);
      setEndPeriod(newEnd);
    },
    onSearch: (value: string) => {
      setGlobalSearch(value);
      debounce(() => handleGlobalSearch?.(value), 1000)();
    },
  };

  useEffect(() => {
    handleSort?.(selectedSort.value);
  }, [selectedSort]);

  useEffect(() => {
    handlePeriodChange?.(startPeriod, endPeriod);
  }, [endPeriod]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.filters}>
          <button
            className={classes.filters__btn}
            onClick={() => setOpenSortModal(true)}
          >
            <span>{selectedSort.label}</span>
            <FilterMobileTableIcon />
          </button>

          <button
            className={classes.filters__btn}
            onClick={() => setOpenSearchModal(true)}
          >
            <SearchIcon />
          </button>
          <button
            className={classes.filters__btn}
            onClick={() => setOpenFilterModal(true)}
          >
            <FilterTableIcon />
          </button>
        </div>

        <ul className={classes['invoices-list']}>
          {invoices.map((invoice) => (
            <li key={invoice.id} className={classes.invoice}>
              <span className={classes.invoice__date}>
                {`${getFormattedDate(
                  new Date(invoice.date)
                )}, ${getFormattedTime(new Date(invoice.date))}`}
              </span>

              {invoice.client && (
                <div className={classes.invoice__client}>
                  <span className={classes.invoice__client_name}>
                    {invoice.client.name}
                  </span>
                  <span className={classes.invoice__client_num}>
                    ID {invoice.client.number}
                  </span>
                </div>
              )}

              <div className={classes.invoice__number}>
                <span>{invoice.invoice.number}</span>
                <Button theme="text">
                  <DownloadIcon />
                </Button>
              </div>

              <span className={classes.invoice__description}>
                {invoice.invoice.description}
              </span>

              <div className={classes.invoice__payment}>
                <div>
                  <span className={classes.invoice__payment_price}>
                    <FormatPrice
                      price={invoice.total}
                      currency={invoice.currency.symbol}
                    />
                  </span>
                  <span className={classes.invoice__payment_diff}>
                    {getPaymentDateMessage(
                      invoice,
                      new Date(invoice?.paid_date || ''),
                      invoice.diff_days,
                      t
                    )}
                  </span>
                </div>

                <Button
                  theme="primary"
                  className={classes.invoice__payment_btn}
                  disabled={invoice.status === 'PAID'}
                  onClick={handlePaymentClick}
                >
                  {t(`${invoice.status === 'PAID' ? 'Paid' : 'Pay now'}`)}
                </Button>
              </div>
            </li>
          ))}
        </ul>

        {pageCount > 1 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={currentPage}
              totalPages={pageCount}
              setPageNumber={setCurrentPage}
              nextPage={() =>
                currentPage > 0 &&
                currentPage < pageCount &&
                setCurrentPage(currentPage + 1)
              }
              prevPage={() =>
                currentPage !== 0 && setCurrentPage(currentPage - 1)
              }
            />
          </div>
        )}
      </div>

      {openSortModal && (
        <MobileFilterModal
          title={t('Sort')}
          onClose={() => setOpenSortModal(false)}
        >
          <ul className={classes['sorting-items']}>
            {sortingOptions.map((item, index) => (
              <li key={`${item.value}${index}`}>
                <Button
                  className={classes['sorting-items__button']}
                  onClick={() => {
                    setSelectedSort(item);
                    setOpenSortModal(false);
                  }}
                >
                  {item.label}
                </Button>
              </li>
            ))}
          </ul>
        </MobileFilterModal>
      )}

      {openSearchModal && (
        <MobileFilterModal
          title={t('Search')}
          onClose={() => setOpenSearchModal(false)}
        >
          <div className={classes['search-wrapper']}>
            <Input
              value={globalSearch}
              onChange={callbacks.onSearch}
              placeholder={`${t('Type a value')}...`}
            />
            <Button theme={'primary'}>{t('Search')}</Button>
          </div>
        </MobileFilterModal>
      )}

      {openFilterModal && (
        <MobileFilterModal
          title={t('Filter')}
          onClose={() => setOpenFilterModal(false)}
        >
          <div className={classes.filter}>
            <div className={classes.filter__header}>
              <span>{t('Action')}</span>
              <Button theme={'text'} onClick={() => setCheckedActions([])}>
                {t('Clear')}
              </Button>
            </div>
            <CheckboxFilterList
              checked={checkedActions}
              options={actionFilterOptions}
              onCheckedChange={setCheckedActions}
              t={t}
              additionalClass={classes.filter__checklist}
            />
          </div>

          <div className={classes.filter}>
            <div className={classes.filter__header}>
              <span>{t('Period')}</span>
              <Button
                theme={'text'}
                onClick={() => {
                  setStartPeriod(null);
                  setEndPeriod(null);
                }}
              >
                {t('Clear')}
              </Button>
            </div>

            <StyledDatePicker
              showMonthYearPicker
              fullWidth
              onChange={callbacks.onPeriodChange}
              inline
              selectsRange
              startDate={startPeriod || undefined}
              endDate={endPeriod || undefined}
            />

            <Button
              className={classes.filter__submit}
              theme={'primary'}
              onClick={() => setOpenFilterModal(false)}
            >
              {t('Apply')}
            </Button>
          </div>
        </MobileFilterModal>
      )}
    </>
  );
}

export default InvoicesList;
