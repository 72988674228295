import { useEffect, useRef, useState } from 'react';
import classes from './Autocomplete.module.scss';
import { Input } from 'components/shared/Input';
import FormInput from 'components/shared/NewInput';
import DefaultSelectItem from 'components/shared/DefaultSelectItem';
import { debounce } from 'lodash';
import { scrollNearBottom } from 'tools/scrollNearBottom';
import clsx from 'clsx';
import Lottie from 'lottie-react';
import loader from 'components/shared/PageLoader/loader.json';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import React from 'react';
interface AutocompleteSelectOption {
  value: string | number;
  label: string;
}

interface AutocompleteSelectProps {
  isLoading?: boolean;
  selected?: AutocompleteSelectOption;
  options: AutocompleteSelectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  debounce?: number;
  scrollDebounce?: number;
  searchByRequest?: boolean;
  additionalClass?: string;
  fakeInputClassName?: string;
  mainInputClassName?: string;
  focusClassName?: string;
  otherFieldSearch?: boolean;
  onScrollBottom?: () => void;
  onSearch?: (val: string) => void;
  onSelect?: (id: string | number) => void;
  onDeleteSelected?: (id: string | number) => void;
}

const Autocomplete = (props: AutocompleteSelectProps) => {
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [itemHovered, setItemHovered] = useState<string | number | null>(null);
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const itemsListRef = useRef<HTMLDivElement | null>(null);

  const callbacks = {
    onListScroll: () => {
      const list = itemsListRef.current;
      if (list) {
        if (scrollNearBottom(list)) {
          debounce(() => {
            props.onScrollBottom?.();
          }, props.scrollDebounce)();
        }
      }
    },
    onSearch: (value: string) => {
      setSearch(value);
      if (props.searchByRequest) {
        if (props.debounce) {
          debounce(() => props.onSearch?.(value), props.debounce)();
        } else {
          props.onSearch?.(value);
        }
      }
      setItemHovered(null);
    },
    onExpandChange: (value: boolean) => {
      setExpanded(value);
      setItemHovered(null);
    },
    onSelectChange: (id: string | number) => {
      props.onSelect?.(id);
      callbacks.onExpandChange(false);
    },
    onKeyboardExpandChange: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        callbacks.onExpandChange(true);
      }
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
        props.options.length
      ) {
        e.stopPropagation();
        if (e.key === 'ArrowDown' && !itemHovered) {
          setItemHovered(props.options[0].value);
          return;
        }

        if (e.key === 'ArrowUp' && !itemHovered) {
          setItemHovered(props.options.at(-1)?.value || null);
          itemsRef.current[props.options.length - 1].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          return;
        }

        const currentItemIndex = props.options.findIndex(
          (option) => option.value === itemHovered
        );
        const nextItemIndex =
          e.key === 'ArrowDown' ? currentItemIndex + 1 : currentItemIndex - 1;
        const nextItem = props.options[nextItemIndex];

        if (nextItem) {
          setItemHovered(nextItem.value);
          itemsRef.current[nextItemIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          return;
        }
      }

      if (e.key === 'Escape') callbacks.onExpandChange(false);
      if (e.key === 'Enter' && itemHovered)
        callbacks.onSelectChange(itemHovered);
    },
  };

  useEffect(() => {
    if (props.selected && !props.otherFieldSearch) {
      setSearch(props.selected.label);
    }
  }, [props.selected, props.otherFieldSearch]);

  useEffect(() => {
    const onClickOutSide = function (event: Event) {
      if (
        selectorRef.current &&
        !selectorRef.current.contains(event.target as Node)
      ) {
        if (props.selected && !props.otherFieldSearch) {
          setSearch(props.selected.label);
        }

        if (props.otherFieldSearch) {
          setSearch('');
        }
        callbacks.onExpandChange(false);
      }
    };

    document.addEventListener('click', onClickOutSide);

    return () => document.removeEventListener('click', onClickOutSide);
  }, [
    selectorRef,
    callbacks.onExpandChange,
    props.selected,
    props.otherFieldSearch,
  ]);

  let filteredOptions;
  if (search.trim() && !props.searchByRequest) {
    filteredOptions = props.options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  } else {
    filteredOptions = props.options;
  }

  const startIconOptions = {
    startIconName: 'SearchIconAlt',
    startIconClassName: classes.startIcon,
  };

  return (
    <div
      ref={selectorRef}
      className={clsx(classes.wrapper, props.additionalClass)}
    >
      {!props.otherFieldSearch ? (
        <Input
          placeholder={props.placeholder}
          value={search}
          onFocus={() => callbacks.onExpandChange(true)}
          onClick={() => callbacks.onExpandChange(true)}
          onChange={callbacks.onSearch}
          onKeyDown={callbacks.onKeyDown}
        />
      ) : (
        <FormInput
          title={props.placeholder}
          placeholder={props.placeholder}
          value={props.selected?.label || ''}
          onFocus={() => callbacks.onExpandChange(true)}
          onChange={() => {}}
          onKeyDown={callbacks.onKeyDown}
          wrapperClassName={props.mainInputClassName}
          focusClassName={props.focusClassName}
        />
      )}
      <ArrowDownIcon
        onClick={() => setExpanded(!expanded)}
        className={clsx(classes.arrowIcon, expanded && classes.arrowActive)}
      />
      {expanded && (
        <div className={classes.dropdown}>
          {props.otherFieldSearch && (
            <div className={clsx(classes['enter-source'])}>
              <FormInput
                value={search}
                placeholder={props?.searchPlaceholder || ''}
                onKeyDown={callbacks.onKeyDown}
                onChange={callbacks.onSearch}
                startIcon={startIconOptions}
                focusClassName={props.focusClassName}
                isSearch
              />
            </div>
          )}

          <div
            className={classes.list}
            ref={itemsListRef}
            onScroll={callbacks.onListScroll}
          >
            {filteredOptions.map((item, index: number) => (
              <div
                key={`${item.value}-${index}`}
                onClick={() => callbacks.onSelectChange(item.value)}
                onMouseEnter={() => setItemHovered(item.value)}
                ref={(e) => {
                  if (e) {
                    itemsRef.current[index] = e;
                  }
                }}
              >
                <DefaultSelectItem
                  label={item.label}
                  selected={props.selected?.value === item.value}
                  hovered={item.value === itemHovered}
                />
              </div>
            ))}
            {props.isLoading && (
              <div className={classes.loaderWrapper}>
                <Lottie animationData={loader} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
