import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { pagesTitlesAndDescriptions } from 'constants/titleAndDescriptionConstants';
import { useTranslation } from 'react-i18next';

interface ITitleAndDescription {
  pageName: string;
  title?: string;
  titleOnDescription?: boolean;
}

export const TitleAndDescription: FC<ITitleAndDescription> = ({
  title,
  pageName,
  titleOnDescription = false,
}) => {
  const { t, i18n } = useTranslation();
  const translatedTitles = pagesTitlesAndDescriptions(t);

  const pageData = translatedTitles.find((el) => el.page === pageName);

  const pageTitle = pageData?.title?.replace('{{title}}', title || '') || '';
  const pageDescription = titleOnDescription
    ? pageData?.description.replace('{{title}}', title || '') || ''
    : pageData?.description || '';

  return (
    <Helmet key={i18n.language}>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
    </Helmet>
  );
};
