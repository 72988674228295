import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const MarketplaceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M10.9565 4H4.34783C4.15652 4 4 4.15652 4 4.34783V10.9565C4 11.1478 4.15652 11.3043 4.34783 11.3043H10.9565C11.1478 11.3043 11.3043 11.1478 11.3043 10.9565V4.34783C11.3043 4.15652 11.1478 4 10.9565 4ZM9.82609 9.82609H5.47826V5.47826H9.82609V9.82609ZM19.6522 4H13.0435C12.8522 4 12.6957 4.15652 12.6957 4.34783V10.9565C12.6957 11.1478 12.8522 11.3043 13.0435 11.3043H19.6522C19.8435 11.3043 20 11.1478 20 10.9565V4.34783C20 4.15652 19.8435 4 19.6522 4ZM18.5217 9.82609H14.1739V5.47826H18.5217V9.82609ZM10.9565 12.6957H4.34783C4.15652 12.6957 4 12.8522 4 13.0435V19.6522C4 19.8435 4.15652 20 4.34783 20H10.9565C11.1478 20 11.3043 19.8435 11.3043 19.6522V13.0435C11.3043 12.8522 11.1478 12.6957 10.9565 12.6957ZM9.82609 18.5217H5.47826V14.1739H9.82609V18.5217ZM19.6522 12.6957H13.0435C12.8522 12.6957 12.6957 12.8522 12.6957 13.0435V19.6522C12.6957 19.8435 12.8522 20 13.0435 20H19.6522C19.8435 20 20 19.8435 20 19.6522V13.0435C20 12.8522 19.8435 12.6957 19.6522 12.6957ZM18.5217 18.5217H14.1739V14.1739H18.5217V18.5217Z"
      fill="url(#paint0_linear_5600_41423)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41423"
          x1="-6.96252"
          y1="-8.88"
          x2="10.4775"
          y2="10.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41423"
          x1="0.560002"
          y1="2.32"
          x2="18.88"
          y2="23.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E3CFF" />
          <stop offset="1" stopColor="#4BA5FF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
