import { authStore } from 'stores/authStore';
import { userStore } from 'stores/user-store';
import { ShowNotification } from './showNotification';
import i18next from 'i18next';

export function ReloadStores() {
  try {
    authStore.refreshAuth();
    userStore.loadUser();
    return true;
  } catch {
    ShowNotification({
      type: 'error',
      children: i18next.t('Unknown error. Please, try again later'),
    });
  }
}
