import { FC } from 'react';
import clsx from 'classnames';
import classes from './TabFilter.module.scss';
import { TTabFilterProps, TTab } from './types';
import { useTranslation } from 'react-i18next';

export const TabFilter: FC<TTabFilterProps> = ({
  tabArray,
  activeTab,
  setActiveTab,
  addClassName,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapper, addClassName)}>
      {tabArray?.length > 0 &&
        tabArray.map((tab: TTab) => (
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (onClick && activeTab?.id !== tab.id) onClick(tab);
              if (setActiveTab && activeTab?.id !== tab.id) setActiveTab(tab);
            }}
            className={clsx(
              classes.filter,
              activeTab?.id === tab.id && classes.active,
              (disabled || tab.count === 0) && classes.disabled
            )}
            key={tab.name}
          >
            {t(tab.name)}
            <div className={classes.count}>{tab.count}</div>
          </div>
        ))}
    </div>
  );
};
