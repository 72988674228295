import React, { useState } from 'react';
import { Label } from './Label';
import { Icon } from 'components/shared/Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import UiIcon from 'components/shared/Icon';

export const Input = ({
  label,
  labelPosition,
  type,
  placeholder,
  disabled = false,
  readOnly = false,
  error,
  value = '',
  icon,
  iconFill,
  iconHeight,
  iconWidth,
  className,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  onKeyDown,
  onClick,
  autoFocus,
  startIconOptions,
  isAccountForm,
}) => {
  const { startIconName, startIconClassName } = startIconOptions || {};
  const [focused, setFocused] = useState(false);

  const classNames = clsx(
    style.input,
    startIconName && style.inputWithStartIcon,
    icon && style.inputicon,
    error && style.inputError,
    isAccountForm && style.accountForm,
    focused && style.focused
  );

  const startIcon = () => {
    if (icon && !startIconName) {
      return (
        <div className={style.iconWrap}>
          <Icon
            name={icon}
            width={iconWidth}
            height={iconHeight}
            iconFill={iconFill}
          />
        </div>
      );
    }
    if (!icon && startIconName) {
      return (
        <div className={clsx(style.iconWrap, startIconClassName)}>
          <UiIcon
            name={startIconName}
            additionalClassName={startIconClassName}
          />
        </div>
      );
    }
  };

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {/*{icon && (*/}
        {/*  <div className={style.iconWrap}>*/}
        {/*    <Icon*/}
        {/*      name={icon}*/}
        {/*      width={iconWidth}*/}
        {/*      height={iconHeight}*/}
        {/*      iconFill={iconFill}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
        {startIcon()}
        <input
          type={type}
          value={value || ''}
          className={classNames}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => {
            setFocused(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur && onBlur();
          }}
          maxLength={maxLength || undefined}
          onKeyDown={onKeyDown}
          onClick={onClick}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
};

Input.defaultProps = {
  label: '',
  labelPosition: '',
  type: '',
  placeholder: '',
  disabled: false,
  readOnly: false,
  error: null,
  value: null,
  icon: null,
  iconWidth: '18px',
  iconHeight: '18px',
  iconFill: '#A09CAC',
  className: '',
  maxLength: undefined,
  autoFocus: undefined,
  startIconOptions: {},
};

Input.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconFill: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  maxLength: PropTypes.number | undefined,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  autoFocus: PropTypes.bool | undefined,
  startIconOptions: PropTypes.object | undefined,
  isAccountForm: PropTypes.bool,
};
