import React, { ReactNode } from 'react';
import { ProjectsStore, projectsStore } from 'stores/projects-store';

const ProjectsContext = React.createContext<ProjectsStore | null>(null);

export const ProjectsProvider = ({ children }: { children: ReactNode }) => (
  <ProjectsContext.Provider value={projectsStore}>
    {children}
  </ProjectsContext.Provider>
);

export const useProjectsStore = () => {
  const context = React.useContext(ProjectsContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ProjectsProvider'
    );
  }
  return context;
};
