import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M2.92626 1.63748V13.0125H11.6763V4.52498L11.545 4.39373L8.92001 1.76873L8.78876 1.63748H2.92626ZM3.80126 2.51248H8.17626V5.13748H10.8013V12.1375H3.80126V2.51248ZM9.05126 3.12498L10.1888 4.26248H9.05126V3.12498ZM4.67626 6.59581V7.47081H9.92626V6.59581H4.67626ZM4.67626 8.78331V9.65831H9.92626V8.78331H4.67626Z"
      fill={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
    />
  </svg>
);
