import React, { FC } from 'react';
import '../styles/main.scss';
import { ErrorPageContainer } from 'components/common/ErrorPageContainer';
import { TitleAndDescription } from '../components/shared/TitleAndDescription/TitleAndDescription';
import { useTranslation } from 'react-i18next';

export const ErrorPage: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={'layout-container layout-container--inside'}>
      <TitleAndDescription pageName="needconfirm" />
      <div className="layout-container-inner">
        <ErrorPageContainer title={t('Error 500')} />
      </div>
    </div>
  );
};
