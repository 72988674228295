import { RequestType } from '@type/global';
import api from 'services/http';

export async function Request({ type, url = '', data }: RequestType) {
  const options = {
    url,
    data: data,
    method: type,
  };

  const response = await api.request(options);

  return response && response.status < 400 && response.status >= 200
    ? response.data
    : response;
}
