import * as React from 'react';
import { SVGProps } from 'react';

export const VisaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="12"
    viewBox="0 0 34 12"
    fill="none"
    {...props}
  >
    <path
      d="M11.999 10.9256L13.6999 0.926966H16.4232L14.7223 10.9256H11.999ZM24.5636 1.17275C24.0327 0.976124 23.1872 0.75 22.1352 0.75C19.4513 0.75 17.5636 2.10674 17.544 4.05337C17.5341 5.48876 18.8909 6.28511 19.9232 6.76685C20.985 7.25843 21.3389 7.57303 21.3291 8.00562C21.3291 8.67416 20.4934 8.98876 19.7069 8.98876C18.6254 8.98876 18.0355 8.83146 17.1507 8.45787L16.7968 8.30056L16.4232 10.5421C17.3893 10.8987 18.4116 11.0786 19.4414 11.073C22.3024 11.073 24.1605 9.72612 24.1802 7.65169C24.1802 6.52107 23.4625 5.6559 21.8993 4.9382C20.9456 4.47612 20.3656 4.17135 20.3754 3.69944C20.3754 3.28652 20.867 2.83427 21.9288 2.83427C22.8136 2.82444 23.4625 3.02107 23.9639 3.22753L24.1999 3.33567L24.5734 1.17275H24.5636ZM31.544 0.936798H29.44C28.7911 0.936798 28.2996 1.11376 28.0145 1.76264L23.9836 10.9256H26.8347L27.4049 9.42135H30.8852L31.2195 10.9256H33.7364L31.544 0.946629M28.2013 7.38624L29.2827 4.5941L29.6366 3.64045L29.8333 4.49579L30.4527 7.38624H28.1914H28.2013ZM9.71811 0.926966L7.06361 7.75L6.7785 6.36376C6.09595 4.54697 4.76031 3.05007 3.03271 2.16573L5.46109 10.9157H8.33187L12.6086 0.926966H9.73777H9.71811Z"
      fill="#004686"
    />
    <path
      d="M4.60531 0.92688H0.220478L0.181152 1.12351C3.59267 1.95918 5.84407 3.9648 6.77806 6.36368L5.82441 1.77239C5.66711 1.13334 5.18537 0.946543 4.59548 0.92688H4.60531Z"
      fill="#EF9B11"
    />
  </svg>
);
