import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './Employees.module.scss';
import { Type } from 'components/shared/Type';
import { useTranslation } from 'react-i18next';
import EmployeesInvite from './EmployeesInvite/EmployeesInvite';
import { tableDataEmployees } from './employeesTableData';
import { observer } from 'mobx-react-lite';
import TableEmployees from './TableEmployees';
import { useEmployees } from './useEmployees';
import { PARTNER_ROLE } from 'types/user';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/user-store';
import { useEffect } from 'react';

const Employees = observer(() => {
  const { t } = useTranslation();
  const {
    partner,
    isLoading,
    clients,
    columns,
    getUsers,
    handleDeletePermissions,
    handleChangePermissions,
  } = useEmployees();

  const navigate = useNavigate();
  const partnerPermission = partner?.permission?.type;

  const isPartnerAdmin = partnerPermission === PARTNER_ROLE.ADMIN;

  useEffect(() => {
    navigate('/solutions'); // TODO: Remove when Employees will be added
  }, []);

  return (
    <div className={classes.container}>
      <TitleAndDescription pageName="Account/employees" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} className={classes.title}>
            {t('Employees')}
          </Type>
        </div>
        <p className={classes['info']}>
          {t(
            'Inviting employees to collaborate on the platform and managing access levels'
          )}
        </p>

        {isPartnerAdmin && (
          <EmployeesInvite client={partner} getUsers={getUsers} />
        )}

        {isLoading || !clients ? (
          <div>{t('Loading...')}</div>
        ) : (
          <TableEmployees
            additionalClassNames={classes.table}
            data={tableDataEmployees(clients, t)}
            columns={columns}
            client={partner}
            handleDeletePermissions={handleDeletePermissions}
            handleChangePermissions={handleChangePermissions as any}
          />
        )}
      </div>
    </div>
  );
});

export default Employees;
