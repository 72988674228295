export enum PASS_MODE {
  OLD = 'oldPass',
  NEW = 'newPass',
  CONFIRM = 'newPassConfirmation',
}

export enum SORT_BY {
  DATE = 'date',
  NAME = 'name',
}

export enum COST_TYPE {
  FROM = 'COST_TYPE_FROM',
  TO = 'COST_TYPE_TO',
  EXACTLY = 'COST_TYPE_EXACTLY',
}

export enum AMOUNT_UNIT {
  CUR = '$',
  PERC = '%',
  NONE = '',
}

export enum AMOUNT_TYPE {
  MORE = '>',
  LESS = '<',
  PLUS = '+',
  NONE = '',
}

export enum NOTICE {
  DEFAULT = 'default',
  WARNING = 'warning',
  WAITING = 'waiting',
}

export enum FILTERS {
  ALL = 'all',
  ACTIVE = 'is_active',
  IN_REVIEW = 'is_review',
  ARCHIVED = 'is_archived',
  REJECTED = 'is_rejected',
}

export enum FILTERS_LABELS {
  ALL = 'all',
  ACTIVE = 'active',
  IN_REVIEW = 'in review',
  ARCHIVED = 'archived',
  REJECTED = 'rejected',
}

export enum PERIOD {
  TODAY = 'TODAY',
  ONE_DAY = 'ONE_DAY',
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTH = 'MONTH',
}

export enum COMPANY_SIZE {
  MICRO = 'MICRO',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  CORPORATION = 'CORPORATION',
}

export const enum Status {
  active = 'ACTIVE_ON_PLATFORM',
  reviewed = 'REVIEW',
  archived = 'ARCHIVED',
  rejected = 'REJECTED',
  moderated = 'MODERATED',
}
