import * as React from 'react';
import { SVGProps } from 'react';

export const QuestionCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M7.57484 7.99984C7.77076 7.44289 8.15746 6.97326 8.66647 6.67411C9.17547 6.37497 9.77392 6.26562 10.3558 6.36543C10.9377 6.46524 11.4655 6.76777 11.8457 7.21944C12.226 7.67111 12.4341 8.24277 12.4332 8.83317C12.4332 10.4998 9.93317 11.3332 9.93317 11.3332M9.99984 14.6665H10.0082M18.3332 10.4998C18.3332 15.1022 14.6022 18.8332 9.99984 18.8332C5.39746 18.8332 1.6665 15.1022 1.6665 10.4998C1.6665 5.89746 5.39746 2.1665 9.99984 2.1665C14.6022 2.1665 18.3332 5.89746 18.3332 10.4998Z"
      stroke="#C4BBD2"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
