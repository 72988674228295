import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const FinancesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M20.3571 5H3.64286C3.28728 5 3 5.28437 3 5.63636V18.3636C3 18.7156 3.28728 19 3.64286 19H20.3571C20.7127 19 21 18.7156 21 18.3636V5.63636C21 5.28437 20.7127 5 20.3571 5ZM4.44643 6.43182H19.5536V8.81818H4.44643V6.43182ZM19.5536 17.5682H4.44643V10.5682H19.5536V17.5682ZM14.7924 16.2955H18.1071C18.1955 16.2955 18.2679 16.2239 18.2679 16.1364V14.7045C18.2679 14.617 18.1955 14.5455 18.1071 14.5455H14.7924C14.704 14.5455 14.6317 14.617 14.6317 14.7045V16.1364C14.6317 16.2239 14.704 16.2955 14.7924 16.2955Z"
      fill="url(#paint0_linear_5600_41432)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41432"
          x1="-4.93252"
          y1="-2.77"
          x2="9.50274"
          y2="17.7477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41432"
          x1="0.560002"
          y1="2.32"
          x2="18.88"
          y2="23.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E3CFF" />
          <stop offset="1" stopColor="#4BA5FF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
