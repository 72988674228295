import clsx from 'clsx';
import { SolutionTabType } from '../types';
import classes from './SolutionTabs.module.scss';

type SolutionTabsProps = {
  activeTab: SolutionTabType['id'];
  tabs: SolutionTabType[];
  onChange: (id: SolutionTabType['id']) => void;
};

function SolutionTabs({ tabs, activeTab, onChange }: SolutionTabsProps) {
  return (
    <div className={classes.tabs}>
      {tabs.map((tab) => (
        <div
          onClick={() => onChange(tab.id)}
          key={tab.id}
          className={clsx(
            classes.tabs__tab,
            activeTab === tab.id && classes['tabs__tab--active']
          )}
        >
          <span>{tab.label}</span>
        </div>
      ))}
    </div>
  );
}

export default SolutionTabs;
