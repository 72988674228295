import * as React from 'react';
import { SVGProps } from 'react';

export const SmallPurpleArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 6L6 1L1 6" stroke="#8362F3" />
  </svg>
);
