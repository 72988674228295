import { PERIOD } from 'constants/enum';
import { PARTNER_COUNTER } from './usePartnerContainer';

export const queryParamsHandler = ({
  page,
  page_size: pageSize,
  statusFilter,
  isFree,
  isNew,
  isHot,
  isSale,
  price,
  serviceTypes,
  businessTypes,
  isFavorites,
  budget,
  ids,
  order,
  analytics,
  dateFrom,
  dateTo,
  singleService,
}: any) => {
  const queryParams = {
    page: page || 1,
    page_size: pageSize || (!singleService && PARTNER_COUNTER),
    is_hot: isHot,
    is_free: isFree,
    is_new: isNew,
    is_sale: isSale,
    price: price ? price : budget,
    is_favorite: isFavorites,
    service_type: serviceTypes?.length && serviceTypes?.join(','),
    business_type: businessTypes?.length && businessTypes?.join(','),
    service_id: ids?.length && ids?.join(','),
    order,
    analytics,
    date_from: dateFrom,
    date_to: dateTo,
    ...(statusFilter && { [statusFilter]: true }),
  };

  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};

const today: Date = new Date();

const getDate = (daysOffset = 0, monthsOffset = 0): Date => {
  const date: Date = new Date(today);
  if (daysOffset) {
    date.setDate(today.getDate() - daysOffset);
  }
  if (monthsOffset) {
    date.setMonth(today.getMonth() - monthsOffset);
  }
  return date;
};

const formatDate = (date: Date): string => {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const periods: Record<PERIOD, Date> = {
  [PERIOD.TODAY]: today,
  [PERIOD.ONE_DAY]: getDate(),
  [PERIOD.WEEK]: getDate(6),
  [PERIOD.TWO_WEEKS]: getDate(13),
  [PERIOD.MONTH]: getDate(0, 1),
};

export const formatPeriodDate = (period: PERIOD): string => {
  const date: Date = periods[period];
  return formatDate(date);
};

export const startDayFormatted = formatPeriodDate(PERIOD.WEEK);
export const endDayFormatted = formatPeriodDate(PERIOD.TODAY);

export const percentNumber = ({ percentage, decrease, isNone }: any) => {
  if (percentage?.length) {
    return `${Math.round(parseFloat(percentage.replace('%', '').replace('-', '')))}%`;
  }
  if (decrease?.length) {
    return Math.round(parseFloat(decrease.replace('%', ''))) < 0;
  }
  if (isNone?.length) {
    return Math.round(parseFloat(isNone.replace('%', ''))) === 0;
  }
};

export const toSentenceCase = (name: string) => {
  const lowerCaseStr = name.toLowerCase();
  const words = lowerCaseStr.split('_');
  const sentence = words.join(' ');

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};
