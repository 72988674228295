import React, { FC } from "react";
import clsx from "clsx";
import { CheckBoxIcon } from "components/shared/Icons/CheckBoxIcon";
import { MinusIcon } from "components/shared/Icons/MinusIcon";

interface Props {
  className?: string;
  onChange: (val: any) => void;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
  label?: string;
  style?: React.CSSProperties;
  indeterminate?: boolean;
}

export const Checkbox: FC<Props> = ({
  className,
  onChange,
  disabled,
  name,
  checked,
  label,
  style,
  indeterminate,
}) => {
  const icon = () => {
    if (indeterminate && !checked) {
      return <MinusIcon width="11px" height="9px" />;
    } else if (checked) {
      return <CheckBoxIcon width="11px" height="9px" />;
    } else {
      return null;
    }
  };

  return (
    <label className={clsx("common__checkbox", className)}>
      <input
        onChange={() => onChange(!checked)}
        name={name}
        type="checkbox"
        className="common__checkbox--hidden"
        disabled={disabled}
        checked={indeterminate || checked}
      />
      <span style={style} className="common__checkbox-visible">
        {icon()}
      </span>
      {label && <span className="common__checkbox-label">{label}</span>}
    </label>
  );
};
