import React, { FC } from 'react';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import '../styles/main.scss';
import { NeedConfirmPageContainer } from 'components/common/NeedConfirmPageContainer';

export const NeedConfirm: FC = () => (
  <div className={'layout-container--inside'}>
    <TitleAndDescription pageName="needconfirm" />
    <div className="layout-container-inner">
      <NeedConfirmPageContainer />
    </div>
  </div>
);
