import * as React from 'react';
import { SVGProps } from 'react';

export const NumberListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="16" y="22" width="14" height="2" />
    <rect x="16" y="8" width="14" height="2" />
    <polygon points="8 12 8 4 6 4 6 5 4 5 4 7 6 7 6 12 4 12 4 14 6 14 8 14 10 14 10 12 8 12" />
    <path d="M10,28H4V24a2,2,0,0,1,2-2H8V20H4V18H8a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2H6v2h4Z" />
    <rect width="32" height="32" fill="none" />
  </svg>
);
