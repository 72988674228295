import React from 'react';
import classes from './SelectedPreview.module.scss';
import { KrestIcon } from 'components/shared/Icons/KrestIcon';
import clsx from 'clsx';

type SelectedPreviewProps = {
  label: React.ReactNode;
  isOutside?: boolean;
  shouldShrink?: boolean;
  onDelete?: () => void;
  order?: number;
};

const SelectedPreview = React.forwardRef<HTMLDivElement, SelectedPreviewProps>(
  (props, ref) => {
    const isLabelText = typeof props.label === 'string';

    return (
      <div
        className={clsx(
          classes['preview-item'],
          props.shouldShrink && classes.shrink,
          props.isOutside && classes.hidden
        )}
        ref={ref}
      >
        {/* {props?.order && (
          <span className={classes['preview-item--number']}>{props.order}</span>
        )} */}
        {isLabelText ? <span>{props.label}</span> : props.label}

        <button
          className={classes['preview-item--button']}
          onClick={(event) => {
            event.stopPropagation();
            props.onDelete?.();
            // event.preventDefault()
          }}
        >
          <KrestIcon />
        </button>
      </div>
    );
  }
);

SelectedPreview.displayName = 'SelectedPreview';

export default SelectedPreview;
