import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchError } from '@type/util-types';
import Service from '../../../services/service';

export const useCardManager = () => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bigQuestionLength, setBigQuestionLength] = useState(false);
  const [questionErrorMessage, setQuestionErrorMessage] = useState('');
  const [bigSourceLength, setBigSourceLength] = useState(false);
  const [sourceErrorMessage, setSourceErrorMessage] = useState<
    string | undefined
  >('');
  const [questionValue, setQuestionValue] = useState('');
  const [sourceValue, setSourceValue] = useState('');
  const [, setSuccess] = useState(false);
  const [confirmFeedback, setConfirmFeedback] = useState(false);

  useEffect(() => {
    if (questionValue !== '' && !!questionValue.trim()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [questionValue, sourceValue]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setConfirmFeedback(false);
    setQuestionValue('');
    setSourceValue('');
    setBigQuestionLength(false);
    setBigSourceLength(false);
    setSourceErrorMessage('');
    setQuestionErrorMessage('');
  }, []);

  useDisableScrolling(openModal);

  const sendRequest = useCallback(async () => {
    if (disabled) {
      return;
    }

    setDisabled(true);
    setBigQuestionLength(false);
    setQuestionErrorMessage('');
    setBigSourceLength(false);
    setSourceErrorMessage('');

    try {
      const response = await Service.consultRequest(sourceValue, questionValue);
      if (response?.status === 201) {
        setSuccess(true);
        setConfirmFeedback(true);

        setQuestionValue('');
        setSourceValue('');
      }
    } catch (e) {
      const errorResponse = (e as FetchError)?.response;
      if (errorResponse?.status === 400) {
        if (errorResponse?.data?.question) {
          setBigQuestionLength(true);
          setQuestionErrorMessage(errorResponse?.data.question?.[0]);
        }
        if (errorResponse?.data?.project_description) {
          setBigSourceLength(true);
          setSourceErrorMessage(errorResponse?.data?.project_description?.[0]);
        }
      }
    } finally {
      setDisabled(false);
    }
  }, [questionValue, sourceValue, disabled]);

  return {
    t,
    setOpenModal,
    confirmFeedback,
    closeModal,
    openModal,
    questionValue,
    bigQuestionLength,
    setQuestionValue,
    questionErrorMessage,
    setSourceValue,
    sourceErrorMessage,
    sourceValue,
    disabled,
    bigSourceLength,
    sendRequest,
  };
};
