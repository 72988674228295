import { Token } from '@type/auth';
import { Request } from 'tools/request';

export async function ChangeUser({ access, data }: Token & { data: any }) {
  const result = await Request({
    type: 'PUT',
    url: `${process.env.REACT_APP_API}/api/v1/partners/users/`,
    isProtected: true,
    access: access,
    data: data,
  });

  return result;
}
