import React, { FC } from 'react';
import classes from './Menu.module.scss';
import { MarketplaceIcon } from 'components/shared/Icons/MarketplaceIcon';
import { ProjectIcon } from 'components/shared/Icons/ProjectIcon';
import { FinancesIcon } from 'components/shared/Icons/FinancesIcon';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { routers } from 'constants/routers';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useAuthStore } from 'contexts/AuthContext';
import { PARTNER_ROLE } from 'types/user';
import { userStore } from 'stores/user-store';

interface MenuProps {
  active: boolean;
  disabled?: boolean;
  onModerate?: boolean;
  closeMenu: () => void;
}

export const Menu: FC<MenuProps> = ({
  active,
  disabled,
  onModerate,
  closeMenu,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowWidth();
  const { isServerError } = useAuthStore();
  const partner = userStore?.partners?.results?.[0];
  const isPartnerAdmin = partner?.permission?.type === PARTNER_ROLE.ADMIN;

  return (
    <>
      {(active || width > 1024) && (
        <ul className={classes.menu}>
          {width > 1024 && (
            <li className={clsx(classes.item, classes.mobile, classes.market)}>
              <NavLink
                to={routers.solutions}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled &&
                      (!onModerate || isServerError) &&
                      classes.disabled
                  )
                }
              >
                <MarketplaceIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Solutions')}</span>
                </div>
              </NavLink>
            </li>
          )}
          <li
            className={clsx(
              classes.item,
              classes.inside,
              classes.source,
              active && classes.active
            )}
            translate="no"
          >
            <NavLink
              to={routers.projects}
              className={(navData) =>
                clsx(
                  classes.linkWrap,
                  classes.projects,
                  navData.isActive && classes.active,
                  disabled && classes.disabled
                )
              }
              onClick={() => closeMenu()}
            >
              <ProjectIcon />
              <div className={classes.wrap}>
                <span className={classes.link}>{t('Projects')}</span>
              </div>
            </NavLink>
          </li>
          {isPartnerAdmin && (
            <li
              className={clsx(
                classes.item,
                classes.inside,
                classes.finances,
                active && classes.active
              )}
            >
              <NavLink
                to={routers.finances}
                className={(navData) =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled
                  )
                }
                onClick={() => closeMenu()}
              >
                <FinancesIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t('Finances')}</span>
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      )}
    </>
  );
};
