import React, { FC } from 'react';
import { NotFoundPageContainer } from 'components/common/NotFoundPageContainer';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import '../styles/main.scss';

export const NotFoundPage: FC = () => (
  <>
    <TitleAndDescription pageName="404" />
    <NotFoundPageContainer />
  </>
);
