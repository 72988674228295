import { TFunction } from 'i18next';
import { ProjectStatusEnum } from '../types';
import ColorSticker from 'components/shared/ColorSticker';
import { RUSSIAN_LOCALE } from 'utils';

type StatusStickerProps = {
  status: string;
  t: TFunction;
};

const getSticker = function (status: string, t: TFunction) {
  switch (status?.toLowerCase()) {
    case ProjectStatusEnum.ACTIVE:
      return (
        <ColorSticker
          text={t('Active')}
          color={RUSSIAN_LOCALE ? 'green' : 'purple'}
        />
      );
    case ProjectStatusEnum.COMPLETED:
      return (
        <ColorSticker
          text={t('Completed')}
          color={RUSSIAN_LOCALE ? 'purple' : 'green'}
        />
      );
    case ProjectStatusEnum.PAUSED:
      return <ColorSticker text={t('Paused')} color="yellow" />;
    case ProjectStatusEnum.CANCELLED:
      return <ColorSticker text={t('Cancelled')} color="red" />;
    default:
      return <ColorSticker text={t('Active')} color="purple" />;
  }
};

function StatusSticker({ status, t }: StatusStickerProps) {
  return getSticker(status, t);
}

export default StatusSticker;
