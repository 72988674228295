import * as React from 'react';
import { SVGProps } from 'react';

export const DotsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 10.8332C10.4603 10.8332 10.8334 10.4601 10.8334 9.99984C10.8334 9.5396 10.4603 9.1665 10 9.1665C9.5398 9.1665 9.16671 9.5396 9.16671 9.99984C9.16671 10.4601 9.5398 10.8332 10 10.8332Z"
        fill="#9C8B8B"
      />
      <path
        d="M15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C15.3731 9.1665 15 9.5396 15 9.99984C15 10.4601 15.3731 10.8332 15.8334 10.8332Z"
        fill="#9C8B8B"
      />
      <path
        d="M4.16671 10.8332C4.62694 10.8332 5.00004 10.4601 5.00004 9.99984C5.00004 9.5396 4.62694 9.1665 4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332Z"
        fill="#9C8B8B"
      />
      <path
        d="M10 10.8332C10.4603 10.8332 10.8334 10.4601 10.8334 9.99984C10.8334 9.5396 10.4603 9.1665 10 9.1665C9.5398 9.1665 9.16671 9.5396 9.16671 9.99984C9.16671 10.4601 9.5398 10.8332 10 10.8332Z"
        stroke="#9C8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8334 9.1665C15.3731 9.1665 15 9.5396 15 9.99984C15 10.4601 15.3731 10.8332 15.8334 10.8332Z"
        stroke="#9C8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16671 10.8332C4.62694 10.8332 5.00004 10.4601 5.00004 9.99984C5.00004 9.5396 4.62694 9.1665 4.16671 9.1665C3.70647 9.1665 3.33337 9.5396 3.33337 9.99984C3.33337 10.4601 3.70647 10.8332 4.16671 10.8332Z"
        stroke="#9C8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
