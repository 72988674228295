import * as React from 'react';
import { SVGProps } from 'react';

export const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    // width="1em"
    // height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.221.334H10.17a.257.257 0 0 0-.181.069L6 4.482 2.013.402a.257.257 0 0 0-.181-.068H.779C.688.334.634.411.688.466L5.637 5.53c.18.183.547.183.725 0l4.95-5.064c.054-.055 0-.132-.09-.132Z"
      fill="#898989"
    />
  </svg>
);
