import { TFunction } from 'i18next';
import classes from 'containers/PartnerContainer/PartnerContainer.module.scss';
import cardClasses from 'containers/PartnerContainer/Card/Card.module.scss';
import classesFinance from 'containers/Finances/Finances.module.scss';
import classesAddSolutions from 'containers/AddSolution/FormBlock/FormBlock.module.scss';
import addCaseClasses from 'containers/AddSolution/AddCaseForm/AddCaseForm.module.scss';

export const LABELS = (t: TFunction) => ({
  GOT_IT: t('Got it'),
  NEXT: t('Next'),
  BACK: t('Back'),
  DONE: t('Done'),
});

export const solutionsHints = (t: TFunction) => [
  {
    title: t(
      'Solutions is where you can add your products to be placed on the Client Platform showcase'
    ),
    intro: '',
  },
  {
    element: `.${classes.emptyBlock__button} .${classes.mainButton}`,
    title: t(
      'To open the form for creating a new solution, click on the button'
    ),
    intro: '',
    position: 'right',
  },
  {
    element: `.${cardClasses.card}`,
    title: t(
      'After successful moderation of the solution, you will see the status Active and will be able to monitor statistics of Clients interaction'
    ),
    intro: '',
    position: 'top',
  },
];

export const financesHints = (t: TFunction) => [
  {
    element: `.${classesFinance['content-wrapper']}`,
    title: t('This section will hold your partnership documents and invoices'),
    intro: '',
    position: 'top',
    scrollTo: 'tooltip',
  },
];

export const addSolutionsFirstStepHints = (t: TFunction) => [
  {
    element: `.${classesAddSolutions.generalGoalsWrapper}`,
    title: t(
      "Fill in the basic information about your solution. This data helps ensure that the showcase matches Client's company type and needs"
    ),
    intro: '',
    position: 'right',
    disableInteraction: true,
  },
  {
    element: `.${classesAddSolutions.detailedStepsWrapper}`,
    scrollTo: 'tooltip',
    position: 'right',
    title: t(
      'The detailed information is displayed on a separate solution page and directly influences customer interest. Add credibility and transparency to your description of processes to increase the likelihood of new projects.'
    ),
    intro: '',
    disableInteraction: true,
  },
  {
    element: `.${classesAddSolutions.materialsBlock}`,
    scrollTo: 'tooltip',
    position: 'right',
    title: t(
      'Add a brief template to make the client negotiation process more efficient and appropriate to the terms of your service.'
    ),
    intro: '',
    disableInteraction: true,
  },
];

export const addSolutionsSecondStepHintsFirst = (t: TFunction) => [
  {
    element: `.${classesAddSolutions.exampleBlock}`,
    scrollTo: 'tooltip',
    title: t(
      'Successful cases are a mark of quality and increase confidence in your expertise. This is an example of a design in which your case will be displayed in the Client Platform'
    ),
    intro: '',
    position: 'right',
    disableInteraction: true,
  },
];

export const addSolutionsSecondStepHintsSecond = (t: TFunction) => [
  {
    element: `.${addCaseClasses.hint}.${addCaseClasses['hint--1']}`,
    scrollTo: 'tooltip',
    position: 'right',
    title: t(
      "Disclosing your clients' brands may be considered a violation of NDA, so we recommend giving a general description of the project and the domain"
    ),
    intro: '',
    disableInteraction: true,
  },
  {
    element: `.${addCaseClasses.hint}.${addCaseClasses['hint--2']}`,
    scrollTo: 'tooltip',
    position: 'right',
    title: t(
      'In a few sentences, convey the meaning of the work done and add inspiring figures with the indicators achieved'
    ),
    intro: '',
    disableInteraction: true,
  },
];

export const addSolutionsThirdStepHints = (t: TFunction) => [
  {
    element: `.${classesAddSolutions.hintBlock}`,
    title: t(
      "Add a minimum starting budget, financial model and geo in which you are ready to work with the Platform's clients. Save solution to send it to moderation before activation on the showcase"
    ),
    intro: '',
    position: 'right',
    disableInteraction: true,
  },
];
