import React, { FC } from 'react';
import classes from './Switcher.module.scss';

interface SwitcherProps {
  label: string;
  isOn?: boolean;
  setIsOn?: (val: boolean) => void;
  onChange?: () => void;
}

const Switcher: FC<SwitcherProps> = ({ label, setIsOn, isOn, onChange }) => {
  const toggleSwitch = () => {
    if (setIsOn) {
      setIsOn(!isOn);
    }
  };

  return (
    <div className={classes.switch}>
      <div className={classes.label}>{label}</div>
      <div
        className={`${classes.switcherContainer} ${isOn ? classes.on : ''}`}
        onClick={toggleSwitch}
        onChange={() => {
          onChange && onChange();
        }}
      >
        <div className={`${classes.knob} ${isOn ? classes.on : ''}`} />
      </div>
    </div>
  );
};

export default Switcher;
