import { Currency } from '@type/finance';

export enum ProjectStatusEnum {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

type ProjectSource = {
  id: number;
  name: string;
};

export type ProjectType = {
  id: string;
  company: string;
  source?: ProjectSource;
  cost: number;
  type: string;
  status: string;
  serial: number;
  solutionId: number;
  projectId: number;
  currency: Currency;
};

export type StatusAmount = {
  type: string;
  count: number;
};

export type SolutionType = {
  id: number;
  solution: {
    id: number;
    name: string;
    totalCost: number;
    currency: Currency;
    statusCount: StatusAmount[];
  };
  projects: ProjectType[];
};

export type SolutionTree = {
  id: number;
  name: string;
  totalCost: number;
  currency: Currency;
  projects: Omit<ProjectType, 'solution'>[];
};

export type ProjectParams = {
  params?: string;
  nextPage?: string;
  prevPage?: string;
};

export type ProjectsQueryParams = {
  order?: string;
  page?: number;
  page_size?: number;
  status?: string;
};
