import { RUSSIAN_LOCALE } from 'utils';
import { TFunction } from 'i18next';

const titleConstant = RUSSIAN_LOCALE
  ? 'Платформа Kokoc Group'
  : 'KIT Global Platform';
const shortTitleConstant = RUSSIAN_LOCALE
  ? 'Kokoc Group'
  : 'KIT Global Platform';

export const pagesTitlesAndDescriptions = (t: TFunction) => [
  {
    page: 'Registration',
    title: `${t('Account creation')} - ${titleConstant}`,
    description: `${t('Sign up for')} ${titleConstant}`,
  },
  {
    page: 'Login',
    title: `${t('Platform Log in')} - ${shortTitleConstant}`,
    description: `${t('Log in to')} ${shortTitleConstant}`,
  },
  {
    page: 'Restore',
    title: `${t('Restore password')} - ${titleConstant}`,
    description: `${t('Restore password')} ${titleConstant}`,
  },
  {
    page: 'Solutions',
    title: `${t('Solutions')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Preview',
    title: `{{title}} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Projects',
    title: `${t('Projects')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'ProjectsDetail',
    title: `{{title}} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Finances',
    title: `${t('Finances')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Orders',
    title: `${t('Orders')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Your invoices',
    title: `${t('Your invoices')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Favorites',
    title: `${t('Favorites')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Cart',
    title: `${t('Cart')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'HotDeals',
    title: `${t('Hot Deals')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Account/personal',
    title: `${t('Personal information')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Account/business',
    title: `${t('Business information')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Account/change-password',
    title: `${t('Change password')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: '404',
    title: `${t('Page not found')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'Disclaimer',
    title: `${t('Pricing Disclaimer')} - ${titleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'AddSolution',
    title: `${t('Add Solution')} - ${shortTitleConstant}`,
    description: `${titleConstant} – ${t('all digital marketing tools at one place')}`,
  },
  {
    page: 'SolutionDetail',
    title: `{{title}} - ${titleConstant}`,
    description: `{{title}} – ${titleConstant}`,
  },
];
