import * as React from 'react';
import { SVGProps } from 'react';

export const VerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#37B24D" />
    <path
      d="M14 6.5L8.5 12.9167L6 10"
      stroke="#37B24D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
