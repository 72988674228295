import React, { forwardRef } from 'react';
import { Tooltip } from './Tooltip';
import clsx from 'clsx';

type TooltipContainerProps = {
  open?: boolean;
  className?: string;
  classNameTooltip?: string;
  children?: React.ReactNode;
  openOnClick?: boolean;
  noPointer?: boolean;
  position?: string;
  text?: React.ReactNode;
  customClasses?: string;
  tooltipInner?: string;
};

export const TooltipContainer = (props: TooltipContainerProps) => {
  const [active, setActive] = React.useState(false);

  const onShowTooltip = React.useCallback(() => {
    setActive(true);
  }, []);

  const onHideTooltip = React.useCallback(() => {
    setActive(false);
  }, []);

  return (
    <div className={props.className}>
      {props.children}
      {!props.openOnClick && (
        <span
          className={clsx(
            'common__tooltip--hidden',
            props.noPointer && 'common__tooltip--no-pointer'
          )}
          onMouseOver={onShowTooltip}
          onMouseOut={onHideTooltip}
        ></span>
      )}
      {(active || props.open) && (
        <Tooltip
          {...props}
          className={props.classNameTooltip}
          position={props.position}
        >
          {props.text}
        </Tooltip>
      )}
    </div>
  );
};
