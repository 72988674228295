import React, { FC, useEffect, useRef, useState } from 'react';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import UiIcon from '../Icon';
import clsx from 'clsx';
import classes from './Input.module.scss';
import { useTranslation } from 'react-i18next';
import { formatNumberWithSpace } from './formatNumberWithSpace';

export interface EndIconProps {
  endIconName?: string;
  endIconTooltipText?: string;
  endIconTooltipPosition?: string;
  endIconTooltipClassName?: string;
  endIconClassName?: string;
  tooltipInner?: string;
}

export interface StartIconProps {
  startIconName?: string;
  startIconClassName?: string;
}

interface InputProps {
  type?: string;
  value: string | number;
  classNames?: string;
  wrapperClassName?: string;
  focusClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  maxLength?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  endIcon?: EndIconProps;
  startIcon?: StartIconProps;
  title?: string;
  error?: string | boolean;
  errorText?: string;
  isSearch?: boolean;
  autoFocus?: boolean;
  inputForCurrency?: boolean;
  isAccount?: boolean;
}

const Input: FC<InputProps> = ({
  type = 'text',
  value,
  classNames,
  wrapperClassName,
  focusClassName,
  placeholder,
  disabled,
  readOnly,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  onKeyDown,
  endIcon,
  startIcon,
  title,
  error,
  errorText,
  isSearch,
  autoFocus,
  inputForCurrency = false,
  isAccount = false,
}) => {
  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
    tooltipInner,
  } = endIcon || {};

  const { startIconName, startIconClassName } = startIcon || {};

  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef?.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formattedValue = inputForCurrency
    ? formatNumberWithSpace(Number(value.toString().replace(/\s+/g, '')))
    : value;

  return (
    <div className={classes.inputWrapper}>
      <div
        ref={inputRef}
        className={clsx(
          classes.input,
          !disabled && !isAccount && focused && classes.active,
          focused && focusClassName,
          error && classes.error,
          isSearch && classes.isSearchInput,
          wrapperClassName,
          disabled && isAccount && classes.disabled
        )}
        onClick={() => {
          onFocus && onFocus();
          setFocused(true);
        }}
        onBlur={() => {
          onBlur && onBlur();
          setFocused(false);
        }}
      >
        {startIconName && (
          <div className={classes.startIcon}>
            <UiIcon
              name={startIconName}
              additionalClassName={startIconClassName}
            />
          </div>
        )}
        <div
          className={clsx(
            classes.inputBlock,
            disabled && isAccount && classes.disabled
          )}
        >
          <span className={classes.inputTitle}>
            {(focused || formattedValue) && title}
          </span>
          <input
            type={type}
            value={formattedValue || ''}
            className={clsx(classes.inputField, classNames)}
            placeholder={!focused ? placeholder : ''}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => {
              onFocus && onFocus();
              setFocused(true);
            }}
            onBlur={() => {
              onBlur && onBlur();
              setFocused(false);
            }}
            maxLength={maxLength || undefined}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
          />
        </div>
        {endIconName && (
          <TooltipContainer
            text={endIconTooltipText}
            customClasses={'kit-ui-block'}
            position={endIconTooltipPosition}
            className={endIconTooltipClassName}
            tooltipInner={tooltipInner}
          >
            <UiIcon
              name={endIconName}
              additionalClassName={clsx(
                classes.endIcon,
                endIconClassName,
                error && classes.error
              )}
            />
          </TooltipContainer>
        )}
      </div>
      {errorText && (
        <div className={classes.errorText}>
          {errorText || t('Fill in the field')}
        </div>
      )}
    </div>
  );
};

export default Input;
