import logo_ru from 'assets/img/logo_ru.svg';
import { LoginRuBGLeftIcon } from 'components/shared/Icons/LoginRuBGLeftIcon';
import { LoginRuBGRightIcon } from 'components/shared/Icons/LoginRuBGRightIcon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { NavLink } from 'react-router-dom';
import { RegistrationFormRu } from './RegistrationFormRu';
import classes from './RegistrationRu.module.scss';

export const RegistrationRUContainer = () => {
  return (
    <div className={classes.container}>
      <TitleAndDescription pageName="Registration" />

      <div className={classes.content}>
        <NavLink
          to={`${process.env.REACT_APP_MAIN_SITE_URL}`}
          className={classes.logo}
        >
          <img src={logo_ru} alt="" />
        </NavLink>

        <RegistrationFormRu />
      </div>

      <div className={classes.right}>
        <LoginRuBGRightIcon />
      </div>

      <div className={classes.left}>
        <LoginRuBGLeftIcon />
      </div>
    </div>
  );
};
