import React, { FC } from 'react';
import UiIcon from 'components/shared/Icon';
import classes from './EmptyProducts.module.scss';
import clsx from 'clsx';

interface EmptyProductsProps {
  title?: string;
  iconName?: string;
  noIcon?: boolean;
  iconOptions?: any;
  bottomText?: string;
  additionalClass?: string;
}

const EmptyProducts: FC<EmptyProductsProps> = ({
  title,
  iconName = 'EmptyIcon',
  noIcon = false,
  iconOptions,
  bottomText,
  additionalClass,
}) => (
  <div className={clsx(classes['empty__container'], additionalClass)}>
    {title && <span className={classes['empty__title']}>{title}</span>}
    {!noIcon && (
      <UiIcon
        iconProps={iconOptions}
        name={iconName}
        additionalClassName={classes['empty__icon']}
      />
    )}
    {bottomText && (
      <span className={classes['empty__bottom-text']}>{bottomText}</span>
    )}
  </div>
);

export default EmptyProducts;
