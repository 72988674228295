import StyledDatePicker from 'components/shared/DatePicker';
import TooltipFilter from 'components/shared/TooltipFilter';
import { useTranslation } from 'react-i18next';
import classes from './PeriodFilter.module.scss';
import { PeriodFilterProps } from './PeriodFilter.props';

function PeriodFilter(props: PeriodFilterProps) {
  const { t } = useTranslation();

  return (
    <TooltipFilter
      open={props.isOpen}
      onClose={props.onClickOutside}
      onSortAscending={props.onSortAsc}
      onSortDescending={props.onSortDesc}
      input={
        <div className={classes.filter}>
          <div className={classes.filter__title}>
            <span>{t('Period')}</span>
          </div>
          <StyledDatePicker
            showMonthYearPicker
            selectsRange
            onChange={props.onPeriodChange}
            startDate={props.startDate}
            endDate={props.endDate}
            dateFormat="MM.yyyy"
          />
        </div>
      }
      t={t}
    >
      {props.children}
    </TooltipFilter>
  );
}

export default PeriodFilter;
