import * as React from 'react';
import { SVGProps } from 'react';

export const GooglePayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="22"
    viewBox="0 0 47 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4678_245158)">
      <path
        d="M21.9897 11.3753V16.7454H20.271V3.46457H24.7397C25.8283 3.46457 26.8595 3.86877 27.6616 4.61942C28.4637 5.31234 28.8647 6.35171 28.8647 7.44882C28.8647 8.54593 28.4637 9.52756 27.6616 10.2782C26.8595 11.0289 25.8856 11.4331 24.7397 11.4331L21.9897 11.3753ZM21.9897 5.08137V9.70079H24.8543C25.4845 9.70079 26.1147 9.46982 26.5158 9.00788C27.4325 8.14174 27.4325 6.69817 26.5731 5.83202L26.5158 5.77428C26.0575 5.31234 25.4845 5.02362 24.8543 5.08137H21.9897Z"
        fill="#5F6368"
      />
      <path
        d="M32.8179 7.39108C34.0783 7.39108 35.0522 7.73754 35.797 8.43045C36.5418 9.12337 36.8856 10.0473 36.8856 11.2021V16.7454H35.2814V15.4751H35.2241C34.5366 16.5144 33.5627 17.0341 32.4168 17.0341C31.4429 17.0341 30.5835 16.7454 29.896 16.168C29.2658 15.5906 28.8647 14.7822 28.8647 13.916C28.8647 12.9921 29.2085 12.2415 29.896 11.664C30.5835 11.0866 31.5575 10.8556 32.7033 10.8556C33.7345 10.8556 34.5366 11.0289 35.1668 11.4331V11.0289C35.1668 10.4515 34.9377 9.87402 34.4793 9.52757C34.021 9.12337 33.4481 8.89239 32.8179 8.89239C31.8439 8.89239 31.0991 9.29659 30.5835 10.105L29.0939 9.18111C30.0106 7.96851 31.2137 7.39108 32.8179 7.39108ZM30.6408 13.9738C30.6408 14.4357 30.87 14.8399 31.2137 15.0709C31.6147 15.3596 32.0731 15.5328 32.5314 15.5328C33.2189 15.5328 33.9064 15.2441 34.422 14.7244C34.995 14.2047 35.2814 13.5696 35.2814 12.8766C34.7658 12.4724 34.021 12.2415 33.047 12.2415C32.3595 12.2415 31.7866 12.4147 31.3283 12.7612C30.87 13.0499 30.6408 13.4541 30.6408 13.9738Z"
        fill="#5F6368"
      />
      <path
        d="M46.1667 7.67978L40.4948 20.7874H38.776L40.8958 16.2257L37.1719 7.73752H39.0052L41.6979 14.2625H41.7552L44.3906 7.73752H46.1667V7.67978Z"
        fill="#5F6368"
      />
      <path
        d="M15.172 10.2205C15.172 9.70078 15.1147 9.18109 15.0575 8.66141H7.896V11.6063H11.9637C11.7918 12.5302 11.2762 13.3963 10.4741 13.916V15.8215H12.9377C14.37 14.4934 15.172 12.5302 15.172 10.2205Z"
        fill="#4285F4"
      />
      <path
        d="M7.89616 17.6693C9.95866 17.6693 11.6774 16.9764 12.9378 15.8215L10.4743 13.916C9.78678 14.378 8.92741 14.6667 7.89616 14.6667C5.94824 14.6667 4.22949 13.3386 3.65657 11.4908H1.13574V13.4541C2.45345 16.0525 5.03157 17.6693 7.89616 17.6693Z"
        fill="#34A853"
      />
      <path
        d="M3.65658 11.4908C3.31283 10.5669 3.31283 9.52755 3.65658 8.54593V6.58267H1.13574C0.0472006 8.71916 0.0472006 11.2598 1.13574 13.4541L3.65658 11.4908Z"
        fill="#FBBC04"
      />
      <path
        d="M7.89599 5.42782C8.98454 5.42782 10.0158 5.83202 10.8179 6.58268L12.995 4.38845C11.62 3.11811 9.78662 2.36745 7.95329 2.4252C5.0887 2.4252 2.45329 4.04199 1.19287 6.64042L3.7137 8.60367C4.22933 6.7559 5.94808 5.42782 7.89599 5.42782Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_4678_245158">
        <rect
          width="45.8333"
          height="22"
          fill="white"
          transform="translate(0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
