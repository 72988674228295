import { useFormContext } from 'react-hook-form';
import { FormValidationProps } from '../FormBlock.props';
import { usePartnerStore } from 'contexts/PartnerContext';

export const useFourthStep = (
  setOpenSubmitModal: (val: boolean) => void,
  currentStep: number
) => {
  const { trigger } = useFormContext<FormValidationProps>();

  const { isCompletedFormSteps } = usePartnerStore();
  isCompletedFormSteps.stepFour = currentStep === 4;

  const onValidateClick = async () => {
    const scrollToTop = window?.scrollTo({ top: 0, behavior: 'smooth' });
    if (trigger) {
      const isValid = await trigger();

      if (isValid) {
        setOpenSubmitModal(true);
      } else {
        return scrollToTop;
      }
    }
  };

  return {
    onValidateClick,
  };
};
