import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Logo.module.scss';
import LogoIcon from '../../../assets/img/LogoIcon';

export const Logo = () => (
  <NavLink to={'/'} className={classes.logo}>
    <LogoIcon />
  </NavLink>
);
