import CheckboxFilterList from 'components/shared/CheckboxFilterList';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import TooltipFilter from 'components/shared/TooltipFilter';
import PeriodFilter from '../../TableFilters/PeriodFilter';
import SearchFilter from '../../TableFilters/SearchFilter';
import classes from '../InvoicesTable.module.scss';
import { useTranslation } from 'react-i18next';
import { GetColumnFilterProps } from './GetColumnFilter.props';

const GetColumnFilter = ({
  columnId,
  globalSearch,
  openedSort,
  callbacks,
  setOpenedSort,
  invoicesContains,
  clientsContains,
  startPeriod,
  endPeriod,
  setCheckedActions,
  checkedActions,
  actionFilterOptions,
}: GetColumnFilterProps) => {
  const { t } = useTranslation();

  if (columnId === 'search') {
    return (
      <SearchFilter
        value={globalSearch}
        isOpen={openedSort === columnId}
        onInputChange={callbacks.onSearch}
        onClickOutside={() => setOpenedSort('')}
      >
        <button
          className={classes.table__search}
          onClick={() => callbacks.onOpenSortings(columnId)}
        >
          <SearchIcon />
        </button>
      </SearchFilter>
    );
  }

  if (columnId === 'invoices' || columnId === 'client') {
    return (
      <SearchFilter
        isOpen={openedSort === columnId}
        containsMode
        value={columnId === 'invoices' ? invoicesContains : clientsContains}
        onInputChange={(val) => callbacks.onContains(columnId, val)}
        onSortAsc={() => callbacks.onSort(columnId, 'asc')}
        onSortDesc={() => callbacks.onSort(columnId, 'desc')}
        onClickOutside={() => setOpenedSort('')}
      >
        <button
          className={classes.table__sort}
          onClick={() => callbacks.onOpenSortings(columnId)}
        >
          <FilterTableIcon />
        </button>
      </SearchFilter>
    );
  }

  if (columnId === 'issued') {
    return (
      <PeriodFilter
        isOpen={openedSort === columnId}
        startDate={startPeriod || undefined}
        endDate={endPeriod || undefined}
        onPeriodChange={callbacks.onPeriodChange}
        onClickOutside={() => setOpenedSort('')}
        onSortAsc={() => callbacks.onSort(columnId, 'asc')}
        onSortDesc={() => callbacks.onSort(columnId, 'desc')}
      >
        <button
          className={classes.table__sort}
          onClick={() => callbacks.onOpenSortings(columnId)}
        >
          <FilterTableIcon />
        </button>
      </PeriodFilter>
    );
  }

  if (columnId === 'amount') {
    return (
      <TooltipFilter
        open={openedSort === columnId}
        t={t}
        onClose={() => setOpenedSort('')}
        onSortAscending={() => callbacks.onSort(columnId, 'asc')}
        onSortDescending={() => callbacks.onSort(columnId, 'desc')}
      >
        <button
          className={classes.table__sort}
          onClick={() => callbacks.onOpenSortings(columnId)}
        >
          <FilterTableIcon />
        </button>
      </TooltipFilter>
    );
  }

  if (columnId === 'action') {
    return (
      <TooltipFilter
        t={t}
        hideSort
        open={openedSort === columnId}
        onClose={() => setOpenedSort('')}
        input={
          <CheckboxFilterList
            options={actionFilterOptions}
            checked={checkedActions}
            onCheckedChange={setCheckedActions}
            t={t}
          />
        }
      >
        <button
          className={classes.table__sort}
          onClick={() => callbacks.onOpenSortings(columnId)}
        >
          <FilterTableIcon />
        </button>
      </TooltipFilter>
    );
  }

  return null;
};

export default GetColumnFilter;
