import * as React from 'react';
import { SVGProps } from 'react';

export const H1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 32}
    height={props.height || 32}
    fill="none"
    {...props}
  >
    <path
      d="M3.95126 14.0909V4.54541H5.39147V8.69358H10.1502V4.54541H11.5951V14.0909H10.1502V9.92871H5.39147V14.0909H3.95126Z"
      fill="currentColor"
    />
    <path
      d="M14.6852 15.7315V11.024H14.6386L13.1658 12.0028V11.0147L14.6852 10.0079H15.6547V15.7315H14.6852Z"
      fill="currentColor"
    />
  </svg>
);
