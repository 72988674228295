import React, { FC, useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { toNbsp } from 'tools/toNbsp';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import { Autoplay, EffectFade, Pagination } from 'swiper';

import es_finances_desktop from 'assets/img/Registration/es_finances_desktop.png';
import es_projects_desktop from 'assets/img/Registration/es_projects_desktop.png';
import es_solutions_desktop from 'assets/img/Registration/es_solutions_desktop.png';
import finances_desktop from 'assets/img/Registration/finances_desktop.png';
import projects_desktop from 'assets/img/Registration/projects_desktop.png';
import solutions_desktop from 'assets/img/Registration/solutions_desktop.png';

import es_finances_desktop2x from 'assets/img/Registration/es_finances_desktop_2x.png';
import es_projects_desktop2x from 'assets/img/Registration/es_projects_desktop_2x.png';
import es_solutions_desktop2x from 'assets/img/Registration/es_solutions_desktop_2x.png';
import finances_desktop2x from 'assets/img/Registration/finances_desktop_2x.png';
import projects_desktop2x from 'assets/img/Registration/projects_desktop_2x.png';
import solutions_desktop2x from 'assets/img/Registration/solutions_desktop_2x.png';

import es_finances_mobile from 'assets/img/Registration/es_finances_mobile.png';
import es_projects_mobile from 'assets/img/Registration/es_projects_mobile.png';
import es_projects_mobile_2 from 'assets/img/Registration/es_projects_mobile2.png';
import es_solutions_mobile from 'assets/img/Registration/es_solutions_mobile.png';
import finances_mobile from 'assets/img/Registration/finances_mobile.png';
import projects_mobile from 'assets/img/Registration/projects_mobile.png';
import solutions_mobile from 'assets/img/Registration/solutions_mobile.png';

import i18n from 'i18n/i18n';

import clsx from 'clsx';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useTranslation } from 'react-i18next';
import classes from './SwiperBlock.module.scss';

const imageByLng: any = {
  en: {
    image1: solutions_desktop,
    image2: projects_desktop,
    image3: finances_desktop,
    imageMobile1: solutions_mobile,
    imageMobile2: projects_mobile,
    imageMobile3: finances_mobile,
    retina1: solutions_desktop2x,
    retina2: projects_desktop2x,
    retina3: finances_desktop2x,
  },
  es: {
    image1: es_solutions_desktop,
    image2: es_projects_desktop,
    image3: es_finances_desktop,
    imageMobile1: es_solutions_mobile,
    imageMobile2: es_projects_mobile,
    imageMobile3: es_finances_mobile,
    imageMobile4: es_projects_mobile_2,
    retina1: es_solutions_desktop2x,
    retina2: es_projects_desktop2x,
    retina3: es_finances_desktop2x,
  },
};

const getMockData = (language: string, isMobile: boolean) => [
  {
    id: 0,
    title: i18n.t(
      'Start your journey with us and unleash your full potential!'
    ),
    text: i18n.t('All digital marketing in one place'),
    image: imageByLng[language]?.image1,
    imageMobile: imageByLng[language]?.imageMobile1,
    retina: `${imageByLng[language]?.retina1} 2x`,
    alt: 'Kit Global Solutions page preview',
  },
  {
    id: 1,
    title: i18n.t('Getting started is quick and easy!'),
    text: i18n.t('Many services, unlimited sources, more result'),
    image: imageByLng[language]?.image2,
    imageMobile:
      language === 'es' && isMobile
        ? imageByLng[language]?.imageMobile4
        : imageByLng[language]?.imageMobile2,
    retina: `${imageByLng[language]?.retina2} 2x`,
    alt: 'Kit Global Projects page preview',
  },
  {
    id: 2,
    title: i18n.t('Join us now and discover endless possibilities!'),
    text: i18n.t('Transparent process from order to production'),
    image: imageByLng[language]?.image3,
    imageMobile: imageByLng[language]?.imageMobile3,
    retina: `${imageByLng[language]?.retina3} 2x`,
    alt: 'Kit Global Finances page preview',
  },
];

export const SwiperBlock: FC = () => {
  const { isMediaTablet, isPhone } = useWindowWidth();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [mockData, setMockData] = useState(
    getMockData(i18n.language, isMediaTablet)
  );

  useEffect(() => {
    setMockData(getMockData(i18n.language, isMediaTablet));
  }, [i18n.language, isMediaTablet]);

  return (
    <div className={classes['swiper-wrapper']}>
      <Swiper
        pagination={!isPhone}
        effect={'fade'}
        noSwiping
        noSwipingClass="no-swiping"
        modules={[EffectFade, Pagination, Autoplay]}
        className={clsx(classes.swiper, 'no-swiping')}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {mockData.map((item, index: number) => (
          <SwiperSlide className={classes['swiper-slider']} key={index}>
            {({ isActive }) => (
              <div
                className={clsx(classes.content, isActive && classes.active)}
              >
                {!isMediaTablet && (
                  <img
                    src={item.image}
                    srcSet={item.retina}
                    alt={item.alt}
                    className={classes.image}
                  />
                )}

                {isMediaTablet && (
                  <img
                    src={item.imageMobile}
                    alt={item.alt}
                    className={classes.image}
                  />
                )}

                {!isMediaTablet && (
                  <img
                    src={item.imageMobile}
                    alt=""
                    className={classes['image-ontop']}
                  />
                )}

                <div className={classes.body}>
                  <span className={classes.title}>{toNbsp(t(item.title))}</span>
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
