import clsx from 'classnames';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Mobile.module.scss';
import { MobileModalProps } from './Mobile.props';

const MobileModal = ({
  title,
  setOpen,
  open,
  onSelect,
  data,
  isPriceTag,
  buttonTextOptions,
  select,
  setSelect,
}: MobileModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const header = document.querySelector('#global-header-id') as HTMLElement;
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      header?.style.setProperty('z-index', '0');
    } else {
      header?.style.removeProperty('z-index');
    }

    return () => {
      header?.style.removeProperty('z-index');
    };
  }, [header, open]);

  const handleClose = () => {
    if (buttonTextOptions && buttonTextOptions?.onCancel) {
      buttonTextOptions?.onCancel();
    }
    if (setOpen) {
      setIsClosing(true);
      setOpen(false);
    }
  };

  return (
    <>
      <div
        className={clsx(classes.overlay, { [classes.close]: isClosing })}
        onClick={() => {
          setOpen && setOpen(!open);
          handleClose();
        }}
      />
      <div
        className={clsx(classes.fixedButton, { [classes.close]: isClosing })}
      >
        <div className={classes.title}>
          <div>{title}</div>
          <UiIcon
            name="CrossIcon"
            onClick={() => {
              setOpen && setOpen(!open);
              handleClose();
            }}
          />
        </div>
        {data?.length && (
          <div className={classes.items}>
            {data?.map((item: any, index: number) => (
              <Button
                key={`${item.id}${index}`}
                className={clsx(
                  classes.button,
                  isPriceTag && classes.priceName
                )}
                onClick={() => {
                  setOpen && setOpen(!open);
                  handleClose();
                  if (item?.id !== select?.id) {
                    setSelect(item);
                    onSelect && onSelect(item);
                  }
                }}
              >
                {t(item?.name)}
                {isPriceTag && (
                  <span className={classes.price}>{item?.value}</span>
                )}
              </Button>
            ))}
          </div>
        )}

        {buttonTextOptions && (
          <div className={classes.modalContainer}>
            <div className={classes.bottomBlock}>
              {!buttonTextOptions?.hideCancel && (
                <Button
                  className={classes.bottomBlock__button}
                  theme="light"
                  onClick={buttonTextOptions.onCancel}
                >
                  {buttonTextOptions.cancel}
                </Button>
              )}
              {!buttonTextOptions?.hideSubmit && (
                <Button
                  className={classes.bottomBlock__button}
                  theme="default"
                  type="button"
                  onClick={buttonTextOptions.onSubmit}
                >
                  {buttonTextOptions.submit}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileModal;
