import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './TextEditor.module.scss';
import { TextEditorProps } from './TextEditor.props';
import CharacterCount from '@tiptap/extension-character-count';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { useEditor, EditorContent, EditorProvider } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ToolBar from './Toolbar';
import './Toolbar/Toolbar.scss';
import clsx from 'clsx';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import UiIcon from 'components/shared/Icon';

const TextEditor: FC<TextEditorProps> = ({
  title,
  onChange,
  defaultValue,
  error,
  maxLength,
  endIcon,
  t,
}) => {
  const extensions = [
    StarterKit,
    BulletList.configure({
      HTMLAttributes: {
        class: 'list-disc',
      },
    }),
    ListItem,
    Underline,
    CharacterCount.configure({
      limit: maxLength,
      mode: 'textSize',
    }),
  ];

  const editor = useEditor({
    extensions,
    onFocus: () => setFocused(true),
    content: undefined,
  });

  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIcon || {};

  const inputRef = useRef<HTMLDivElement>(null);

  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef?.current &&
        !inputRef.current?.contains(event?.target as Node)
      ) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const withContent =
    (defaultValue || value).length > 0 && (defaultValue || value) !== '<p></p>';

  return (
    <>
      <div
        ref={inputRef}
        className={clsx(
          classes.textEditorWrapper,
          focused && classes.focused,
          withContent && classes.withContent,
          error && classes.error
        )}
        onFocus={handleFocus}
      >
        <div className={classes.content} onClick={handleFocus}>
          {!focused && (
            <div
              className={clsx(
                classes.label,
                !focused && !withContent && classes.label_centered
              )}
              onClick={handleFocus}
            >
              {title}
            </div>
          )}
          <EditorProvider
            slotBefore={
              focused && (
                <ToolBar label={title} focused={focused} setValue={setValue} />
              )
            }
            autofocus={focused}
            onFocus={handleFocus}
            extensions={extensions}
            content={defaultValue}
            editorContainerProps={{
              className: clsx(
                classes.editorContainer,
                !focused && !withContent && classes.hidden
              ),
            }}
          >
            <EditorContent
              onFocus={handleFocus}
              editor={editor}
              className={classes.hidden}
              maxLength={maxLength}
            />
          </EditorProvider>
        </div>
        {endIconName && (
          <TooltipContainer
            text={endIconTooltipText}
            customClasses={'kit-ui-block'}
            position={endIconTooltipPosition}
            className={endIconTooltipClassName}
          >
            <UiIcon
              name={endIconName}
              additionalClassName={clsx(
                classes.endIcon,
                endIconClassName,
                error && classes.error
              )}
            />
          </TooltipContainer>
        )}
      </div>
      {error && (
        <div className={classes.errorText}>
          {error || t('Fill in the field')}
        </div>
      )}
    </>
  );
};

export default TextEditor;
