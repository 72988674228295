import React, { createContext, useContext } from 'react';
import { PartnerStore, partnerStore } from '../stores/partnerStore';

const PartnerContext = createContext<PartnerStore | null>(null);

export const PartnerProvider = ({ children }: any) => (
  <PartnerContext.Provider value={partnerStore}>
    {children}
  </PartnerContext.Provider>
);

export const usePartnerStore = () => {
  const context = useContext(PartnerContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with PartnerProvider'
    );
  }
  return context;
};
