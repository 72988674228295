import { SuccessIcon } from 'components/shared/Icons/SuccessIcon';
import { useTranslation } from 'react-i18next';
import { toNbsp } from 'tools/toNbsp';
import classes from './Success.module.scss';

interface Props {
  email: string;
  resendEmail: () => void;
}

export const Success = ({ email, resendEmail }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        <SuccessIcon />

        <span>
          {toNbsp(
            t('Congratulations! Your account has been successfully created')
          )}
        </span>
      </div>

      <div className={classes.body}>
        <div className={classes.title}>{t('Email verification')}</div>
        <div className={classes.text}>
          {t('We’ve sent you a verification link to your email address')}{' '}
          <span>{`${email}.`}</span> {t('Please check your email')}.
        </div>

        <div className={classes.help}>
          {t('Can’t find it? Check your spam folder or')}
          <span onClick={resendEmail} className={classes.link}>
            {t('resend verification link')}
          </span>
          .
        </div>
      </div>
    </div>
  );
};
