import React, { FC, useEffect } from 'react';
import '../styles/main.scss';
import { useTranslation } from 'react-i18next';
import { AddSolution } from '../containers/AddSolution';
import { PARTNER_ROLE } from 'types/user';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../stores/user-store';

export const AddSolutionPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const partnerPermission = userStore?.partners?.results?.[0]?.permission?.type;

  const isViewer = partnerPermission === PARTNER_ROLE.VIEWER;

  useEffect(() => {
    if (isViewer) {
      navigate('/solutions');
    }
  }, []);

  return <AddSolution title={t('Adding new Solution')} />;
};
