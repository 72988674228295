export enum ProjectDealStageTypeEnum {
  ANALYSIS = 'ANALYSIS',
  BRIEFING = 'BRIEFING',
  CREATION = 'PROPOSAL_CREATION',
  PRESENTED = 'PROPOSAL_PRESENTED',
  NEGOTIATIONS = 'NEGOTIATIONS',
  SIGNING = 'SIGNING_AGREEMENT',
  SIGNED = 'AGREEMENT_SIGNED',
  ASSIGNMENT = 'ACCOUNT_ASSIGNMENT',
  ONBOARDING = 'ONBOARDING',
  PREPARATION_LAUNCH = 'PREPARATION_FOR_LAUNCH',
  LAUNCHED = 'LAUNCHED',
  PRODUCTION = 'PRODUCTION',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export enum ProjectStatusEnum {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}
