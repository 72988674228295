import { TFunction } from 'i18next';
import { ProjectsQueryParams } from './types';

export const projectsQueryParamsHandler = ({
  order,
  page,
  page_size: pageSize,
  status,
}: ProjectsQueryParams) => {
  const queryParams = {
    page,
    order,
    pageSize,
    status,
  };

  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};

export const selectOptions = (t: TFunction) => [
  { id: 1, name: `${t('Start date')}: ${t('new ones first')}` },
  { id: 2, name: `${t('Start date')}: ${t('old ones first')}` },
  { id: 3, name: `${t('Price')}: ${t('high to low')}` },
  { id: 4, name: `${t('Price')}: ${t('low to high')}` },
  { id: 5, name: `${t('Status')}` },
];

export const selectOptionsRu = (t: TFunction) => [
  { id: 1, name: `${t('New ones first')}` },
  { id: 2, name: `${t('Old ones first')}` },
  { id: 3, name: `${t('High to low')}` },
  { id: 4, name: `${t('Low to high')}` },
  { id: 5, name: `${t('Status filter')}` },
];

export const defaultSortParams = (sortSelect: { id: number }) => {
  switch (sortSelect?.id) {
    case 1:
      return 'created_desc';
    case 2:
      return 'created_asc';
    case 3:
      return 'cost_desc';
    case 4:
      return 'cost_asc';
    case 5:
      return 'status_asc';
  }
};
