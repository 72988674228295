import React, { FC } from 'react';
import { NewPasswordContainer } from 'containers/NewPassword';
import '../styles/main.scss';

// todo добавить функцию локализации
export const NewPassword: FC = () => (
  <>
    <NewPasswordContainer />
  </>
);
