import React, { ReactNode } from 'react';
import { userStore, UserStore } from 'stores/user-store';

const UserContext = React.createContext<UserStore | null>(null);

export const UserProvider = ({ children }: { children: ReactNode }) => (
  <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
);

export const useUserStore = () => {
  const context = React.useContext(UserContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with UserProvider'
    );
  }
  return context;
};
