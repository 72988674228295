import classes from './ListAccordionHeader.module.scss';
import { ProjectType } from '../types';
import FormatPrice from '../../../components/shared/FormatPriceAndCurrency';
import { useTranslation } from 'react-i18next';
import { Currency } from '@type/finance';

type Props = {
  title: string;
  totalCost: number;
  currency: Currency;
  projects: Omit<ProjectType, 'solution'>[];
};

function ListAccordionHeader(props: Props) {
  const { t } = useTranslation();
  const { title, totalCost, currency, projects } = props || {};
  const projectsAmount =
    projects?.length === 1
      ? t('{{word}} project', { word: projects?.length })
      : t('{{word}} projects', { word: projects?.length });
  return (
    <div className={classes.header}>
      <span className={classes.header__title}>{title}</span>
      <div className={classes.header__info}>
        <span className={classes.header__projects}>{projectsAmount}</span>
        <span className={classes.header__cost}>
          <FormatPrice currency={currency?.symbol} price={totalCost} />
          {/* <span style={{ fontFamily: "Inter" }}>$</span>164 000 */}
        </span>
      </div>
    </div>
  );
}

export default ListAccordionHeader;
