import logo from 'assets/img/logo.png';
import success from 'assets/img/Registration/success.png';
import { Button } from 'components/shared/Button';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { Link } from 'components/shared/Link';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './Registration.module.scss';
import { RegistrationForm } from './RegistrationForm';
import { Success } from './Success';
import { SwiperBlock } from './SwiperBlock';
import { useRegistration } from './useRegistarion';

export const RegistrationContainer = () => {
  const {
    step,
    setStep,
    t,
    setSendEmail,
    sendEmail,
    setSuccessData,
    resendEmail,
  } = useRegistration();

  return (
    <>
      <TitleAndDescription pageName="Registration" />

      <div className={classes.container}>
        <Link href={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo} alt="" className={classes.logo} />
        </Link>

        <div className={classes.left}>
          {step !== 3 ? (
            <SwiperBlock />
          ) : (
            <img src={success} alt="" className={classes.success} />
          )}
        </div>

        <div className={classes.right}>
          {step !== 3 && (
            <div className={classes.steps}>
              {step === 2 && (
                <Button
                  theme="text"
                  onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo(0, 0);
                    setStep(1);
                  }}
                >
                  <LeftArrowIcon className={classes.arrow} />
                </Button>
              )}
              {t('Step {{step}} of 2', { step })}
            </div>
          )}

          {step !== 3 && (
            <div className={classes.title}>
              {step === 1
                ? t('Welcome to KIT Global Platform')
                : t('Business details')}
            </div>
          )}

          {step !== 3 && (
            <div className={classes.text}>
              {step === 1 && (
                <>
                  <span>
                    {t('Fill in your details to create your account')}
                  </span>
                  <span>
                    {t('Already have an account')}?
                    <Link className={classes.link} href="/login">
                      {t('Log in')}
                    </Link>
                  </span>
                </>
              )}

              {step === 2 && (
                <span>
                  {t(
                    'Tell us more about your business to find more relevant clients'
                  )}
                </span>
              )}
            </div>
          )}

          {step !== 3 ? (
            <RegistrationForm
              setSendEmail={setSendEmail}
              step={step}
              setStep={setStep}
              setSuccessData={setSuccessData}
            />
          ) : (
            <Success email={sendEmail} resendEmail={resendEmail} />
          )}
        </div>
      </div>
    </>
  );
};
