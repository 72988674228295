import React, { FC, useEffect } from 'react';
import { useCurrentEditor } from '@tiptap/react';
import './Toolbar.scss';
import UiIcon from 'components/shared/Icon';
import classes from '../TextEditor.module.scss';
import { ToolbarProps } from './Toolbar.props';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from '../../../shared/Tooltip/TooltipContainer';
import { RUSSIAN_LOCALE } from '../../../../utils';

const ToolBar: FC<ToolbarProps> = ({ label, focused, setValue }) => {
  const { editor } = useCurrentEditor();
  const content = editor?.getHTML() || '';
  const { t } = useTranslation();

  useEffect(() => {
    if (setValue) {
      setValue(content);
    }
  }, [setValue, content]);

  useEffect(() => {
    if (editor && focused) {
      editor.commands.focus();
    }
  }, [focused, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.toolbar}>
      <div
        className={clsx(
          classes.buttonGroup,
          RUSSIAN_LOCALE && classes.buttonGroup__russian
        )}
      >
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().undo().run();
          }}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <TooltipContainer
            text={t('Undo')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="UndoIcon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>
        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().redo().run();
          }}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <TooltipContainer
            text={t('Redo')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="RedoIcon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>

        <span className={classes.divider} />

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Bold')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="TextBoldIcon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Italic')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="TextItalicIcon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleUnderline().run();
          }}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Underline')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon
              name="TextUnderlineIcon"
              additionalClassName={classes.icon}
            />
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Strike through')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="TextStrikeIcon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().clearContent().run();
          }}
          disabled={!editor.can().chain().focus().clearContent().run()}
          className={editor.isActive('clear') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Clear')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon
              name="TextEditorClearIcon"
              additionalClassName={classes.icon}
            />
          </TooltipContainer>
        </button>

        <span className={classes.divider} />

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
          className={
            editor.isActive('heading', { level: 1 }) ? classes.active : ''
          }
        >
          <TooltipContainer
            text={t('Heading 1')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="H1Icon" additionalClassName={classes.icon} />
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }}
          className={
            editor.isActive('heading', { level: 2 }) ? classes.active : ''
          }
        >
          <TooltipContainer
            text={t('Heading 2')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="H2Icon" additionalClassName={classes.icon} />{' '}
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleHeading({ level: 3 }).run();
          }}
          className={
            editor.isActive('heading', { level: 3 }) ? classes.active : ''
          }
        >
          <TooltipContainer
            text={t('Heading 3')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="H3Icon" additionalClassName={classes.icon} />{' '}
          </TooltipContainer>
        </button>

        <span className={classes.divider} />

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editor.isActive('bulletList') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Bullet list')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="BulletListIcon" additionalClassName={classes.icon} />{' '}
          </TooltipContainer>
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={editor.isActive('orderedList') ? classes.active : ''}
        >
          <TooltipContainer
            text={t('Numbered list')}
            customClasses={'kit-ui-block'}
            position={'bottom'}
            className={classes.tooltip}
            classNameTooltip={classes.tooltipContent}
            tooltipInner={classes.tooltipInner}
          >
            <UiIcon name="NumberListIcon" additionalClassName={classes.icon} />{' '}
          </TooltipContainer>
        </button>
      </div>
      <div className={clsx(classes.label, focused && classes.focused)}>
        {label || 'Label'}
      </div>
    </div>
  );
};

export default ToolBar;
