import api from './http';

export default class Search {
  static async getSearchResults(
    resource: string,
    searchTerm: string,
    limit?: number,
    offset?: number
  ) {
    const pageSizeValue =
      resource === 'projects'
        ? '&page_size=20'
        : limit
          ? `&page_size=${limit}`
          : '';
    const pageValue =
      resource === 'projects' ? '&page=1' : offset ? `&page=${offset}` : '';

    return api.get(
      `/api/v1/partners/search/?resource=${encodeURIComponent(
        resource
      )}&search=${encodeURIComponent(searchTerm)}${pageSizeValue}${pageValue}`
    );
  }

  static async getFastSearchResults(searchTerm: string) {
    return api.get(
      `/api/v1/partners/search/fast_search/?search=${encodeURIComponent(
        searchTerm
      )}`
    );
  }
}
