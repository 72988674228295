import { toast, ToastOptions } from 'react-toastify';
import { Toast } from 'components/shared/Toast';

interface Props {
  type: 'success' | 'warning' | 'error';
  children?: string;
  toastProps?: ToastOptions;
  title?: string;
}

export function ShowNotification({ type, children, toastProps, title }: Props) {
  return toast(<Toast type={type} title={title} text={children} />, {
    ...toastProps,
    className: 'toast-container-big',
  });
}
