import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const ProjectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M20.28 6.79024H12.2025L9.56325 4.05366C9.52967 4.01958 9.4855 4.00043 9.4395 4H3.72C3.32175 4 3 4.34878 3 4.78049V19.2195C3 19.6512 3.32175 20 3.72 20H20.28C20.6782 20 21 19.6512 21 19.2195V7.57073C21 7.13902 20.6782 6.79024 20.28 6.79024ZM19.38 18.2439H4.62V5.7561H8.86125L11.5522 8.54634H19.38V18.2439Z"
      fill="url(#paint0_linear_5600_41428)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41428"
          x1="-4.93252"
          y1="-4.88"
          x2="12.1866"
          y2="16.4109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_5600_41428"
          x1="0.560002"
          y1="2.32"
          x2="18.88"
          y2="23.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E3CFF" />
          <stop offset="1" stopColor="#4BA5FF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
