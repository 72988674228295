import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { EmptyIcon } from 'components/shared/Icons/EmptyIcon';
import { Modal } from 'components/shared/Modal';
import DocumentsList from 'containers/Finances/DocumentsList';
import { useEffect, useState } from 'react';
import classes from './DocumentsCard.module.scss';
import { DocumentsCardProps } from './DocumentsCard.props';
import { useTranslation } from 'react-i18next';

function DocumentsCard({
  isMobile,
  documents,
  onMoreClick,
}: DocumentsCardProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [expandList, setExpandList] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setExpandList(false);
    }
  }, [isMobile]);

  return (
    <>
      <div className={classes.card}>
        <div className={classes.card__header}>
          <span className={classes.card__title}>{t('Documents')}</span>

          {documents.length > 5 && !isMobile && (
            <Button
              theme="text"
              onClick={() => {
                setOpenModal(true);
                onMoreClick?.();
              }}
            >
              {t('See more')}
            </Button>
          )}
        </div>

        {documents.length ? (
          <DocumentsList
            documents={expandList ? documents : documents.slice(0, 5)}
          />
        ) : (
          <div className={classes.card__empty}>
            <EmptyIcon />
            <span>{t("You don't have any documents")}</span>
          </div>
        )}

        {isMobile && documents.length > 5 && (
          <Button
            className={clsx(
              classes.card__more,
              expandList && classes['card__more--active']
            )}
            theme="text"
            onClick={() => {
              setExpandList((prev) => !prev);
              onMoreClick?.();
            }}
          >
            {t(`See ${expandList ? 'less' : 'more'}`)}
            <ArrowDownIcon />
          </Button>
        )}
      </div>

      <Modal
        title="Documents"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className={classes.modal}>
          <span className={classes.modal__title}>{t('Documents')}</span>
          <DocumentsList
            documents={documents}
            additionalClass={classes.modal__documents}
          />
        </div>
      </Modal>
    </>
  );
}

export default DocumentsCard;
