import classes from '../PartnerContainer.module.scss';
import { Button } from 'components/shared/Button';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import UiIcon from 'components/shared/Icon';
import clsx from 'clsx';
import { PARTNER_ROLE } from 'types/user';
import { userStore } from 'stores/user-store';
import React from 'react';

const EmptyBlock = () => {
  const { clearCopyOfService } = usePartnerStore();
  const { t } = useTranslation();

  const isPartnerViewer =
    userStore?.partners?.results?.[0]?.permission?.type === PARTNER_ROLE.VIEWER;

  return (
    <div className={classes.emptyBlock}>
      <div className={classes.emptyBlock__title}>
        <h1>{t('You have no added Solutions')}</h1>
      </div>

      <div className={classes.emptyBlock__description}>
        <p>{t('Create your first solution and start earning today')}</p>
      </div>

      <div
        className={clsx(
          classes.emptyBlock__button,
          isPartnerViewer && classes.disabled
        )}
      >
        <NavLink to="/solutions/add-solution/" data-step-empty_solutions_p-1>
          <Button
            theme="primary"
            className={classes.mainButton}
            onClick={clearCopyOfService}
            disabled={isPartnerViewer}
          >
            <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
            {t('Add solution')}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default EmptyBlock;
