import { makeAutoObservable } from 'mobx';
import Partner from '../services/partner';
import { userStore } from './user-store';
import { ShowNotification } from '../tools/showNotification';
import { getLangName } from '../tools/utils';
import i18n from 'i18next';
import { AxiosError } from 'axios';
import { FILTERS_LABELS, Status } from '../constants/enum';
import i18next from 'i18next';

export class PartnerStore {
  constructor() {
    makeAutoObservable(this);
  }

  services = [];
  servicesCount = 0;
  statusCount = {
    current_filter: 0,
    active: 0,
    in_review: 0,
    archived: 0,
    rejected: 0,
  };
  copiedService = {};
  nextServices = '';
  prevServices = '';
  analytics = [];
  singleService = {};
  goals = [];
  isCompletedFormSteps = {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  };

  fetchPartnerServices = async (params: string) => {
    try {
      if (!userStore.partners) {
        await userStore.loadPartner();
      }
      const partnerId = userStore.partners?.results[0]?.id;

      
if (!partnerId) {
  throw new Error('no partnerId')
  }

      const response = await Partner.getServices(partnerId, params);
      if (response?.status === 200) {
        const {
          results,
          allServices,
          count,
          archived_count,
          active_count,
          review_count,
          rejected_count,
        } = response?.data;
        this.services = results;
        this.servicesCount = allServices;
        this.statusCount = {
          current_filter: count,
          active: active_count,
          in_review: review_count,
          archived: archived_count,
          rejected: rejected_count,
        };
        this.nextServices = response?.data?.next;
        this.prevServices = response?.data?.previous;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching services'),
          });
        }
      }
    }
  };

  fetchSingleService = async (serviceSlug: string) => {
    try {
      const response = await Partner.getSingleService(serviceSlug);
      if (response?.status === 200) {
        const serviceData = response?.data;
        const getStatus = (value: Status) => {
          switch (value) {
            case Status.active: {
              return FILTERS_LABELS.ACTIVE;
            }
            case Status.reviewed: {
              return FILTERS_LABELS.IN_REVIEW;
            }
            case Status.archived: {
              return FILTERS_LABELS.ARCHIVED;
            }
            case Status.rejected: {
              return FILTERS_LABELS.REJECTED;
            }
            case Status.moderated: {
              return FILTERS_LABELS.IN_REVIEW;
            }
          }
        };
        const status: Status = serviceData.status;
        this.singleService = {
          status: getStatus(status),
          name: getLangName(serviceData, 'name', i18n),
          id: serviceData?.id,
          currency: userStore?.user?.currency,
          files: serviceData.files.map((file: any) => ({
            name: file.name,
            link: file?.link,
            size: file?.file_size,
            id: file?.id,
          })),
          worksFor: serviceData?.available_for,
          service_type: serviceData?.service_type,
          description: getLangName(serviceData, 'description', i18n),
          full_description: getLangName(serviceData, 'description', i18n),
          short_description: getLangName(
            serviceData,
            'short_description',
            i18n
          ),
          period_comment: getLangName(serviceData, 'period_comment', i18n),
          product: serviceData?.product,
          stages: serviceData?.service_stage,
          cases: serviceData?.case,
          company_size: serviceData?.company_size,
          business_type: serviceData?.business_type,
          goals: serviceData?.goals,
          cost: serviceData?.cost_with_discounts,
          price_per: serviceData?.price_per,
          is_free: serviceData?.is_free,
          countries: serviceData?.countries,
          slug: serviceData?.slug,
          payment_types: serviceData?.payment_type,
        };
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching service'),
          });
        }
      }
    }
  };

  fetchSingleServiceAnalytics = async (params: string, serviceSlug: string) => {
    try {
      const response = await Partner.getSingleServiceAnalytics(
        params,
        serviceSlug
      );
      if (response?.status === 200) {
        this.analytics = response?.data.results;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching service'),
          });
        }
      }
    }
  };

  fetchSolutionGoals = async () => {
    const response = await Partner.getSolutionGoals();
    this.goals = response?.data?.results;
  };

  createPartnerSolution = async (data: any) => {
    try {
      const response = await Partner.createSolution(data);
      if (response?.status === 201) {
        ShowNotification({
          type: 'success',
          children: i18next.t('Solution created successfully'),
        });
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error creating solution'),
          });
        }
      }
    }
  };

  setCopyOfService = (service: any) => {
    this.copiedService = service;
  };

  clearCopyOfService = () => {
    this.copiedService = {};
    this.singleService = {};
  };

  clearCompletedFormSteps = () => {
    this.isCompletedFormSteps = {
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
    };
  };

  clearStore = () => {
    this.servicesCount = 0;
    this.services = [];
    this.clearCompletedFormSteps();
    this.clearCopyOfService();
  };
}

export const partnerStore = new PartnerStore();
