import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const TimerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 16.667C11.7684 16.667 13.4641 15.9646 14.7144 14.7144C15.9646 13.4641 16.667 11.7684 16.667 10.0003C16.667 8.23222 15.9646 6.53652 14.7144 5.28628C13.4641 4.03604 11.7684 3.33366 10.0003 3.33366C8.23222 3.33366 6.53652 4.03604 5.28628 5.28628C4.03604 6.53652 3.33366 8.23222 3.33366 10.0003C3.33366 11.7684 4.03604 13.4641 5.28628 14.7144C6.53652 15.9646 8.23222 16.667 10.0003 16.667ZM10.0003 1.66699C11.0947 1.66699 12.1783 1.88254 13.1894 2.30133C14.2004 2.72012 15.1191 3.33395 15.8929 4.10777C16.6667 4.88159 17.2805 5.80025 17.6993 6.8113C18.1181 7.82234 18.3337 8.90598 18.3337 10.0003C18.3337 12.2105 17.4557 14.3301 15.8929 15.8929C14.3301 17.4557 12.2105 18.3337 10.0003 18.3337C5.39199 18.3337 1.66699 14.5837 1.66699 10.0003C1.66699 7.79019 2.54497 5.67057 4.10777 4.10777C5.67057 2.54497 7.79019 1.66699 10.0003 1.66699Z"
      fill="#8362F3"
    />
    <path
      d="M10.0003 16.667C11.7684 16.667 13.4641 15.9646 14.7144 14.7144C15.9646 13.4641 16.667 11.7684 16.667 10.0003C16.667 8.23222 15.9646 6.53652 14.7144 5.28628C13.4641 4.03604 11.7684 3.33366 10.0003 3.33366C8.23222 3.33366 6.53652 4.03604 5.28628 5.28628C4.03604 6.53652 3.33366 8.23222 3.33366 10.0003C3.33366 11.7684 4.03604 13.4641 5.28628 14.7144C6.53652 15.9646 8.23222 16.667 10.0003 16.667Z"
      fill=""
    />
    <path
      d="M10 6V10.2L13.5 12.5"
      stroke="#8362F3"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
