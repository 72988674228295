import * as React from 'react';
import { SVGProps } from 'react';

export const SearchIconAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g id="Search 2">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7304 5.51785C10.7387 3.52617 7.50953 3.52617 5.51785 5.51785C3.52617 7.50953 3.52617 10.7387 5.51785 12.7304C7.50953 14.722 10.7387 14.722 12.7304 12.7304C14.722 10.7387 14.722 7.50953 12.7304 5.51785ZM4.51611 4.51611C7.06104 1.97119 11.1872 1.97119 13.7321 4.51611C16.1078 6.89179 16.2658 10.6454 14.2061 13.2043L17.3926 16.3909L16.3909 17.3926L13.2043 14.2061C10.6454 16.2658 6.89179 16.1078 4.51611 13.7321C1.97119 11.1872 1.97119 7.06104 4.51611 4.51611Z"
        fill="#9C8B8B"
      />
    </g>
  </svg>
);
