import * as React from 'react';
import { SVGProps } from 'react';

export const CheckCirleIconPurple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    fill="none"
    {...props}
  >
    <path d="M0 .5h20v20H0z" fill="none" />
    <path
      data-color="svg-main-color"
      fillRule="evenodd"
      d="M5.37 3.57a8.333 8.333 0 1 1 9.26 13.859A8.333 8.333 0 0 1 5.37 3.57ZM15 7.075v-.001c.162-.215.032-.566-.277-.566h-.856a.634.634 0 0 0-.509.26l-4.851 6.49-2.223-2.974a.646.646 0 0 0-.508-.258H4.92c-.313 0-.436.354-.278.566L8 15.082a.63.63 0 0 0 1.016.001L15 7.073Z"
      clipRule="evenodd"
    />
  </svg>
);
