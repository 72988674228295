import { CompanyProduct, Country, Language, Manager } from './common';
import { Currency } from './finance';
import { LocalizedNames } from '@type/util-types';

export type User = {
  id: number;
  manager: Manager | null;
  company_industry: number | null;
  company_link: string;
  company_name: string;
  company_size: string | null;
  country: Country | null;
  currency: Currency;
  created: string;
  date_joined: string;
  email: string;
  email_confirmed: boolean;
  full_name: string;
  is_active: boolean;
  is_confirmed_local_preferences: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  language: Language;
  modified: string | null;
  moderated: boolean;
  onboarding_passed: boolean;
  onboarding_partner_passed: boolean;
  phone_number: string;
  roles: string[];
  source_page: string;
  sources: { id: number; name: string }[];
  user_office: {
    id: number;
    name: string;
  } | null;
  user_role_in_company: string | null;
  zoho_account_id: string;
  zoho_contact_id: string;
  zoho_lead_id: string | null;
  user_products: CompanyProduct[];
};

export enum PARTNER_STATUS {
  REVIEW = 'REVIEW',
  MODERATED = 'MODERATED',
  REJECTED = 'REJECTED',
  ACTIVE_ON_PLATFORM = 'ACTIVE_ON_PLATFORM',
  ARCHIEVED = 'ARCHIEVED',
}

export type PermissionType = LocalizedNames & {
  type: PARTNER_ROLE;
};

export type LegalEntitiesType = {
  name: string;
  tax_id: string;
};

export type ProductType = LocalizedNames & {
  id: number;
};

export type Partner = {
  id: number;
  status: PARTNER_STATUS;
  is_active: boolean;
  name: string;
  link: string;
  office: number;
  permission: PermissionType;
  legal_entities: LegalEntitiesType[];
  product_types: ProductType[];
};

export type UpdateUserRequest = {
  old_password?: string;
  new_password?: string;
  first_name?: string;
  last_name?: string;
  language?: string;
  phone_number?: string;
  company_name?: string;
  user_role_in_company?: string;
  user_products?: number[];
  sources?: {
    id: number;
    name: string;
  }[];
  goal?: string;
  other_field_value?: string;
  onboarding_partner_passed?: boolean;
};

export type UpdateUserResponse = {
  is_valid: boolean;
  user: User;
};

export type UserProductType = {
  id: number;
  manager: {
    first_name: string;
    last_name: string;
    is_active: boolean;
    office: number | null;
  };
  name: string;
  name_en: string | null;
  name_es: string | null;
  name_es_mx: string | null;
  is_active: boolean;
  managers: number[];
};

export enum PARTNER_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  VIEWER = 'VIEWER',
}
