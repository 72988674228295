import DatePicker from 'react-datepicker';
import classes from './DatePicker.module.scss';
import { DatePickerProps } from 'react-datepicker';
import { ForwardedRef, forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerStyles.scss';

//todo: доработать стили для режима выбора дня с флагом fullWidth

type StyledDatePickerProps = {
  fullWidth?: boolean;
} & DatePickerProps;

const CustomInput = forwardRef(
  ({ value, onClick, className }: any, ref: ForwardedRef<HTMLDivElement>) => (
    <div className={className} onClick={onClick} ref={ref}>
      {value}
    </div>
  )
);

CustomInput.displayName = 'CustomInput';

function StyledDatePicker({ fullWidth, ...props }: StyledDatePickerProps) {
  return (
    <DatePicker
      calendarClassName={fullWidth ? 'datepicker-full' : ''}
      customInput={<CustomInput className={classes.input} />}
      {...props}
    />
  );
}

export default StyledDatePicker;
