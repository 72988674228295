import i18n from 'i18n/i18n';

export const formatCasesForLocale = (
  amount: number | string,
  amount_unit: string,
  fractionDigits: number
) => {
  const lang = i18n.language;
  const isRussianLocale = lang === 'ru';
  const isRubleCurrency = amount_unit === 'RUB';

  // Determine the number of decimal places
  const hasFraction = Number(amount) % 1 !== 0;
  const minFractionDigits = hasFraction ? fractionDigits : 0;
  const maxFractionDigits = hasFraction ? fractionDigits : 0;

  let formattedValue = '0';

  // Format the number taking into account the locale and currency symbol
  if (amount_unit !== 'NONE' && amount_unit !== 'PERC') {
    // Use Intl.NumberFormat to get the currency symbol by char_code
    formattedValue = new Intl.NumberFormat(
      isRussianLocale ? 'ru-RU' : 'en-US',
      {
        style: 'currency',
        currency: amount_unit,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
        currencyDisplay: 'symbol', // Use the currency symbol
      }
    )?.format(Number(amount));

    // If the locale is ru or the currency is RUB, move the currency symbol after the number
    if (isRussianLocale || isRubleCurrency) {
      const currencySymbolMatch = formattedValue.match(/[^\d.,\s]+/); // Find the currency symbol
      if (currencySymbolMatch) {
        const currencySymbol = currencySymbolMatch[0];
        formattedValue = formattedValue.replace(currencySymbol, '').trim(); // Remove the currency symbol
        formattedValue = `${formattedValue}\u00A0${currencySymbol}`; // Add the symbol after the number with a non-breaking space
      }
    }
  } else {
    // If PERC or NONE, format the number as a regular value
    formattedValue = new Intl.NumberFormat(
      isRussianLocale ? 'ru-RU' : 'en-US',
      {
        style: 'decimal',
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      }
    ).format(Number(amount));

    // Add % sign for PERC
    if (amount_unit === 'PERC') {
      formattedValue += '%';
    }
  }

  // If the locale is not ru, replace commas with non-breaking spaces
  if (!isRussianLocale) {
    formattedValue = formattedValue.replace(/,/g, '\u00A0');
  }

  return formattedValue;
};
