import { chunk, reverse } from 'lodash';

const isValidInteger = (int: number): boolean =>
  !Number.isInteger(int) ||
  int > Number.MAX_SAFE_INTEGER ||
  int < Number.MIN_SAFE_INTEGER;

const magnitudeName = (t: (key: string) => string) => [
  '',
  t('thousand'),
  t('million'),
  t('billion'),
  t('trillion'),
  t('quadrillion'),
];

const addMagnitude = (group: string, i: number, t: (key: string) => string) => {
  const magnitude = magnitudeName(t)[i];
  return magnitude === '' ? group : group ? `${group} ${magnitude}` : '';
};

const zeroToNineteenNames = (t: (key: string) => string) => [
  '',
  t('one'),
  t('two'),
  t('three'),
  t('four'),
  t('five'),
  t('six'),
  t('seven'),
  t('eight'),
  t('nine'),
  t('ten'),
  t('eleven'),
  t('twelve'),
  t('thirteen'),
  t('fourteen'),
  t('fifteen'),
  t('sixteen'),
  t('seventeen'),
  t('eighteen'),
  t('nineteen'),
];

const tensNames = (t: (key: string) => string) => [
  '',
  '',
  t('twenty'),
  t('thirty'),
  t('forty'),
  t('fifty'),
  t('sixty'),
  t('seventy'),
  t('eighty'),
  t('ninety'),
];

const pipe =
  <T, R>(firstFn: (arg: T) => any, ...fns: any[]) =>
  (arg: any): R =>
    fns.reduce((argument, fn) => fn(argument), firstFn(arg));

const splitNumberIntoDigits = (n: number): number[] =>
  `${n}`.split('').map((v) => parseInt(v, 10));

const reverseAndChunkDigitsByThree = pipe<number, number[][]>(
  splitNumberIntoDigits,
  reverse,
  (a: unknown[]) => chunk(a, 3)
);

export function splitNumber(num: number, splitSize: number) {
  return num
    .toString()
    .replace(new RegExp(`\\B(?=(\\d{${splitSize}})+(?!\\d))`, 'g'), ' ');
}

export const formatNumber = (value: number | string | undefined) => {
  const number = value && typeof value === 'string' && Number(value);
  return number ? (number % 1 === 0 ? number.toFixed(0) : value) : 0;
};

const mapToWordGroup = (
  [ones = 0, tens = 0, huns = 0]: number[],
  i: number,
  c: number[][],
  t: (key: string) => string
): string => {
  const hundredsPlace =
    huns === 0 ? '' : `${zeroToNineteenNames(t)[huns]} hundred `;
  const tensPlace =
    ones === 0
      ? tensNames(t)[tens]
      : tensNames(t)[tens] && `${tensNames(t)[tens]} `;
  const onesPlace =
    zeroToNineteenNames(t)[parseInt(`${tens}${ones}`, 10)] ||
    zeroToNineteenNames(t)[ones];

  const addAnAnd =
    (hundredsPlace || c.length > 1) && (tensPlace || onesPlace) && i === 0
      ? 'and '
      : '';

  return [hundredsPlace, addAnAnd, tensPlace, onesPlace].join('').trim();
};

const toWords = (num: number, t: (key: string) => string) =>
  reverseAndChunkDigitsByThree(num)
    .map((digits) => mapToWordGroup(digits, 0, [], t))
    .map((group, i) => addMagnitude(group, i, t))
    .filter((str) => str.length)
    .reverse()
    .join(' ');

export const integerToWords = (
  number: number,
  t: (key: string) => string
): string => {
  if (isValidInteger(number)) {
    return '';
  }
  if (Object.is(number, -0)) return 'negative zero';
  if (number === 0) return 'zero';

  const words = toWords(Math.abs(number), t);

  return number < 0 ? `negative ${words}` : words;
};
