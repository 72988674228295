import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const WaitingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="url(#paint0_linear_2992_58611)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16C6 10.4777 10.4777 6 16 6C21.5223 6 26 10.4777 26 16C26 21.5223 21.5223 26 16 26C10.4777 26 6 21.5223 6 16ZM7.69643 16C7.69643 20.5848 11.4152 24.3036 16 24.3036C20.5848 24.3036 24.3036 20.5848 24.3036 16C24.3036 11.4152 20.5848 7.69643 16 7.69643C11.4152 7.69643 7.69643 11.4152 7.69643 16Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9 10.1V16.4438L20.305 18.39L19.5 20L15.5975 17.805C15.2926 17.6525 15.1 17.3409 15.1 17V10.1H16.9Z"
      fill="white"
    />
    <defs>
      {RUSSIAN_LOCALE ? (
        <linearGradient
          id="paint0_linear_2992_58611"
          x1="2.22027e-07"
          y1="2"
          x2="32"
          y2="27.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6B66C" stopOpacity="0.4" />
          <stop offset="1" stopColor="#F6B66C" />
        </linearGradient>
      ) : (
        <linearGradient
          id="paint0_linear_2992_58611"
          x1="2.22027e-07"
          y1="2"
          x2="32"
          y2="27.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F67D6C" stopOpacity="0.4" />
          <stop offset="1" stopColor="#F67D6C" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
