import React, { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const EmptyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122"
    height="64"
    viewBox="0 0 122 64"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.408 49.4079H75.2871C75.5467 49.4079 75.7998 49.3794 76.0434 49.3254C76.287 49.3794 76.5401 49.4079 76.7997 49.4079H103.016C104.965 49.4079 106.545 47.8033 106.545 45.8239C106.545 43.8445 104.965 42.2399 103.016 42.2399H99.9913C98.0422 42.2399 96.4622 40.6353 96.4622 38.6559C96.4622 36.6765 98.0422 35.0719 99.9913 35.0719H109.57C111.52 35.0719 113.1 33.4673 113.1 31.4879C113.1 29.5085 111.52 27.9039 109.57 27.9039H98.4788C100.428 27.9039 102.008 26.2993 102.008 24.3199C102.008 22.3405 100.428 20.7359 98.4788 20.7359H66.2121C68.1613 20.7359 69.7413 19.1313 69.7413 17.1519C69.7413 15.1725 68.1613 13.5679 66.2121 13.5679H37.4746C35.5255 13.5679 33.9455 15.1725 33.9455 17.1519C33.9455 19.1313 35.5255 20.7359 37.4746 20.7359H17.308C15.3589 20.7359 13.7788 22.3405 13.7788 24.3199C13.7788 26.2993 15.3589 27.9039 17.308 27.9039H29.9121C31.8612 27.9039 33.4413 29.5085 33.4413 31.4879C33.4413 33.4673 31.8612 35.0719 29.9121 35.0719H9.74548C7.79637 35.0719 6.21631 36.6765 6.21631 38.6559C6.21631 40.6353 7.79637 42.2399 9.74548 42.2399H29.408C27.4589 42.2399 25.8788 43.8445 25.8788 45.8239C25.8788 47.8033 27.4589 49.4079 29.408 49.4079ZM111.587 49.4079C113.536 49.4079 115.116 47.8033 115.116 45.8239C115.116 43.8445 113.536 42.2399 111.587 42.2399C109.638 42.2399 108.058 43.8445 108.058 45.8239C108.058 47.8033 109.638 49.4079 111.587 49.4079Z"
      fill={RUSSIAN_LOCALE ? '#FFF3F3' : '#F2ECFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.6762 38.301C54.6368 38.5843 54.6164 38.8737 54.6164 39.168C54.6164 42.5613 57.3251 45.312 60.6664 45.312C64.0078 45.312 66.7164 42.5613 66.7164 39.168C66.7164 38.8737 66.6961 38.5843 66.6567 38.301H81.3373V51.968C81.3373 52.8163 80.6601 53.504 79.8248 53.504H41.5081C40.6728 53.504 39.9956 52.8163 39.9956 51.968V38.301H54.6762Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.2206 38.144C67.2206 41.82 64.2862 44.8 60.6664 44.8C57.0467 44.8 54.1123 41.82 54.1123 38.144C54.1123 38.0249 54.1153 37.9065 54.1214 37.789H39.9956L44.8157 23.3159C45.0235 22.6919 45.5998 22.272 46.2484 22.272H75.0844C75.733 22.272 76.3094 22.6919 76.5172 23.3159L81.3373 37.789H67.2114C67.2175 37.9065 67.2206 38.0249 67.2206 38.144Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.2616 38.6326C66.2616 41.4732 63.7566 44.2879 60.6665 44.2879C57.5765 44.2879 55.0715 41.4732 55.0715 38.6326C55.0715 38.5406 55.0741 37.9371 55.0793 37.8463H44.5332L48.6479 27.6865C48.8253 27.2044 49.3173 26.8799 49.871 26.8799H71.4621C72.0158 26.8799 72.5078 27.2044 72.6852 27.6865L76.7999 37.8463H66.2537C66.2589 37.9371 66.2616 38.5406 66.2616 38.6326Z"
      fill={RUSSIAN_LOCALE ? '#FFF3F3' : '#F2ECFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.626 37.8944V51.4561C40.626 51.951 41.021 52.3521 41.5083 52.3521H79.8249C80.3122 52.3521 80.7072 51.951 80.7072 51.4561V37.8944L75.9204 23.521C75.7992 23.1571 75.463 22.9121 75.0846 22.9121H46.2486C45.8703 22.9121 45.534 23.1571 45.4128 23.521L40.626 37.8944Z"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
    />
    <path
      d="M47.0541 37.6321C49.0388 37.6321 51.1807 37.6321 53.4797 37.6321C54.4257 37.6321 54.4257 38.3072 54.4257 38.6561C54.4257 42.0493 57.1967 44.8001 60.6148 44.8001C64.033 44.8001 66.804 42.0493 66.804 38.6561C66.804 38.3072 66.804 37.6321 67.75 37.6321H80.3291M43.3101 37.6321H44.5333H43.3101Z"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.3211 10.651L67.725 17.027M60.2128 8.448V17.027V8.448ZM47.0542 10.651L52.6503 17.027L47.0542 10.651Z"
      stroke={RUSSIAN_LOCALE ? '#FFC8C8' : '#DDC8FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
