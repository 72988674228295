import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n/i18n';
import { RUSSIAN_LOCALE } from '../utils';
import { AxiosError, AxiosResponse } from 'axios';
import { UpdateUserRequest, UpdateUserResponse, User } from '@type/user';
import UserService from 'services/user';
import { initOnboarding } from 'tools/onboarding';

export class UserStore {
  user: User = {} as User;
  partners: any = null;
  singlePartner: any = null;
  isComplete = false;
  isDataLoading = false;
  isInnerDataLoading = false;
  isPermissionError = false;
  isLocaleError = false;
  localeChanged = false;
  currentLanguage: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: User) {
    this.user = user;
  }

  setLocaleChanged = (val: boolean) => {
    this.localeChanged = val;
  };

  loadData(isDataLoading?: boolean, isInnerDataLoading?: boolean) {
    if (isDataLoading !== undefined) {
      this.isDataLoading = isDataLoading;
    }
    if (isInnerDataLoading !== undefined) {
      this.isInnerDataLoading = isInnerDataLoading;
    }
  }

  public async loadUser(
    onError?: (error: AxiosError) => void
  ): Promise<User | undefined> {
    try {
      const response = await UserService.getUser();
      this.setUser(response?.data);
      const userLocale: string | null =
        response?.data?.language?.lang_code || null;
      const userOffice: string | null =
        response?.data?.user_office?.name || null;

      const isUserSentToYm = localStorage.getItem('ymUserSent');
      if (isUserSentToYm !== 'true' && response?.data) {
        if (typeof window.ym === 'function') {
          window.ym(98911466, 'setUserID', String(response?.data?.id));
          window.ym(98911466, 'userParams', {
            userID: String(response?.data?.id),
          });
          localStorage.setItem('ymUserSent', 'true');
        }
      }

      if (RUSSIAN_LOCALE) {
        this.currentLanguage = 'ru';
        if (userOffice !== null && userOffice !== 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'ru';
        }
        await this.changeLanguage(this.currentLanguage);
      } else {
        if (userOffice !== null && userOffice === 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        } else {
          this.currentLanguage = userLocale || 'en';
          await this.changeLanguage(this.currentLanguage);
        }
      }
      if (!RUSSIAN_LOCALE && i18n.language !== this.currentLanguage) {
        await this.changeLanguage(this.currentLanguage);
      }

      if (response?.data) {
        runInAction(() => {
          initOnboarding();
          this.isComplete = true;
        });
      }

      return response?.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 403) {
        const userLocale: string | null =
          error.response?.data?.language?.lang_code || null;
        runInAction(async () => {
          this.isPermissionError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        });

        onError?.(error as AxiosError);
      }
    }
  }

  public async updateUser(
    requestData: UpdateUserRequest,
    onSuccess?: (result: AxiosResponse<UpdateUserResponse>) => void,
    onError?: (error: AxiosError) => void
  ): Promise<void> {
    try {
      const result = await UserService.updateUser(requestData);

      runInAction(() => {
        this.setUser(result.data.user);
      });

      onSuccess?.(result);
    } catch (error) {
      onError?.(error as AxiosError);
    }
  }

  public async sendRegistrationCompleteEmail(
    onSuccess?: () => void,
    onError?: (error: AxiosError) => void
  ) {
    try {
      await UserService.sendRegistrationCompleteEmail();
      onSuccess?.();
    } catch (error) {
      onError?.(error as AxiosError);
    }
  }

  public async changeLanguage(language: string): Promise<boolean> {
    try {
      const response = await UserService.updateUser({ language });
      if (response.data.is_valid === true) {
        await i18n.changeLanguage(language);
        this.setUser(response.data.user);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async loadPartner() {
    try {
      const response = await UserService.getPartners();

      this.partners = response.data;
    } catch (error: any) {
      this.partners = null;

      return error;
    }
  }

  async loadPartnerById(partnerId: number) {
    try {
      return await UserService.getPartnerById(partnerId);
    } catch (error: any) {
      this.singlePartner = null;

      return error;
    }
  }

  async loadAllOnboardings() {
    try {
      const response = await UserService.getAllOnboardings();
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке списка онбордингов:', error);
      return null;
    }
  }

  async loadOnboardingSteps(id: string) {
    try {
      const response = await UserService.getOnboardingSteps(id);
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке шагов онбординга:', error);
      return null;
    }
  }

  async completeOnboarding(onboardingId: string) {
    try {
      const response = await UserService.completeOnboarding(onboardingId);
      return response?.data;
    } catch (error) {
      console.error('Ошибка при выполнении шагов онбординга:', error);
      return null;
    }
  }

  clearStore() {
    this.user = {} as User;
    this.isComplete = false;
  }
}

export const userStore = new UserStore();
