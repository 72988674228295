import * as React from 'react';
import { SVGProps } from 'react';

export const BulletListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="9" r="3" />
    <circle cx="7" cy="23" r="3" />
    <rect x="16" y="22" width="14" height="2" />
    <rect x="16" y="8" width="14" height="2" />
    <rect width="32" height="32" fill="none" />
  </svg>
);
