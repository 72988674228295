import React from 'react';

export const EditIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9375 4.55216L5.25607 13.2587L4.69691 15.3056L6.74715 14.7474L15.4285 6.04081C15.7141 5.75569 15.4166 5.41425 15.0105 5.00883C14.6044 4.6034 14.2622 4.228 13.9375 4.55216ZM16.0238 3.99719C17.3447 5.31594 17.3739 6.08838 16.4251 7.03571L7.61135 15.8744C7.52464 15.9609 7.4168 16.0234 7.2985 16.0556L3.87804 16.987C3.63408 17.0534 3.19167 17.1654 3.01287 16.9869C2.83406 16.8084 2.94628 16.3667 3.01282 16.1231L3.94567 12.7083C3.97793 12.5901 4.04052 12.4825 4.12723 12.3959L12.9409 3.55726C13.8898 2.60993 14.7022 2.67781 16.0238 3.99719Z"
        fill="#8362F3"
      />
    </g>
  </svg>
);
