import { useUserStore } from 'contexts/UserContext';
import { useCallback, useEffect } from 'react';
import { PersonalFormInputs } from './Personal/Personal.props';
import { useCommonStore } from 'contexts/CommonContext';
import { getLangName } from '../../tools/utils';
import { useTranslation } from 'react-i18next';

export const useAccountContainer = () => {
  const userStore = useUserStore();
  const commonStore = useCommonStore();
  const { i18n } = useTranslation();

  useEffect(() => {
    commonStore.loadCompanyProducts();
  }, []);

  const getParams = useCallback(async () => {
    userStore.loadUser();
  }, []);

  const formatDictionaryTypes = (externalData: any) =>
    externalData?.map((item: any) => ({
      value: item?.type || item?.id,
      label: getLangName(item, 'name', i18n),
    })) || [];

  const callbacks = {
    onSubmitPersonal: (
      requestData: PersonalFormInputs,
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      const data = {
        full_name: requestData.fullName,
        phone_number: `+${requestData.phone}`,
        user_role_in_company: requestData.jobTitle,
      };

      userStore.updateUser(
        data,
        () => onSuccess?.(),
        () => {
          onError?.();
        }
      );
    },
    onSubmitBusiness: (
      selectedProducts: number[],
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      const data = {
        user_products: selectedProducts,
      };

      userStore.updateUser(
        data,
        () => onSuccess?.(),
        () => {
          onError?.();
        }
      );
    },
  };

  return { callbacks, getParams, formatDictionaryTypes };
};
