import React, { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const EmptyDocsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122"
    height="64"
    viewBox="0 0 122 64"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.7733 15.0527C99.6445 15.0527 101.161 16.5932 101.161 18.4934C101.161 20.3936 99.6445 21.934 97.7733 21.934H78.4133C80.2845 21.934 81.8013 23.4744 81.8013 25.3747C81.8013 27.2749 80.2845 28.8153 78.4133 28.8153H89.0613C90.9325 28.8153 92.4493 30.3557 92.4493 32.2559C92.4493 34.1561 90.9325 35.6966 89.0613 35.6966H84.1371C81.7779 35.6966 79.8653 37.237 79.8653 39.1372C79.8653 40.404 80.8333 41.5509 82.7693 42.5779C84.6405 42.5779 86.1573 44.1183 86.1573 46.0185C86.1573 47.9187 84.6405 49.4591 82.7693 49.4591H42.5973C40.7262 49.4591 39.2093 47.9187 39.2093 46.0185C39.2093 44.1183 40.7262 42.5779 42.5973 42.5779H23.7213C21.8502 42.5779 20.3333 41.0374 20.3333 39.1372C20.3333 37.237 21.8502 35.6966 23.7213 35.6966H43.0813C44.9525 35.6966 46.4693 34.1561 46.4693 32.2559C46.4693 30.3557 44.9525 28.8153 43.0813 28.8153H30.9813C29.1102 28.8153 27.5933 27.2749 27.5933 25.3747C27.5933 23.4744 29.1102 21.934 30.9813 21.934H50.3413C48.4702 21.934 46.9533 20.3936 46.9533 18.4934C46.9533 16.5932 48.4702 15.0527 50.3413 15.0527H97.7733ZM97.7733 28.8153C99.6445 28.8153 101.161 30.3557 101.161 32.2559C101.161 34.1561 99.6445 35.6966 97.7733 35.6966C95.9022 35.6966 94.3853 34.1561 94.3853 32.2559C94.3853 30.3557 95.9022 28.8153 97.7733 28.8153Z"
      fill={RUSSIAN_LOCALE ? '#FFEAEA' : '#F2ECFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.0223 14.5615L76.4729 47.8598L76.8721 51.2006C77.0007 52.2768 76.243 53.258 75.1798 53.3921L46.7827 56.9745C45.7194 57.1087 44.7532 56.345 44.6246 55.2687L40.3176 19.2283C40.2533 18.6902 40.6321 18.1996 41.1638 18.1325C41.1671 18.1321 41.1705 18.1317 41.1738 18.1313L43.5297 17.8594M45.4244 17.5951L47.6454 17.3395L45.4244 17.5951Z"
      fill="white"
    />
    <path
      d="M72.4978 14.4961C72.4627 14.2334 72.2213 14.0496 71.9587 14.0857C71.6961 14.1218 71.5116 14.3641 71.5467 14.6268L72.4978 14.4961ZM76.4729 47.8598L76.9493 47.8011L76.9484 47.7945L76.4729 47.8598ZM76.8721 51.2006L77.3485 51.1418L76.8721 51.2006ZM75.1798 53.3921L75.2381 53.8686L75.1798 53.3921ZM46.7827 56.9745L46.841 57.451L46.7827 56.9745ZM44.6246 55.2687L45.101 55.21L44.6246 55.2687ZM40.3176 19.2283L40.794 19.1696L40.3176 19.2283ZM41.1738 18.1313L41.2271 18.6084L41.1738 18.1313ZM43.583 18.3365C43.8463 18.3061 44.036 18.0678 44.0065 17.8044C43.9771 17.5409 43.7398 17.352 43.4765 17.3824L43.583 18.3365ZM45.3713 17.1181C45.108 17.1484 44.9183 17.3866 44.9476 17.65C44.977 17.9135 45.2142 18.1025 45.4776 18.0722L45.3713 17.1181ZM47.6985 17.8165C47.9619 17.7862 48.1516 17.548 48.1222 17.2846C48.0929 17.0211 47.8556 16.8321 47.5923 16.8624L47.6985 17.8165ZM71.5467 14.6268L75.9973 47.9252L76.9484 47.7945L72.4978 14.4961L71.5467 14.6268ZM75.9965 47.9186L76.3957 51.2593L77.3485 51.1418L76.9493 47.8011L75.9965 47.9186ZM76.3957 51.2593C76.4935 52.0779 75.9172 52.8153 75.1215 52.9157L75.2381 53.8686C76.5689 53.7007 77.5079 52.4757 77.3485 51.1418L76.3957 51.2593ZM75.1215 52.9157L46.7244 56.4981L46.841 57.451L75.2381 53.8686L75.1215 52.9157ZM46.7244 56.4981C45.9298 56.5984 45.1987 56.0276 45.101 55.21L44.1482 55.3275C44.3077 56.6623 45.5091 57.619 46.841 57.451L46.7244 56.4981ZM45.101 55.21L40.794 19.1696L39.8412 19.287L44.1482 55.3275L45.101 55.21ZM40.794 19.1696C40.7605 18.8891 40.958 18.6423 41.2221 18.609L41.1055 17.6561C40.3063 17.7569 39.7461 18.4913 39.8412 19.287L40.794 19.1696ZM41.2221 18.609C41.2237 18.6088 41.2254 18.6086 41.2271 18.6084L41.1206 17.6543C41.1155 17.6548 41.1105 17.6554 41.1055 17.6561L41.2221 18.609ZM41.2271 18.6084L43.583 18.3365L43.4765 17.3824L41.1206 17.6543L41.2271 18.6084ZM45.4776 18.0722L47.6985 17.8165L47.5923 16.8624L45.3713 17.1181L45.4776 18.0722Z"
      fill={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.737 16.66L74.8121 46.8805L75.1787 49.9123C75.2967 50.889 74.622 51.7768 73.6717 51.8954L48.2897 55.0603C47.3394 55.1788 46.4732 54.4831 46.3551 53.5064L42.3847 20.6677C42.3338 20.2466 42.6337 19.8627 43.0546 19.8102L46.3933 19.3939"
      fill={RUSSIAN_LOCALE ? '#FFEAEA' : '#F7F5FF'}
    />
    <path
      d="M49.6945 9.21569C49.6945 8.63247 50.1673 8.15969 50.7505 8.15969H73.3586C73.6414 8.15969 73.9124 8.2731 74.1108 8.47453L80.8587 15.323C81.0534 15.5206 81.1625 15.7868 81.1625 16.0642V47.4314C81.1625 48.0146 80.6897 48.4874 80.1065 48.4874H50.7505C50.1673 48.4874 49.6945 48.0146 49.6945 47.4314V9.21569Z"
      fill="white"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
      strokeWidth="0.96"
    />
    <path
      d="M73.4145 8.86133V14.5616C73.4145 15.3759 74.0646 16.0361 74.8665 16.0361H78.7064"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
      strokeWidth="0.96"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.6973 41.1037H67.2813M54.6973 16.0361H67.2813H54.6973ZM54.6973 21.9344H75.5093H54.6973ZM54.6973 28.3241H75.5093H54.6973ZM54.6973 34.7139H75.5093H54.6973Z"
      stroke={RUSSIAN_LOCALE ? '#FFC8C8' : '#DDC8FF'}
      strokeWidth="0.96"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
