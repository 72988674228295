import classes from './OurTeam.module.scss';

interface OurTeamProps {
  data: '';
  title: string;
}

export const OurTeam = ({ data, title }: OurTeamProps) => (
  <div className={classes.wrapper}>
    <div className={classes.title}>{title}</div>
    <div dangerouslySetInnerHTML={{ __html: data }} />
  </div>
);
