import AnalyticsCard from './AnalyticsCard';
import classes from './Dashboard.module.scss';
import { percentNumber, toSentenceCase } from '../../PartnerContainer/utils';
import { TFunction } from 'i18next';

export type DashboardProps = {
  data: {
    name: string;
    value: number;
    diff: string;
    description?: string;
  }[];
  filterText: string;
  isMobile?: boolean;
  t: TFunction;
};

function Dashboard({ data, filterText, isMobile, t }: DashboardProps) {
  return (
    <div className={classes['card-grid']}>
      {data?.map((dataItem, index) => (
        <AnalyticsCard
          key={`${index}-${dataItem.name}`}
          title={t(toSentenceCase(dataItem.name))}
          count={dataItem.value}
          percentage={dataItem.diff}
          description={dataItem?.description}
          filterText={filterText}
          arrowDown={percentNumber({ decrease: dataItem.diff }) as boolean}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
}

export default Dashboard;
