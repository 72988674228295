import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { NOTICE, PERIOD } from 'constants/enum';
import { LABELS } from 'constants/onboarding';
import { FormBlock } from 'containers/AddSolution/FormBlock';
import MobileModal from 'containers/PartnerContainer/Mobile';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { completeOnboarding, findOnboardingSteps, formatSteps,  OnboardingSteps } from 'tools/onboarding';
import { usePartnerStore } from '../../contexts/PartnerContext';
import {
  endDayFormatted,
  formatPeriodDate,
  queryParamsHandler,
  startDayFormatted,
} from '../PartnerContainer/utils';
import Dashboard from './Dashboard';
import classes from './SolutionDetail.module.scss';
import SolutionHeader from './SolutionHeader';
import SolutionTabs from './SolutionTabs';
import { SolutionTabType } from './types';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Preview } from 'components/Preview';
import { NoticeBox } from 'components/shared/NoticeBox';
import { useFormBlock } from '../AddSolution/FormBlock/useFormBlock';
import { FormProvider } from 'react-hook-form';

const SolutionDetailContainer = observer(() => {
  const { t } = useTranslation();
  const periodOptions = useMemo(
    () => [
      { id: 1, name: 'Day' },
      { id: 2, name: 'Week' },
      { id: 3, name: '14 days' },
      { id: 4, name: 'Month' },
    ],
    [t]
  );
  const {
    fetchSingleServiceAnalytics,
    singleService,
    analytics,
    setCopyOfService,
    copiedService,
  } = (usePartnerStore() as any) || {};
  const userStore = useUserStore();
  const navigate = useNavigate();
  const tabs: SolutionTabType[] = [
    {
      id: 'dashboard',
      label: t('Dashboard'),
    },
    {
      id: 'main',
      label: t('Main info'),
    },
    {
      id: 'cases',
      label: t('Cases'),
    },
    {
      id: 'price',
      label: t('Price'),
    },
    {
      id: 'preview',
      label: t('Preview'),
    },
  ];
  const [solution, setSolution] = useState<any>();
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();
  const [activeTab, setActiveTab] =
    useState<SolutionTabType['id']>('dashboard');
  const [periodFilter, setPeriodFilter] = useState<{
    id: number;
    name: string;
  }>(periodOptions[1]);
  const [openPeriods, setOpenPeriods] = useState(false);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const { methods } = useFormBlock(copiedService);


  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'SOLUTION_DETAIL_P';
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps(onboardingPath).then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  const getDatePeriod = () => {
    switch (periodFilter.id) {
      case 1:
        return formatPeriodDate(PERIOD.ONE_DAY);
      case 2:
        return formatPeriodDate(PERIOD.WEEK);
      case 3:
        return formatPeriodDate(PERIOD.TWO_WEEKS);
      case 4:
        return formatPeriodDate(PERIOD.MONTH);
    }
  };

  const getPeriodName = () => {
    switch (periodFilter.id) {
      case 1:
        return t('Compared to last day');
      case 2:
        return t('Compared to last week');
      case 3:
        return t('Compared to last two weeks');
      case 4:
        return t('Compared to last month');
      default:
        return t('Compared to last week');
    }
  };

  const params = useMemo(
    () =>
      queryParamsHandler({
        singleService: true,
        dateFrom: getDatePeriod() || startDayFormatted,
        dateTo: endDayFormatted,
      }),
    [periodFilter]
  );

  useEffect(() => {
    if (singleService?.slug) {
      fetchSingleServiceAnalytics(params, singleService.slug);
    }
  }, [params, singleService]);

  useEffect(() => {
    setSolution({
      ...singleService,
      data: analytics,
    });
  }, [analytics, singleService]);

  useEffect(() => {
    if (singleService?.slug && analytics?.length) {
      setDataIsLoaded(true);
    }
  }, [singleService, analytics]);

  const onCopy = (project: any) => {
    setCopyOfService(singleService);
    navigate('/solutions/add-solution/');
  };

  const stickerColor = () => {
    switch (solution?.status) {
      case 'active':
        return 'green';
      case 'in review':
        return 'yellow';
      case 'archived':
        return 'purple';
      case 'rejected':
        return 'red';
      default:
        return 'purple';
    }
  };

  const comparePeriod = getPeriodName();

  const alertBlock = (
    <NoticeBox
      className={classes.noticebox}
      type={NOTICE.DEFAULT}
      title={t('To update the solution info, get in touch with your manager')}
      description={t(
        "Updating your service info through the app isn't an option right now, but that's because we're making sure everything stays super accurate and secure for you"
      )}
    />
  );

  return (
    <div className={classes.wrapper}>
      <TitleAndDescription
        pageName="SolutionDetail"
        title={solution?.name}
        titleOnDescription
      />
 {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
            scrollToElement: false,
          }}
          onChange={(nextStep: number) => {
            console.log('nextStep ======> ,', nextStep)
            if (nextStep === 0) {
              setActiveTab('dashboard');
            } else if (nextStep === 1) {
              setActiveTab('main');
            }
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}
      <ul className={classes.breadcrumbs}>
        <li className={classes.breadcrumbs__item}>
          <NavLink to={'/solutions/'} className={classes.breadcrumbs__link}>
            {t('Solutions')}
          </NavLink>
        </li>
        <li className={classes.breadcrumbs__item}>
          <span className={classes.breadcrumbs__link}>{solution?.name}</span>
        </li>
      </ul>

      <SolutionHeader
        title={solution?.name}
        statusText={t(
          solution?.status?.charAt(0)?.toUpperCase() +
            solution?.status?.slice(1)
        )}
        solutionId={solution?.id}
        solutionType={solution?.product}
        stickerColor={stickerColor()}
        isMobile={isMediaTablet}
        t={t}
      />

      <div className={classes['content-wrapper']} data-step-solution_detail_p-0> 
        <div
          className={clsx(
            classes['hints-block'],
            activeTab !== 'dashboard' && classes['hints-block__shrink']
          )}
          data-step-solution_detail_p-1
        >
          <div className={classes.filters}>
            <SolutionTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={(tabId) => setActiveTab(tabId)}
            />

            {activeTab === 'dashboard' && (
              <div className={classes.filters__period}>
                <span>{t('Data per')}</span>
                {!isMediaTablet ? (
                  <MultiselectComponent
                    textTheme
                    data={periodOptions.map((option) => ({
                      id: option.id,
                      name: t(option.name),
                    }))}
                    defaultSelected={2}
                    setMultiselect={setPeriodFilter}
                    className={classes.filters__select}
                  />
                ) : (
                  <Button theme="text" onClick={() => setOpenPeriods(true)}>
                    {t(periodFilter.name)}
                    <ArrowDownIcon
                      className={clsx(
                        classes.arrow,
                        openPeriods && classes['arrow--active']
                      )}
                    />
                  </Button>
                )}
              </div>
            )}
          </div>

          {activeTab === 'dashboard' && (
            <>
              <Dashboard
                data={solution?.data}
                filterText={comparePeriod}
                isMobile={isMediaTablet}
                t={t}
              />
              {openPeriods && (
                <div className={classes.modal}>
                  <MobileModal
                    title={t('Select period for data')}
                    data={periodOptions}
                    open={openPeriods}
                    setOpen={setOpenPeriods}
                    select={periodFilter}
                    setSelect={setPeriodFilter}
                  />
                </div>
              )}
            </>
          )}

          {activeTab === 'main' && (
            <>
              {alertBlock}
              <FormProvider {...methods}>
                <FormBlock
                  copiedService={solution}
                  currentStep={1}
                  isDisabled
                />
              </FormProvider>
            </>
          )}
          {activeTab === 'cases' && (
            <>
              {alertBlock}
              <FormProvider {...methods}>
                <FormBlock
                  copiedService={solution}
                  currentStep={2}
                  isDisabled
                  isShowHits={false}
                />
              </FormProvider>
            </>
          )}
          {activeTab === 'price' && (
            <>
              {alertBlock}
              <FormProvider {...methods}>
                <FormBlock
                  copiedService={solution}
                  currentStep={3}
                  isDisabled
                />
              </FormProvider>
            </>
          )}
          {activeTab === 'preview' && (
            <>
              {alertBlock}
              <Preview data={solution} isDisabled />
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default SolutionDetailContainer;
