import clsx from 'clsx';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { Input } from 'components/shared/Input';
import TooltipFilter from 'components/shared/TooltipFilter';
import { useTranslation } from 'react-i18next';
import classes from './SearchFilter.module.scss';
import { SearchFilterProps } from './SearchFilter.props';

function SearchFilter(props: SearchFilterProps) {
  const { t } = useTranslation();

  return (
    <TooltipFilter
      open={props.isOpen}
      hideSort={!props.containsMode}
      onClose={props.onClickOutside}
      onSortAscending={props.onSortAsc}
      onSortDescending={props.onSortDesc}
      additionalClass={!props.containsMode ? classes.tooltip_right : undefined}
      input={
        <div className={classes.filter}>
          {props.containsMode ? (
            <div className={classes.filter__title}>
              <span>{t('Contains')}</span>
              <ArrowDownIcon />
            </div>
          ) : (
            <div
              className={clsx(
                classes.filter__title,
                classes['filter__title--search']
              )}
            >
              <span>{t('Search the entire table')}</span>
            </div>
          )}
          <Input
            value={props.value}
            className={classes.filter__input}
            placeholder={`${t('Type a value')}...`}
            onChange={props.onInputChange}
          />
        </div>
      }
      t={t}
    >
      {props.children}
    </TooltipFilter>
  );
}

export default SearchFilter;
