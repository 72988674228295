import React from 'react';

export const PaperClipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.6271 9.0829L10.1141 16.5959C8.40553 18.3045 5.63543 18.3045 3.92688 16.5959C2.21834 14.8874 2.21834 12.1173 3.92688 10.4087L11.4399 2.89571C12.5789 1.75669 14.4257 1.75669 15.5647 2.89571C16.7037 4.03474 16.7037 5.88147 15.5647 7.0205L8.3463 14.2389C7.77679 14.8084 6.85342 14.8084 6.28391 14.2389C5.71439 13.6694 5.71439 12.746 6.28391 12.1765L12.6184 5.84199"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
