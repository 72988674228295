import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import classes from './CheckboxSelectItem.module.scss';
import clsx from 'clsx';

type CheckboxSeletItemProps = {
  label: React.ReactNode;
  checked?: boolean;
  hovered?: boolean;
  order?: number;
  withOrder?: boolean;
  onClick?: () => void;
  indeterminate?: boolean;
};

function CheckboxSelectItem({
  label,
  checked,
  hovered,
  order,
  withOrder = false,
  onClick,
  indeterminate,
}: CheckboxSeletItemProps) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        classes['item-wrapper'],
        hovered && classes['item-wrapper_hovered']
      )}
    >
      {order && order > 0 && checked ? (
        <span
          className={clsx(
            classes.orderBlock,
            (checked || indeterminate) && classes.orderBlock__checked
          )}
        >
          {order}
        </span>
      ) : (
        <Checkbox
          onChange={(_) => !withOrder && onClick?.()}
          style={{ borderRadius: 4 }}
          checked={checked}
          indeterminate={indeterminate}
          className={clsx(
            classes.checkbox,
            withOrder && classes.checkbox__order
          )}
        />
      )}
      <div className={classes['item-content']}>{label}</div>
    </div>
  );
}

export default CheckboxSelectItem;
