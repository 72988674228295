import { AxiosError } from 'axios';
import { IStatus } from '@type/api';

export default class StatusFactory {
  public static default<TData = null>(): IStatus<TData | null> {
    return { data: null, error: null, isLoading: false };
  }

  public static loading<TData = null>(): IStatus<TData | null> {
    return { data: null, error: null, isLoading: true };
  }

  public static success<TData>(data: TData): IStatus<TData> {
    return { data, error: null, isLoading: false };
  }

  public static failed<TData = null>(error: AxiosError): IStatus<TData | null> {
    return { data: null, error, isLoading: false };
  }
}
