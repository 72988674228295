import { PARTNER_ROLE } from 'types/user';
import { OptionTypeString } from 'types/util-types';

export const employeesRoles: OptionTypeString[] = [
  {
    id: PARTNER_ROLE.ADMIN,
    name: 'ADMIN',
    description:
      'All platform sections are available, including employee invitations and access level management',
  },
  {
    id: PARTNER_ROLE.MANAGER,
    name: 'MANAGER',
    description:
      'Access to viewing and ordering Solutions, managing Projects, and viewing Dashboards is available. No access to managing Finances or inviting employees',
  },
  {
    id: PARTNER_ROLE.VIEWER,
    name: 'VIEWER',
    description:
      'Only viewing of Solutions, Projects, and Dashboards is available',
  },
];
