import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { FinancesIcon } from 'components/shared/Icons/FinancesIcon';
import { integerToWords } from 'tools/numberToWords';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './TotalCard.module.scss';
import { TotalCardProps } from './TotalCard.props';
import { useTranslation } from 'react-i18next';

function TotalCard({
  totalDue,
  invoicesCount,
  onPaymentClick,
}: TotalCardProps) {
  const { t } = useTranslation();
  const invoicesWord = integerToWords(invoicesCount, t);
  const invoicesMessage =
    invoicesCount === 0
      ? t('No invoices awaiting payment')
      : invoicesCount === 1
        ? t('{{ word }} invoice is waiting payment', {
            word: invoicesWord[0].toUpperCase() + invoicesWord.substring(1),
          })
        : t('{{ word }} invoices are waiting payment', {
            word: invoicesWord[0].toUpperCase() + invoicesWord.substring(1),
          });

  return (
    <div className={classes.card}>
      <div className={classes.card__content}>
        <span className={classes.card__content_title}>{t('Total due')}</span>
        <span className={classes.card__content_due}>
          {/* временно валюта захардкожена, потом брать ее из апи */}
          <FormatPrice price={totalDue} currency={RUSSIAN_LOCALE ? '₽' : '$'} />
        </span>
        <span>{invoicesMessage}</span>
      </div>

      <div
        className={clsx(
          classes.card__actions,
          invoicesCount === 0 && classes.card__actions_zero
        )}
      >
        <Button
          theme="primary"
          disabled={invoicesCount === 0}
          onClick={onPaymentClick}
        >
          <FinancesIcon />
          <span>{t('Pay now')}</span>
        </Button>
      </div>
    </div>
  );
}

export default TotalCard;
