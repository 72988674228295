import MobileFilterModal from 'components/common/MobileFilterModal';
import { Modal } from 'components/shared/Modal';
import PaymentCard from './PaymentCard';
import classes from './PaymentModal.module.scss';
import { PaymentModalProps } from './PaymentModal.props';

function PaymentModal({
  isMobile,
  open,
  title,
  onClose,
  infoTitle,
  infoDesc,
  infoDownloadText,
}: PaymentModalProps) {
  return isMobile && open ? (
    <MobileFilterModal title={title} onClose={onClose}>
      <PaymentCard
        infoTitle={infoTitle}
        infoDesc={infoDesc}
        infoDownloadText={infoDownloadText}
      />
    </MobileFilterModal>
  ) : (
    <Modal title="" isOpen={open} onClose={onClose}>
      <div className={classes.modal}>
        <span className={classes.modal__title}>{title}</span>
        <PaymentCard
          infoTitle={infoTitle}
          infoDesc={infoDesc}
          infoDownloadText={infoDownloadText}
        />
      </div>
    </Modal>
  );
}

export default PaymentModal;
