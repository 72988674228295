import React, { useState, useEffect } from 'react';
import classes from './Switch.module.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { LockGradientIcon } from 'components/shared/Icons/LockGradientIcon';
import { LogoutGradientIcon } from 'components/shared/Icons/LogoutGradientIcon';
import { BusinessGradientIcon } from 'components/shared/Icons/BusinessGradientIcon';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { routers } from 'constants/routers';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { SwitchProps } from './Switch.props';
import SwitchNavItem from './SwitchNavItem/SwitchNavItem';
import { observer } from 'mobx-react-lite';
import { useHeader } from 'components/common/Header/useHeader';
import { ModalMobile } from 'components/shared/ModalMobile';
import { Button } from 'components/shared/Button';
import { EmployeesIcon } from 'components/shared/Icons/EmployeesIcon';
import { PARTNER_ROLE } from 'types/user';
import { useUserStore } from 'contexts/UserContext';
import { ManagerType } from 'types/partner';
import { CardManager } from 'components/Preview/CardManager';
import { ProfileGradientIcon } from 'components/shared/Icons/ProfileGradientIcon';
import { AdminPanelGradientIconActive } from 'components/shared/Icons/AdminPanelGradientIconActive';

export const Switch = observer(
  ({ className: auditionalClassname, section, currentStep }: SwitchProps) => {
    const { t } = useTranslation();
    const { resetCookies } = useHeader();
    const userStore = useUserStore();

    const userManager = userStore.user.manager as ManagerType | null;

    const [showExitModal, setShowExitModal] = useState(false);

    const handleShowExitModal = () => {
      setShowExitModal(true);
    };

    const handleCloseExitModal = () => {
      setShowExitModal(false);
    };

    const { isMediaTablet, isSmallLaptop } = useWindowWidth();
    const { origin, pathname } = window.location;
    const [open, setOpen] = useState(false);

    const isLinkAccount =
      pathname === `${routers.personal}` ||
      pathname === `${routers.business}` ||
      pathname === `${routers.changePassword}` ||
      pathname === `${routers.employees}`;

    useEffect(() => {
      if (isMediaTablet) {
        if (
          pathname !== `${routers.personal}` &&
          pathname !== `${routers.business}` &&
          pathname !== `${routers.changePassword}` &&
          pathname !== `${routers.employees}`
        ) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }
    }, [pathname]);

    const isAccessEmployeesTable = false;
    // partner &&
    // (partner.permission?.type === PARTNER_ROLE.ADMIN ||
    //   partner.permission?.type === PARTNER_ROLE.MANAGER) &&
    // partner.is_active;

    const isAdmin = userStore.user.is_superuser;
    const isManager = userStore.user.is_staff;
    const isConfirmedEmail = userStore?.user?.email_confirmed;

    return (
      <div className={classes.container}>
        <div
          className={clsx(
            classes.wrapper,
            auditionalClassname && classes[auditionalClassname]
          )}
        >
          {isMediaTablet && (
            <div className={classes.mobileTitle}>{t('Account')}</div>
          )}

          <div
            className={clsx(classes.list, isLinkAccount && classes.isHidden)}
          >
            <SwitchNavItem
              to={section && !isSmallLaptop ? '/account' : '/account/personal'}
              isActive={currentStep === 1 || section === 'personal'}
              icon={<ProfileGradientIcon />}
              label={t('Personal information')}
              isHidden={isLinkAccount}
            />

            <SwitchNavItem
              withoutShape
              to={section && !isSmallLaptop ? '/account' : '/account/business'}
              isActive={currentStep === 2 || section === 'business'}
              icon={<BusinessGradientIcon />}
              label={t('Account information')}
              isHidden={isLinkAccount}
            />

            {isAccessEmployeesTable && (
              <SwitchNavItem
                withoutShape
                to={
                  section && !isSmallLaptop ? '/account' : '/account/employees'
                }
                isActive={currentStep === 5 || section === 'employees'}
                icon={<EmployeesIcon />}
                label={t('Employees')}
                isHidden={isLinkAccount}
              />
            )}

            {!RUSSIAN_LOCALE && (
              <SwitchNavItem
                withoutShape
                to={
                  section && !isSmallLaptop
                    ? '/account'
                    : '/account/change-password'
                }
                isActive={currentStep === 3 || section === 'change-password'}
                icon={<LockGradientIcon />}
                label={t('Password')}
                isHidden={isLinkAccount}
              />
            )}
          </div>

          {!isSmallLaptop && isLinkAccount && (
            <NavLink to={'/account'} className={classes['link-back']}>
              <LeftArrowIcon className={classes['link-back__arrow']} />
              <span className={classes.backTo}>{t('Back to Account')}</span>
            </NavLink>
          )}
        </div>

        {(isAdmin || isManager) && isConfirmedEmail && (
          <NavLink
            className={clsx(
              classes.wrapperLogout,
              isLinkAccount && classes.isHidden
            )}
            to={`${process.env.REACT_APP_API}/globaladmin/`}
          >
            <div className={classes.logout}>
              <div className={clsx(classes.icon, classes.iconShape)}>
                <AdminPanelGradientIconActive />
              </div>
              <div className={classes.name}> {t('Admin panel')}</div>
            </div>
          </NavLink>
        )}

        <div
          className={clsx(
            classes.wrapperLogout,
            isLinkAccount && classes.isHidden
          )}
          onClick={RUSSIAN_LOCALE ? handleShowExitModal : resetCookies}
        >
          <div className={classes.logout}>
            <div className={clsx(classes.icon, classes.iconShape)}>
              <LogoutGradientIcon />
            </div>
            <div className={classes.name}> {t('Log out')}</div>
          </div>
        </div>

        {userManager && (
          <div
            className={clsx(
              classes.wrapperLogout,
              isLinkAccount && classes.isHidden
            )}
            data-step-profile_p-4
          >
            <CardManager
              forAccountPage
              name={userManager?.first_name}
              src={userManager?.image}
              alt="Manager"
              serviceId={''}
              fullName={userManager.name}
            />
          </div>
        )}

        <ModalMobile
          title=""
          isOpen={showExitModal}
          onClose={handleCloseExitModal}
          isBasicHeader={false}
        >
          <div className={classes.inner}>
            <div className={classes.header}>
              <h3 className={classes.title}>
                {t('Already leaving the platform?')}
              </h3>
              <p className={classes['title-description']}>
                {t(
                  'Please note that while you’re logging out of the Kokoc Group platform, you will remain active in Kokoc ID, which allows you to access other services within the Group'
                )}
              </p>
            </div>

            <div className={classes.buttons}>
              <Button
                theme={'primary-light'}
                size="big"
                className={classes.btn}
                onClick={handleCloseExitModal}
              >
                {t('Cancel')}
              </Button>

              <Button
                theme={'primary'}
                size="big"
                className={classes.btn}
                onClick={resetCookies}
              >
                {t('Log out')}
              </Button>
            </div>
          </div>
        </ModalMobile>
      </div>
    );
  }
);
