import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { EyeIcon } from 'components/shared/Icons/EyeIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { KrestIcon } from 'components/shared/Icons/KrestIcon';
import { Input } from 'components/shared/Input';
import { Link } from 'components/shared/Link';
import { PhoneInputMask } from 'components/shared/PhoneInput';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { defaultLanguage } from 'i18n/i18n';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import classes from './StepOne.module.scss';
import { StepOneProps } from './StepOne.ptops';
import { useStepOne } from './useStepOne';

export const StepOne = ({
  password,
  setPassword,
  setStep,
  control,
  errors,
  isValid,
}: StepOneProps) => {
  const { t, invSymbCheck, passwordRules, i18n } = useStepOne(password);
  const [showChecks, setShowCheks] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className={classes['top-fields']}>
        <div className={classes.fullName}>
          <div className={classes['item-label']}>{t('Full name')}</div>
          <Controller
            render={({ field }) => (
              <Input
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Enter your full name')}
                value={field.value}
                error={!!errors?.fullName}
                maxLength={50}
                className={classes.register_theme}
                onChange={field.onChange}
              />
            )}
            name="fullName"
            control={control}
          />
          {errors?.fullName && (
            <TooltipContainer
              text={errors.fullName?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={classes.email}>
          <div className={classes['item-label']}>{t('Work email')}</div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Enter your work email')}
                className={clsx(classes.email, classes.register_theme)}
                value={field.value}
                error={!!errors?.email}
                onChange={field.onChange}
              />
            )}
          />
          {errors?.email && (
            <TooltipContainer
              text={errors.email?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={classes.phone}>
          <div className={classes['item-label']}>{t('Mobile number')}</div>
          <Controller
            render={({ field }) => (
              <PhoneInputMask
                labelPosition={'left'}
                type={'number'}
                placeholder={t('Enter your mobile')}
                className={classes.register_theme}
                value={field?.value}
                onChange={field.onChange}
                error={!!errors?.phone}
              />
            )}
            name="phone"
            control={control}
          />
          {errors?.phone && (
            <TooltipContainer
              text={errors.phone?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
      </div>

      <div className={classes['bottom-fields']}>
        <div className={classes['input-field']}>
          {showChecks && (
            <div
              className={clsx(
                classes['password-check'],
                !invSymbCheck && classes['slight-up']
              )}
            >
              <div className={classes.title}>{t('Password requirements')}</div>

              <div className={classes.checks}>
                {passwordRules.map(({ label, check }, index) => (
                  <div className={classes.check} key={index}>
                    <div className={classes.icon}>
                      {password.length === 0 && <CheckIcon fill="#A09CAC" />}
                      {check && password.length >= 1 ? (
                        <CheckIcon fill="#00C853" />
                      ) : (
                        password.length >= 1 && <KrestIcon />
                      )}
                    </div>
                    <div
                      className={clsx(
                        classes.name,
                        check && password.length >= 1
                          ? classes.correct
                          : password.length >= 1 && classes.wrong
                      )}
                    >
                      {label}
                    </div>
                  </div>
                ))}

                {!invSymbCheck && (
                  <div className={classes.check}>
                    <div className={classes.icon}>
                      <KrestIcon />
                    </div>
                    <div
                      className={clsx(
                        classes.name,
                        !(invSymbCheck && password.length > 1) &&
                          password.length > 0 &&
                          classes.wrong
                      )}
                    >
                      {t('Invalid symbol')}
                    </div>
                  </div>
                )}

                {(errors?.password?.type === 'server' ||
                  errors?.password?.message ===
                    t('Password must not contain spaces')) && (
                  <div className={classes.check}>
                    <div className={classes.icon}>
                      <KrestIcon />
                    </div>
                    <div className={clsx(classes.name, classes.wrong)}>
                      {errors.password?.message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={classes['item-label']}>{t('Password')}</div>
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder={t('Enter password')}
                value={field.value}
                error={!!errors?.password}
                onChange={(e) => {
                  setPassword(e);
                  field.onChange(e);
                }}
                className={clsx(classes.password, classes.register_theme)}
                onBlur={() => setShowCheks(false)}
                onFocus={() => setShowCheks(true)}
              />
            )}
            name="password"
          />

          <div
            role="button"
            tabIndex={-1}
            onClick={() => setShowPassword(!showPassword)}
            onKeyDown={() => {}}
            className={classes['show-password']}
          >
            <EyeIcon width="18px" height="14px" />
          </div>
        </div>
      </div>

      <div className={classes['checkbox-block']}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox onChange={field.onChange} checked={field.value} />
              <div className={classes.text}>
                <span>{t('I agree to KIT Global')} </span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_MAIN_SITE_URL}/${
                    i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                  }terms`}
                  target={'_blank'}
                >
                  {t('Terms of Service')}
                </Link>
                <span>{t('and')}</span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_MAIN_SITE_URL}/${
                    i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                  }privacy-policy`}
                  target={'_blank'}
                >
                  {t('Privacy Policy')}
                </Link>
              </div>
            </>
          )}
          name="iAgree"
          control={control}
        />
      </div>

      <Button
        className={classes.button}
        theme="primary"
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo(0, 0);
          setStep(2);
        }}
      >
        {t('Continue')}
      </Button>
    </>
  );
};
