import { Token } from '@type/auth';
import { Request } from 'tools/request';
import { CookiesConcentData } from '@type/api';

export async function AcceptCookies({
  cookie_groups_varnames,
  access,
}: Token & { cookie_groups_varnames: string }): Promise<CookiesConcentData> {
  const result = await Request({
    type: 'POST',
    url: `${process.env.REACT_APP_API}/api/v1/users/accept_cookies/`,
    isProtected: true,
    data: { cookie_groups_varnames: cookie_groups_varnames },
    access: access,
  });

  return result;
}
