import { useEffect } from 'react';
import clsx from 'clsx';
import classes from './Modal.module.scss';
import { Logo } from 'components/common/Logo';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { ChevronIcon } from 'components/shared/Icons/ChevronIcon';
import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { Portal } from 'components/common/Portal';

interface Props {
  subModal?: boolean;
  children: any;
  title: string;
  isOpen?: boolean;
  isBottom?: boolean;
  isBasicHeader?: boolean;
  onClose: () => void;
  filterMobile?: boolean;
  className?: string;
  isPriceModal?: boolean;
  isOutsideBase?: boolean;
}

export const Modal = ({
  subModal = false,
  children,
  title,
  isOpen = false,
  onClose,
  isBottom = false,
  isBasicHeader = false,
  filterMobile,
  className,
  isPriceModal,
  isOutsideBase,
}: Props) => {
  useDisableScrolling(isOpen);

  useEffect(() => {
    if (isOpen) {
      if (typeof window !== undefined) {
        const layout = document.getElementsByClassName('.layout-container')[0];
        const element: any = document?.getElementById('#modal-block');
        if (layout && element) {
          layout.appendChild(element);
        }
      }
    }
  }, [isOpen]);

  return (
    <Portal
      selector={isOutsideBase ? '.restore-container' : '.layout-container'}
    >
      <div
        className={clsx(
          !isBottom && classes.modal,
          isOpen && !isBottom && classes.open,
          subModal && classes['sub-modal'],
          isBottom && classes['bottom-modal'],
          isOpen && isBottom && classes['bottom-open'],
          filterMobile && classes['mobile-filter'],
          className && className,
          isPriceModal && classes['price-modal']
        )}
        id="modal-block"
      >
        <div className={clsx(classes.header, isBasicHeader && classes.basic)}>
          {!subModal ? (
            <div
              className={clsx(classes.wrapper, isBasicHeader && classes.basic)}
            >
              {!isBasicHeader && <Logo />}
              <div className={classes.title}>{title}</div>
              <div role="button" onClick={onClose} className={classes.close}>
                <CrossIcon />
              </div>
            </div>
          ) : (
            <div className={classes.wrapper}>
              <div className={classes.title}>
                <div role="button" onClick={onClose}>
                  <ChevronIcon width="16px" height="8px" />
                </div>
                {title}
              </div>
              <div role="button" onClick={onClose} className={classes.close}>
                <CrossIcon />
              </div>
            </div>
          )}
        </div>
        <div className={classes.content}>
          {!isPriceModal && (
            <div
              role="button"
              onClick={onClose}
              className={clsx(classes.close, classes.desktop)}
            >
              <CrossIcon width={'15px'} height={'15px'} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Portal>
  );
};
