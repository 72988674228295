import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const ProfileGradientIcon = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = React.useId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        {RUSSIAN_LOCALE ? (
          <linearGradient id={gradientId} gradientTransform="rotate(150)">
            <stop offset="8.91%" stopColor="#e47267" />
            <stop offset="93.43%" stopColor="#eb5945" />
          </linearGradient>
        ) : (
          <linearGradient id={gradientId} gradientTransform="rotate(140)">
            <stop offset="0%" stopColor="#6e3cff" />
            <stop offset="100%" stopColor="#4ba5ff" />
          </linearGradient>
        )}
      </defs>

      <path
        d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
        stroke={`url(#${gradientId})`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
