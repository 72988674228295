import * as React from 'react';
import { SVGProps } from 'react';

export const InfoIconAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g id="Frame 1232421">
      <rect
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_11710_162927)"
      />
      <path
        id="!"
        d="M18.8161 9.19739L22.5603 9.43674L21.57 23.5062L18.0069 23.2784L18.8161 9.19739ZM17.1088 28.3167C17.1471 27.7185 17.4014 27.2186 17.8717 26.817C18.3622 26.4167 18.9295 26.2371 19.5737 26.2783C20.2178 26.3195 20.7577 26.5698 21.1932 27.0293C21.6287 27.4889 21.8273 28.0177 21.7891 28.6158C21.7508 29.214 21.4865 29.7132 20.996 30.1136C20.5055 30.5139 19.9382 30.6934 19.294 30.6523C18.67 30.6124 18.1402 30.3627 17.7047 29.9032C17.2692 29.4436 17.0706 28.9148 17.1088 28.3167Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11710_162927"
        x1="0"
        y1="2.5"
        x2="40"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6B66C" stopOpacity="0.4" />
        <stop offset="1" stopColor="#F6B66C" />
      </linearGradient>
    </defs>
  </svg>
);
