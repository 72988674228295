import { FC, Suspense, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

export interface AuthRouteProps {
  children?: any;
}

export const AuthRoute: FC<AuthRouteProps> = observer(() => {
  const location = useLocation();

  useEffect(() => {
    toast.dismiss();
  }, [location]);

  return (
    <Suspense fallback={<Outlet />}>
      <Outlet />
    </Suspense>
  );
});
