import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import classes from '../KPIForm.module.scss';
import clsx from 'clsx';
import UiIcon from 'components/shared/Icon';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Input from 'components/shared/NewInput';
import { Select } from 'components/shared/Select';
import { SelectData } from 'components/shared/SelectComponent';
import { useCommonStore } from 'contexts/CommonContext';
import { ValueType } from 'types/util-types';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import formClasses from '../../../FormBlock/FormBlock.module.scss';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { FormValues, KPIStepProps } from './KPIStep.props';
import { FormValidationProps } from 'containers/AddSolution/FormBlock/FormBlock.props';
import { formatNumber } from '../../../../../tools/numberToWords';
import { kpiMessages } from 'containers/AddSolution/FormBlock/validationSchemas';

export const KPIStep: FC<KPIStepProps> = ({
  step,
  error,
  caseNumber,
  index,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { kpiOptions } = useCommonStore() || {};
  const [focused, setFocused] = useState(false);
  const [generalCount, setGeneralCount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMediaTablet } = useWindowWidth();

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<FormValidationProps>();

  const valueTypes = useMemo(
    () =>
      kpiOptions?.value_types?.map((item: ValueType, index: number) => ({
        id: index + 1,
        name: item.key === 'NONE' ? t('None') : item.value,
        type: item.key,
      })),
    [kpiOptions]
  );

  const unitTypes = useMemo(
    () =>
      kpiOptions?.value_units?.map((item: ValueType, index: number) => ({
        id: index + 1,
        name: item.key === 'NONE' ? t('None') : item.value,
        type: item.key,
      })),
    [kpiOptions]
  );

  const nameValue = useWatch<FormValues>({
    name: `cases.${caseNumber}.kpi.${index}.name`,
  });
  const valueTypeValue = useWatch<FormValues>({
    name: `cases.${caseNumber}.kpi.${index}.valueType`,
  });
  const valueValue = useWatch<FormValues>({
    name: `cases.${caseNumber}.kpi.${index}.value`,
  });
  const unitValue = useWatch<FormValues>({
    name: `cases.${caseNumber}.kpi.${index}.unit`,
  });

  useEffect(() => {
    setGeneralCount(
      [nameValue, valueTypeValue, valueValue, unitValue]?.filter((item) => item)
        .length
    );
  }, [nameValue, valueTypeValue, valueValue, unitValue]);

  useEffect(() => {
    if (inputRef.current && focused) {
      inputRef.current.focus();
    }
  }, [focused, inputRef]);

  const errorMessage = (type: any) =>
    // eslint-disable-next-line
    // @ts-ignore
    errors?.cases?.[caseNumber]?.kpi?.[index]?.[type]?.message;

  const endIconOptions = (title: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: title,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: formClasses.endIconTooltip,
    endIconClassName: formClasses.endIcon,
  });

  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIconOptions(
    t(
      'Share the key performance indicators. This demonstrates the measurable results of the case'
    )
  );

  return (
    <div className={clsx(classes.kpiStep, error && classes.error)}>
      <div className={classes.kpiStepTitle}>
        <div className={classes.kpiStepTitle__titleContainer}>
          <div className={classes.kpiStepTitle__text}>
            <span>{step?.title || t('KPI 1')}</span>
          </div>

          {endIconName && (
            <TooltipContainer
              text={endIconTooltipText}
              customClasses={'kit-ui-block'}
              position={endIconTooltipPosition}
              className={clsx(endIconTooltipClassName, classes.infoTooltip)}
            >
              <UiIcon
                name={endIconName}
                additionalClassName={clsx(
                  classes.infoIcon,
                  endIconClassName,
                  error && classes.error
                )}
              />
            </TooltipContainer>
          )}

          <span className={classes.counter}>
            {generalCount} {t('out of 4 filled')}
          </span>
        </div>

        <button className={classes.deleteButton} onClick={onDelete}>
          {t('Remove')}
        </button>
      </div>

      <div className={classes.kpiItems}>
        <div className={classes.kpiItems__input}>
          <Controller
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(
                    e.replaceAll(/\s{2,}/g, ' ').replace(/^\s/g, '')
                  );
                  clearErrors(`cases.${caseNumber}.kpi.${index}.name`);
                }}
                title={t('KPI name')}
                placeholder={t('KPI name')}
                error={errorMessage('name')}
                errorText={errorMessage('name')}
                maxLength={50}
                endIcon={endIconOptions(kpiMessages(t).name)}
              />
            )}
            name={`cases.${caseNumber}.kpi.${index}.name`}
            control={control}
          />
        </div>

        <div className={classes.inputGroup}>
          <div className={classes.inputGroup__item}>
            <Controller
              render={({ field }) => (
                <Select
                  title={t('Type')}
                  placeholder={t('Select value type')}
                  select={valueTypes?.find(
                    (item) => field?.value === item?.type
                  )}
                  options={valueTypes}
                  onSelect={(item: SelectData) => {
                    field.onChange(item?.type);
                    clearErrors(`cases.${caseNumber}.kpi.${index}.valueType`);
                  }}
                  isSolutionForm
                  error={errorMessage('valueType')}
                  endIcon={endIconOptions(kpiMessages(t).valueType)}
                />
              )}
              name={`cases.${caseNumber}.kpi.${index}.valueType`}
              control={control}
            />
          </div>

          <div className={classes.inputGroup__item}>
            <Controller
              render={({ field }) => (
                <Input
                  value={field.value ? formatNumber(field.value) : ''}
                  onChange={(inputValue: string) => {
                    clearErrors(`cases.${caseNumber}.kpi.${index}.value`);
                    const regex = /^\d{0,8}(\.\d{0,2})?$/;
                    if (regex.test(inputValue)) {
                      field.onChange(inputValue);
                    }
                  }}
                  type="text"
                  title={t('Value')}
                  placeholder={t('Value')}
                  error={errorMessage('value')}
                  errorText={errorMessage('value')}
                  endIcon={endIconOptions(kpiMessages(t).value)}
                />
              )}
              name={`cases.${caseNumber}.kpi.${index}.value`}
              control={control}
            />
          </div>

          <div className={classes.inputGroup__item}>
            <Controller
              render={({ field }) => (
                <Select
                  title={t('Unit')}
                  placeholder={t('Select unit')}
                  select={unitTypes?.find(
                    (item) => field?.value === item?.type
                  )}
                  options={unitTypes}
                  onSelect={(item: SelectData) => {
                    field.onChange(item?.type);
                    clearErrors(`cases.${caseNumber}.kpi.${index}.unit`);
                  }}
                  isSolutionForm
                  error={errorMessage('unit')}
                  endIcon={endIconOptions(kpiMessages(t).unit)}
                />
              )}
              name={`cases.${caseNumber}.kpi.${index}.unit`}
              control={control}
            />
          </div>
        </div>
      </div>

      {error && <div className={classes.errorText}>{error}</div>}
    </div>
  );
};
