import * as React from 'react';
import { SVGProps } from 'react';

export const MasterCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
    {...props}
  >
    <path d="M8.396 1.50726H14.5483V12.5266H8.396V1.50726Z" fill="#FF5F00" />
    <path
      d="M8.77762 7.01682C8.77836 5.95675 9.0204 4.91057 9.48564 3.95647C9.95088 3.00238 10.6273 2.16507 11.4643 1.50714C10.4243 0.703796 9.17833 0.206258 7.86786 0.0710676C6.55739 -0.0641228 5.235 0.168457 4.051 0.742374C2.86699 1.31629 1.86885 2.20853 1.17 3.31769C0.471152 4.42685 0.0996303 5.70844 0.0976562 7.01682C0.0996303 8.3252 0.471152 9.6068 1.17 10.716C1.86885 11.8251 2.86699 12.7174 4.051 13.2913C5.235 13.8652 6.55739 14.0978 7.86786 13.9626C9.17833 13.8274 10.4243 13.3298 11.4643 12.5265C10.6255 11.8701 9.94787 11.0331 9.48245 10.0787C9.01704 9.12425 8.77603 8.07728 8.77762 7.01682Z"
      fill="#EB001B"
    />
    <path
      d="M22.8476 7.01673C22.8456 8.32511 22.4741 9.6067 21.7752 10.7159C21.0764 11.825 20.0782 12.7173 18.8942 13.2912C17.7102 13.8651 16.3878 14.0977 15.0774 13.9625C13.7669 13.8273 12.5209 13.3298 11.481 12.5264C12.3168 11.8679 12.992 11.0303 13.4562 10.0762C13.9203 9.12216 14.1614 8.07629 14.1614 7.01673C14.1614 5.95716 13.9203 4.91129 13.4562 3.95724C12.992 3.00318 12.3168 2.1656 11.481 1.50704C12.5174 0.690884 13.7651 0.182531 15.0801 0.0407054C16.3951 -0.101121 17.7236 0.129367 18.9123 0.705544C20.101 1.28172 21.1012 2.18008 21.7976 3.29685C22.4939 4.41362 22.858 5.70323 22.8476 7.01673Z"
      fill="#F79E1B"
    />
  </svg>
);
