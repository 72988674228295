import React, { useEffect, useState } from 'react';
import classes from './Accordion.module.scss';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import clsx from 'clsx';

type AccordionProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  expanded?: boolean;
  onChangeExpanded?: (isExpanded: boolean) => void;
  headerClassname?: string;
  reverse?: boolean;
  hideArrowIcon?: boolean;
  scrollable?: boolean;
};

function Accordion(props: AccordionProps) {
  const [open, setOpen] = useState(!!props.expanded);

  useEffect((): void => {
    setOpen(!!props.expanded);
  }, [props.expanded]);

  const onChangeView = (): void => {
    setOpen((prev) => {
      props.onChangeExpanded?.(!prev);
      return !prev;
    });
  };

  return (
    <div className={classes.accordion}>
      <div
        className={clsx(
          classes.accordion__header,
          props.reverse && classes.accordion__header_reverse,
          props.hideArrowIcon && classes['zero-padding'],
          props.headerClassname
        )}
        onClick={onChangeView}
      >
        {props.header}
        {!props.hideArrowIcon && (
          <div
            className={clsx(
              classes.accordion__arrow,
              open && classes.accordion__arrow_up,
              props.reverse && classes.accordion__arrow_reverse
            )}
          >
            <ArrowDownIcon />
          </div>
        )}
      </div>
      <div
        className={clsx(
          classes.accordion__content,
          open && classes.accordion__content_expanded,
          props?.scrollable && classes.accordion__content_scrollable
        )}
      >
        {props.children}
      </div>
    </div>
  );
}

export default React.memo(Accordion);
