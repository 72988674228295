import { IStatus } from '@type/api';
import {
  GetSolutionProjectsResponse,
  Project,
  SolutionProjects,
} from '@type/projects';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import ProjectsService from 'services/projects';
import { ShowNotification } from 'tools/showNotification';
import StatusFactory from 'tools/status';
import i18next from 'i18next';

export class ProjectsStore {
  solutionsWithProjects: SolutionProjects[] = [];
  loadSolutionsProjectsStatus: IStatus<GetSolutionProjectsResponse | null> =
    StatusFactory.default();
  selectedProject: Project | null = null;
  loadSelectedProjectStatus: IStatus<Project | null> = StatusFactory.default();
  allProjectsCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public resetStore(): void {
    this.solutionsWithProjects = [];
    this.selectedProject = null;
    this.allProjectsCount = 0;
  }

  public resetSolutionsProjectsStatus(): void {
    this.loadSolutionsProjectsStatus = StatusFactory.default();
  }

  public async loadSolutionsWithProjects(
    params?: string,
    onError?: () => void
  ) {
    this.loadSolutionsProjectsStatus = StatusFactory.loading();

    try {
      const response = await ProjectsService.getSolutionProjects(params);

      runInAction(() => {
        this.solutionsWithProjects = response.data.results;
        this.allProjectsCount = response.data.allProjectsCount;
        this.loadSolutionsProjectsStatus = StatusFactory.success(response.data);
      });
    } catch (error) {
      onError?.();
      runInAction(() => {
        this.loadSolutionsProjectsStatus = StatusFactory.failed(
          error as AxiosError
        );
      });
    }
  }

  loadProject = async (projectId: string) => {
    this.loadSelectedProjectStatus = StatusFactory.loading();
    try {
      const response = await ProjectsService.getProject(Number(projectId));
      runInAction(() => {
        this.selectedProject = response.data;
        this.loadSelectedProjectStatus = StatusFactory.success(response.data);
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        this.loadSelectedProjectStatus = StatusFactory.failed(error);
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching project'),
          });
        }
      }
    }
  };
}

export const projectsStore = new ProjectsStore();
