import React, { FC } from 'react';
import { FourthStepProps } from './FourthStep.props';
import classes from '../FormBlock.module.scss';
import { Button } from 'components/shared/Button';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { useFourthStep } from './useFourthStep';

const FourthStep: FC<FourthStepProps> = ({
  disabled,
  setCurrentStep,
  setOpenCancelModal,
  setOpenSubmitModal,
  t,
  currentStep,
  isFormBlock,
}) => {
  const { onValidateClick } = useFourthStep(setOpenSubmitModal, currentStep);
  return (
    <div>
      {!disabled && (
        <div className={classes.bottomBlock}>
          <div className={classes.left}>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setCurrentStep?.(3);
              }}
            >
              <PurpleArrowLeftIcon />
              {t('Back')}
            </Button>

            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>
          </div>

          <Button
            className={classes.button}
            theme="default"
            onClick={async (e: any) => {
              e.preventDefault();
              await onValidateClick();
            }}
          >
            {t('Submit to review')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FourthStep;
