import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import classes from './AnalyticsCard.module.scss';
import clsx from 'clsx';
import { QuestionCircleIcon } from 'components/shared/Icons/QuestionCircleIcon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { Button } from 'components/shared/Button';
import { useState } from 'react';
import MobileModal from 'containers/PartnerContainer/Mobile';
import { percentNumber } from '../../../PartnerContainer/utils';
import { useTranslation } from 'react-i18next';

type AnalyticsCardProps = {
  title: string;
  count: number;
  percentage: string;
  description?: string;
  filterText: string;
  arrowDown?: boolean;
  isMobile?: boolean;
};

function AnalyticsCard(props: AnalyticsCardProps) {
  const [openDesc, setOpenDesc] = useState(false);
  const isZeroAnalytics = props.count === 0 && props.percentage === '';
  const { t } = useTranslation();
  const isNoneCart = percentNumber({ isNone: props.percentage });

  return (
    <div className={classes.card}>
      <div className={classes.card__header}>
        <span>{t(`${props.title}`)}</span>
        {props.description &&
          (!props.isMobile ? (
            <TooltipContainer
              text={props.description}
              position="left"
              className={classes.tooltipContainer}
              classNameTooltip={classes.descTooltip}
            >
              <QuestionCircleIcon width={20} height={20} viewBox="0 0 20 20" />
            </TooltipContainer>
          ) : (
            <Button theme="text" onClick={() => setOpenDesc(true)}>
              <QuestionCircleIcon width={20} height={20} viewBox="0 0 20 20" />
            </Button>
          ))}
      </div>
      <div className={classes.card__content}>
        <div className={classes.card__content_inner}>
          <span className={classes.card__content_count}>{props.count}</span>
          <div
            className={clsx(
              classes.card__content_percentage,
              props.arrowDown && classes.down,
              isZeroAnalytics && classes.zero
            )}
          >
            {props.percentage && !isNoneCart && (
              <>
                <ArrowUpRight
                  className={classes.arrowIcon}
                  fill={props.arrowDown ? '#EA4A70' : '#4CBB43'}
                />
                {percentNumber({ percentage: props.percentage })}
              </>
            )}
          </div>
        </div>
        <div className={classes.filterText}>{props.filterText}</div>
      </div>
      {props.isMobile && openDesc && (
        <div className={classes.modal}>
          <MobileModal
            title={props.title}
            open={openDesc}
            setOpen={setOpenDesc}
            data={[{ id: 999, name: props.description }]}
          />
        </div>
      )}
    </div>
  );
}

export default AnalyticsCard;
