import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const MenuGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="14px"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.3A.3.3 0 0 1 .3 6h19.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H.3a.3.3 0 0 1-.3-.3V6.3Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 .3A.3.3 0 0 1 .3 0h19.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H.3a.3.3 0 0 1-.3-.3V.3Z"
      fill="url(#b)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.3a.3.3 0 0 1 .3-.3h15.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H.3a.3.3 0 0 1-.3-.3v-1.4Z"
      fill="url(#c)"
    />
    <defs>
      {!RUSSIAN_LOCALE && (
        <>
          <linearGradient
            id="a"
            x1={14.824}
            y1={0}
            x2={14.15}
            y2={14.197}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65B2FF" />
            <stop offset={1} stopColor="#6638F4" />
          </linearGradient>
          <linearGradient
            id="b"
            x1={14.824}
            y1={0}
            x2={14.15}
            y2={14.197}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65B2FF" />
            <stop offset={1} stopColor="#6638F4" />
          </linearGradient>
          <linearGradient
            id="c"
            x1={14.824}
            y1={0}
            x2={14.15}
            y2={14.197}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65B2FF" />
            <stop offset={1} stopColor="#6638F4" />
          </linearGradient>
        </>
      )}
      {RUSSIAN_LOCALE && (
        <>
          <linearGradient
            id="a"
            x1="-7.7"
            y1="-2.77"
            x2="6.16388"
            y2="19.1251"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB3D2" />
            <stop offset="1" stopColor="#FF4848" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="-7.7"
            y1="-2.77"
            x2="6.16388"
            y2="19.1251"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB3D2" />
            <stop offset="1" stopColor="#FF4848" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="-7.7"
            y1="-2.77"
            x2="6.16388"
            y2="19.1251"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB3D2" />
            <stop offset="1" stopColor="#FF4848" />
          </linearGradient>
        </>
      )}
    </defs>
  </svg>
);
