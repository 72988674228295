import React, { ReactNode } from 'react';
import { FinancesStore, financesStore } from 'stores/financesStore';

const FinancesContext = React.createContext<FinancesStore | null>(null);

export const FinancesProvider = ({ children }: { children: ReactNode }) => (
  <FinancesContext.Provider value={financesStore}>
    {children}
  </FinancesContext.Provider>
);

export const useCommonStore = () => {
  const context = React.useContext(FinancesContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with UserProvider'
    );
  }
  return context;
};
