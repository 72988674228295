import { Type } from 'components/shared/Type';
import { useTranslation } from 'react-i18next';
import classes from './BusinessHeader.module.scss';
import { WaitingIcon } from 'components/shared/Icons/WaitingIcon';
import { BusinessHeaderProps } from './BusinessHeader.props';
import { getLinkForPrivacy } from '../../getLinkForPrivacy';
import { VerifiedIcon } from 'components/shared/Icons/VerifiedIcon';

const BusinessHeader = ({ partner }: BusinessHeaderProps) => {
  const { t, i18n } = useTranslation();

  const isApproved = partner?.is_active;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.helperInfo}>
          <div className={classes.titleWrap}>
            <Type tag={'h2'} className={classes.title}>
              {t('Account information')}
            </Type>
          </div>

          <p className={classes['info']}>
            {t(
              'Information about the Account. By providing contact information,'
            )}
            <br />
            {t('you agree to the terms of the')}{' '}
            <a
              className={classes['info__link']}
              href={getLinkForPrivacy(i18n.language)}
              target="_blank"
              rel="noreferrer"
            >
              {t('Privacy Policy on account')}
            </a>
          </p>

          {/* {isApproved && RUSSIAN_LOCALE && <LoyaltyProgram />} */}
        </div>

        {isApproved && (
          <div className={classes.approved}>
            <VerifiedIcon />
            <div className={classes.approvedText}>{t('Account verified')}</div>
          </div>
        )}
      </div>

      {!isApproved && (
        <div className={classes.attention}>
          <WaitingIcon style={{ flexShrink: 0 }} />

          <ul className={classes.attention__list}>
            <li className={classes.attention__title}>
              {t('Checking Account information')}
            </li>
            <li className={classes.attention__description}>
              {t(
                `We're tidying up the data so everything shows up just right.`
              )}
              <br />
              {t(`Usually, this takes about 1 to 3 business days`)}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default BusinessHeader;
