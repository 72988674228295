import { useUserStore } from 'contexts/UserContext';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PartnerPage } from './Partner';
import { PARTNER_ROLE } from 'types/user';

function ConfirmModerate() {
  const { user, partners } = useUserStore();
  const permissionType = partners?.results?.[0]?.permission?.type;

  const isAdmin = permissionType === PARTNER_ROLE.ADMIN;

  const isManager =
    permissionType === PARTNER_ROLE.MANAGER ||
    permissionType === PARTNER_ROLE.VIEWER;

  const isAccountPage = window.location.href.toLowerCase().includes('account');

  if (
    user?.id &&
    !isAdmin &&
    !isAccountPage &&
    !isManager &&
    !user?.moderated
  ) {
    return <PartnerPage />;
  }

  return <Outlet />;
}

export default ConfirmModerate;
