import { toJS } from 'mobx';
import { Outlet } from 'react-router-dom';
import { useUserStore } from '../../contexts/UserContext';
import { NeedConfirm } from '../../pages/NeedConfirm';

export const ConfirmEmail = () => {
  const userStore = useUserStore();
  if (toJS(userStore).user?.id && !toJS(userStore).user?.email_confirmed) {
    return <NeedConfirm />;
  }

  return <Outlet />;
};
