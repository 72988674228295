import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import classes from './DefaultSelectItem.module.scss';
import clsx from 'clsx';

type DefaultSelectItemProps = {
  label: string;
  selected?: boolean;
  hovered?: boolean;
  onClick?: () => void;
};

function DefaultSelectItem({
  label,
  hovered,
  selected,
  onClick,
}: DefaultSelectItemProps) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        classes['item-wrapper'],
        (hovered || selected) && classes['item-wrapper_hovered']
      )}
    >
      <span className={classes['item-content']}>{label}</span>
      {selected && <CheckIcon />}
    </div>
  );
}

export default DefaultSelectItem;
