import clsx from 'clsx';
import classes from './PageTabs.module.scss';

type PageTab<ID> = {
  id: ID;
  label: React.ReactNode;
};

type SolutionTabsProps<TId> = {
  activeTab: PageTab<TId>['id'];
  tabs: PageTab<TId>[];
  onChange: (id: PageTab<TId>['id']) => void;
};

function PageTabs<TId extends string | number>({
  tabs,
  activeTab,
  onChange,
}: SolutionTabsProps<TId>) {
  return (
    <div className={classes.tabs}>
      {tabs.map((tab) => (
        <div
          onClick={() => onChange(tab.id)}
          key={tab.id}
          className={clsx(
            classes.tabs__tab,
            activeTab === tab.id && classes['tabs__tab--active']
          )}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
}

export default PageTabs;
