import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import classes from './ProjectGoals.module.scss';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { userStore } from 'stores/user-store';
import _ from 'lodash';
import { pushToDataLayer } from 'tools/analytics';
import UiIcon from 'components/shared/Icon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { EndIconProps } from 'components/shared/NewInput';
import { getLangName } from 'tools/utils';
import Partner from 'services/partner';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { GoalType } from 'types/partner';

interface Props {
  project?: any;
  userGoals?: any;
  updateProject?: any;
  tabsId?: string;
  disabled?: boolean;
  title?: string;
  isAddSolution?: boolean;
  systemGoals?: any;
  setSolutionGoals?: any;
  error?: string;
  onChange?: (value?: GoalType[] | SystemGoal[]) => void;
  endIcon?: EndIconProps;
}

interface SystemGoal {
  name: string;
  id?: number;
}

export const ProjectGoalsContainer: FC<Props> = observer(
  ({
    onChange,
    project,
    isAddSolution,
    systemGoals,
    userGoals,
    setSolutionGoals,
    error,
    updateProject,
    tabsId,
    disabled,
    title,
    endIcon,
  }) => {
    const { t, i18n } = useTranslation();
    const internalGoals = isAddSolution ? [] : project?.goals;
    const internalSystemGoals = isAddSolution
      ? systemGoals
      : project?.project_system_goals;
    const { isMediaTablet } = useWindowWidth();

    const editableDivRef = useRef<HTMLDivElement>(null);
    const editableInputRef = useRef<HTMLInputElement>(null);
    const [projectGoals, setProjectGoals] =
      useState<SystemGoal[]>(internalGoals);
    const [systemProjectGoals, setSystemProjectGoal] =
      useState<SystemGoal[]>(internalSystemGoals);
    const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [newGoal, setNewGoal] = useState<SystemGoal | null>(null);
    const [projectId, setProjectId] = useState<number>();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const userLocale = userStore.user?.language?.lang_code;
    const maxLength = 80;

    const {
      endIconName,
      endIconTooltipText,
      endIconTooltipPosition,
      endIconTooltipClassName,
      endIconClassName,
    } = endIcon || {};

    useEffect(() => {
      if (newGoal) {
        setProjectId(project?.id || 1);
      }
      if (!newGoal) {
        setIsFocused(false);
      }
    }, [newGoal, project?.id]);

    useEffect(() => {
      if (disabled) return;
      if (isAddSolution) {
        const uniqueUserGoals = _.uniqBy(userGoals as SystemGoal[], 'name');
        const intersectionGoals = _.differenceBy(
          internalSystemGoals,
          uniqueUserGoals,
          'id'
        );
        if (userGoals?.length) {
          setProjectGoals(uniqueUserGoals);
          setSystemProjectGoal(intersectionGoals as unknown as SystemGoal[]);
        }
        return;
      } else {
        updateProject().then((project: any) => {
          setSystemProjectGoal(project?.data?.project_system_goals);
          setProjectGoals(project?.data?.goals);
        });
      }
    }, [disabled, userLocale, isAddSolution]);

    const checkGoals = (goalsArray: SystemGoal[], goal: SystemGoal) =>
      _.findIndex(goalsArray, (o) => _.isMatch(o, goal)) > -1;

    const handleGoal = async (
      projectId: number,
      goal: any,
      removed = false
    ) => {
      setIsClicked(true);
      const finalGoal = goal?.goal ? goal.goal : goal;
      if (isAddSolution) {
        if (!removed) {
          setProjectGoals((prevState) => {
            const isDuplicate = prevState.some(
              (goal) => goal?.name?.trim() === finalGoal?.name?.trim()
            );
            return isDuplicate ? prevState : [...prevState, finalGoal];
          });
          const uniqueGoals = _.uniqBy(
            [...projectGoals, finalGoal] as SystemGoal[],
            'name'
          );

          setSolutionGoals && setSolutionGoals(uniqueGoals);
          if (onChange) {
            onChange(uniqueGoals);
          }
          setSystemProjectGoal((prevState) =>
            prevState?.filter((systemGoal) => systemGoal?.id !== finalGoal?.id)
          );
        } else if (removed) {
          setProjectGoals((prevState) => {
            if (onChange) {
              onChange(
                prevState?.filter(
                  (projectGoal) => projectGoal?.name !== finalGoal?.name
                )
              );
            }
            return prevState?.filter(
              (projectGoal) => projectGoal?.name !== finalGoal?.name
            );
          });
          setSystemProjectGoal((prevState) => {
            const isSystemGoal = internalSystemGoals?.some(
              (goal: SystemGoal) => goal.id === finalGoal?.id
            );
            const uniqueGoals = _.uniqBy(
              [...prevState, finalGoal] as SystemGoal[],
              'name'
            );

            return isSystemGoal ? uniqueGoals : prevState;
          });
          setSolutionGoals &&
            setSolutionGoals((prevState: any) => {
              const filterGoals = prevState?.filter(
                (solutionGoal: any) => solutionGoal?.name !== finalGoal?.name
              );
              if (onChange) {
                onChange(filterGoals);
              }
              return filterGoals;
            });
        }
        setTimeout(() => setIsClicked(false), 500);
        return;
      }

      try {
        if (removed) {
          await Partner.deleteGoal(projectId, finalGoal?.id);
          const data = await updateProject();
          setSystemProjectGoal(data.project_system_goals);
          setProjectGoals(data.goals);
        } else if (!checkGoals(projectGoals, finalGoal)) {
          const data = await Partner.updateProject(projectId, finalGoal?.name);
          setProjectGoals(data?.goals);
          setSystemProjectGoal(data?.project_system_goals);
        }
      } catch (error) {
        console.error(error);
      } finally {
        updateProject();
      }
      setTimeout(() => setIsClicked(false), 500);
    };

    const handleAddAnotherGoal = () => {
      handleGoal(project?.id, newGoal);
      setNewGoal(null);
      setAddAnotherGoal(false);
      setIsFocused(false);
      setProjectId(undefined);
    };

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        handleAddAnotherGoal();
      }
    };

    const icon = () => (
      <svg
        onClick={() => {
          setNewGoal(null);
          setAddAnotherGoal(false);
          setIsFocused(false);
          setProjectId(undefined);
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.00016 3.33301V12.6663M3.3335 7.99967H12.6668"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
    const handleNonInputClick = (event: any) => {
      if (event.target.closest(`#${tabsId}`)) return;
      if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        projectId &&
        isFocused
      ) {
        event.preventDefault();
        handleAddAnotherGoal();
      } else if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        !isFocused &&
        !newGoal?.name
      ) {
        event.preventDefault();
        setNewGoal(null);
        setAddAnotherGoal(false);
        setProjectId(undefined);
      }
    };

    useEffect(() => {
      if (disabled) return;
      document.addEventListener('click', handleNonInputClick);
      return () => {
        document.removeEventListener('click', handleNonInputClick);
      };
    }, [newGoal, projectId, isFocused, handleNonInputClick]);

    const getGoalChoice = (goals: SystemGoal[]) =>
      goals.map((goal: SystemGoal) => (
        <div
          className={classes.goals__item}
          onClick={() => {
            if (!isClicked) {
              handleGoal(project?.id, goal);
              pushToDataLayer('addGoals', 'buttonClick', 'GAForms');
            }
          }}
        >
          <div>{getLangName(goal, 'name', i18n)}</div>
          {icon()}
        </div>
      ));

    const getProjectGoals = (goals: any) =>
      goals?.map((goal: any, index: number) => {
        const finalGoal = goal?.goal ? goal.goal : goal;
        return (
          <div
            className={clsx(classes.goals__item, classes.goals__item_active)}
            onClick={() => {
              handleGoal(project?.id, goal, true);
              setIsClicked(true);
            }}
            key={finalGoal?.name + index}
          >
            <div className={classes.goals__item__tag}>
              {getLangName(finalGoal, 'name', i18n)}
            </div>
            <span className={classes.goalLength}>
              {goal?.name?.length}/{maxLength}
            </span>
            {icon()}
          </div>
        );
      });

    useEffect(() => {
      const currentElement = editableInputRef.current || editableDivRef.current;
      if (addAnotherGoal && currentElement) {
        currentElement.focus();
      }
    }, [addAnotherGoal]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target?.value as HTMLInputElement['value'];
      const divValue = editableDivRef.current?.innerText || '';
      const currentDiv = editableDivRef?.current;

      if (isMediaTablet) {
        if (divValue.length > maxLength) {
          currentDiv!.innerText = divValue?.substring(0, maxLength);

          const range = document.createRange();
          const selection = window.getSelection();
          range.selectNodeContents(currentDiv!);
          range.collapse(false);
          selection?.removeAllRanges();
          selection?.addRange(range);
        } else {
          setNewGoal({ name: divValue });
        }
      } else {
        if (value?.length <= maxLength) {
          setNewGoal({ name: value });
          e.target.style.width = `${e.target.scrollWidth}px`;
        }
      }
    };

    return (
      <div className={classes.goals}>
        {title && (
          <div className={classes.goals__title}>
            <span>{title} </span>
            {endIconName && (
              <TooltipContainer
                text={endIconTooltipText}
                customClasses={'kit-ui-block'}
                position={endIconTooltipPosition}
                className={clsx(
                  endIconTooltipClassName,
                  classes['end-icon__tooltip']
                )}
              >
                <UiIcon
                  name={endIconName}
                  additionalClassName={clsx(
                    classes.infoIcon,
                    endIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            )}
          </div>
        )}
        <div
          className={clsx(
            classes.goals__main,
            (projectGoals?.length > 0 || addAnotherGoal) &&
              classes.goals__main_active,
            error && classes.error
          )}
        >
          {getProjectGoals(projectGoals)}

          {addAnotherGoal && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_active)}
              onKeyDown={handleKeyDown}
            >
              {isMediaTablet ? (
                <div
                  contentEditable
                  ref={editableDivRef}
                  className={classes.divElement}
                  onInput={handleChange}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  onBlur={() => {
                    !newGoal?.name && setIsFocused(false);
                  }}
                />
              ) : (
                <input
                  ref={editableInputRef}
                  className={classes.input}
                  value={newGoal?.name}
                  maxLength={maxLength}
                  onChange={handleChange}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  onBlur={() => {
                    !newGoal?.name && setIsFocused(false);
                  }}
                />
              )}
              <span className={classes.goalLength}>
                {newGoal?.name?.length || 0}/{maxLength}
              </span>
              {icon()}
            </div>
          )}
          {projectGoals?.length > 0 && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_add)}
              onClick={() => setAddAnotherGoal(true)}
            >
              <span>{t('Add another…')}</span>
              {icon()}
            </div>
          )}
          {projectGoals?.length <= 0 && !addAnotherGoal && (
            <div className={clsx(classes.goals__empty_block)}>
              <p className={clsx(classes.goals__empty_text)}>
                {t('Add goals so they appear here')}
                <br />
                {error && error}
              </p>
              <div
                className={clsx(classes.goals__empty_btn)}
                onClick={() => {
                  setAddAnotherGoal(true);
                }}
              >
                {t('Add goal')}
                {icon()}
              </div>
            </div>
          )}
        </div>

        {systemProjectGoals?.length > 0 && (
          <div className={classes.goals__bottom_text}>
            {t('Perhaps your goals are among these')}
          </div>
        )}
        <div className={classes.goals__bottom}>
          {getGoalChoice(systemProjectGoals)}
        </div>
      </div>
    );
  }
);
