import React, { ReactNode } from 'react';
import { AuthStore, authStore } from 'stores/authStore';

const AuthContext = React.createContext<AuthStore | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => (
  <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
);

export const useAuthStore = () => {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with AuthProvider'
    );
  }
  return context;
};
