import { Button } from 'components/shared/Button';
import { CopyIcon } from 'components/shared/Icons/CopyIcon';
import { EmptyDocsIcon } from 'components/shared/Icons/EmptyDocsIcon';
import { copyTextToClipboard } from 'tools/copyToClipboard';
import { splitNumber } from 'tools/numberToWords';
import { ShowNotification } from 'tools/showNotification';
import classes from './LegalEntityCard.module.scss';
import { LegalEntityCardProps } from './LegalEntityCard.props';
import { useTranslation } from 'react-i18next';

function LegalEntityCard({
  title,
  companyName,
  entityId,
  kitBank,
  entityBank,
}: LegalEntityCardProps) {
  const { t } = useTranslation();
  const handleCopied = function (text: string) {
    copyTextToClipboard(text, () =>
      ShowNotification({
        type: 'success',
        children: t('Successfully copied'),
      })
    );
  };

  return (
    <div className={classes.card}>
      {!entityId ? (
        <div className={classes.empty}>
          <span className={classes.title}>{title}</span>

          <div className={classes.empty__content}>
            <EmptyDocsIcon />
            <span className={classes.empty__content_text}>
              {t('Company details are missing')}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.company}>
            <span className={classes.title}>{title}</span>
            <span className={classes.company__name}>{companyName}</span>
            <span className={classes.company__id}>ID {entityId}</span>
          </div>

          <div className={classes.bank}>
            <div className={classes.bank__details}>
              <span className={classes.bank__details_title}>
                {kitBank.label}
              </span>

              <div className={classes.bank__details_number}>
                <span>{splitNumber(Number(kitBank.number), 4)}</span>
                <Button
                  theme="text"
                  onClick={() => handleCopied(kitBank.number)}
                >
                  <CopyIcon width={14} height={14} viewBox="0 0 14 14" />
                </Button>
              </div>
            </div>

            <div className={classes.bank__details}>
              <span className={classes.bank__details_title}>
                {entityBank.label}
              </span>

              <div className={classes.bank__details_number}>
                <span>{splitNumber(Number(entityBank.number), 4)}</span>
                <Button
                  theme="text"
                  onClick={() => handleCopied(entityBank.number)}
                >
                  <CopyIcon width={14} height={14} viewBox="0 0 14 14" />
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LegalEntityCard;
