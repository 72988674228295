import * as React from 'react';
import { SVGProps } from 'react';

export const H3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Text editor">
      <g id="H3">
        <path
          d="M3.32626 14.0909V4.54541H4.76647V8.69358H9.52522V4.54541H10.9701V14.0909H9.52522V9.92871H4.76647V14.0909H3.32626Z"
          fill="currentColor"
        />
        <path
          d="M14.8339 15.8061C14.4051 15.8061 14.0214 15.7346 13.6827 15.5917C13.3471 15.4487 13.0799 15.2514 12.881 14.9997C12.6853 14.748 12.5843 14.4575 12.5781 14.1282H13.6035C13.6128 14.3798 13.734 14.5818 13.967 14.7341C14.2001 14.8894 14.489 14.9671 14.8339 14.9671C15.1975 14.9671 15.4958 14.8832 15.7288 14.7154C15.965 14.5476 16.0831 14.3332 16.0831 14.0722C16.0831 13.8112 15.9572 13.5968 15.7055 13.429C15.4569 13.2612 15.1229 13.1773 14.7034 13.1773H14.2374V12.4316H14.7034C15.0483 12.4316 15.3358 12.3539 15.5657 12.1986C15.7987 12.0432 15.9153 11.835 15.9153 11.574C15.9153 11.3316 15.8174 11.1312 15.6216 10.9727C15.4259 10.8143 15.1695 10.735 14.8526 10.735C14.5356 10.735 14.2622 10.8127 14.0323 10.9681C13.8054 11.1234 13.6874 11.3316 13.678 11.5926H12.7086C12.7148 11.2633 12.8127 10.9743 13.0022 10.7257C13.1949 10.4771 13.4512 10.2829 13.7713 10.1431C14.0944 10.0033 14.4549 9.93337 14.8526 9.93337C15.2565 9.93337 15.6123 10.0017 15.9199 10.1385C16.2275 10.2752 16.4684 10.4632 16.6424 10.7024C16.8164 10.9417 16.9034 11.2136 16.9034 11.5181C16.9034 11.8536 16.7884 12.1286 16.5585 12.343C16.3285 12.5574 16.0458 12.6926 15.7102 12.7485V12.7858C16.167 12.8511 16.5165 13.008 16.7589 13.2566C17.0044 13.5051 17.1271 13.8019 17.1271 14.1468C17.1271 14.4637 17.0292 14.7465 16.8335 14.9951C16.6377 15.2468 16.3674 15.4441 16.0225 15.587C15.6776 15.733 15.2814 15.8061 14.8339 15.8061Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
