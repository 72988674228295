import React, { FC } from 'react';
import classes from './NoticeBox.module.scss';
import clsx from 'clsx';
import { NoticeType } from 'types/util-types';
import UiIcon from 'components/shared/Icon';
import { RUSSIAN_LOCALE } from 'utils';

interface NoticeBoxProps {
  title: string;
  description: string;
  type: NoticeType;
  className?: string;
}

export const NoticeBox: FC<NoticeBoxProps> = ({
  title,
  description,
  type,
  className,
}) => {
  const iconName = () => {
    if (type === 'default') {
      return RUSSIAN_LOCALE ? 'PaperClipIcon' : 'ExclamationIcon';
    }

    if (type === 'warning') {
      return 'ExclamationIcon';
    }

    if (type === 'waiting') {
      return RUSSIAN_LOCALE ? 'ExclamationIcon' : 'ClockIcon';
    }
  };

  return (
    <div className={clsx(classes.noticeContainer, classes[type], className)}>
      <UiIcon name={iconName()} additionalClassName={classes.icon} />
      <div className={classes.textBlock}>
        <div className={classes.noticeTitle}>{title}</div>
        <div className={classes.noticeDescription}>{description}</div>
      </div>
    </div>
  );
};
