import { SVGProps } from 'react';

export const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66666 10C1.66666 5.39809 5.39806 1.66669 9.99999 1.66669C14.6019 1.66669 18.3333 5.39809 18.3333 10C18.3333 14.602 14.6019 18.3334 9.99999 18.3334C5.39806 18.3334 1.66666 14.602 1.66666 10ZM3.08035 10C3.08035 13.8207 6.17931 16.9197 9.99999 16.9197C13.8207 16.9197 16.9196 13.8207 16.9196 10C16.9196 6.17934 13.8207 3.08038 9.99999 3.08038C6.17931 3.08038 3.08035 6.17934 3.08035 10Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.75 5.08335V10.3698L13.5875 11.9917L12.9167 13.3334L9.66458 11.5042C9.41049 11.3771 9.24999 11.1174 9.24999 10.8334V5.08335H10.75Z"
      fill="white"
    />
  </svg>
);
