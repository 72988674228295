import React, { FC, useState } from 'react';
import classes from '../Cases.module.scss';
import casePhone from 'assets/img/Partner/casePhone.png';
import casePhoneRu from 'assets/img/Partner/casePhoneRu.png';
import { getLangName } from 'tools/utils';
import { DevicePlatform } from '@type/common';
import { Button } from 'components/shared/Button';
import clsx from 'clsx';
import { SmallPurpleArrowDownIcon } from 'components/shared/Icons/SmallPurpleArrowDownIcon';
import { Case, InternalCaseKPI } from '@type/partner';
import { AMOUNT_TYPE } from 'constants/enum';
import { formatCasesForLocale } from '../formattedCases';
import { Modal } from 'components/shared/Modal';
import UiIcon from 'components/shared/Icon';
import { SingleCaseProps } from './SingleCase.props';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useCommonStore } from 'contexts/CommonContext';
import { RUSSIAN_LOCALE } from 'utils';

const SingleCase: FC<SingleCaseProps> = observer(({ singleCase }) => {
  const [showMore, setShowMore] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const { isSmallTablet } = useWindowWidth();
  const { platforms } = useCommonStore();
  const intersection = (currentCase: Case) =>
    _.intersectionBy(platforms, currentCase?.platforms, 'id');
  const { t, i18n } = useTranslation();
  const caseImage = RUSSIAN_LOCALE ? casePhoneRu : casePhone;

  return (
    <div className={classes.case}>
      <div className={classes['image-wrapper']}>
        <img className={classes.image} src={caseImage} alt="" />
      </div>

      <div className={classes.info}>
        <div className={classes.head}>
          <div className={classes.type}>
            {getLangName(singleCase, 'keyAchievements', i18n)}
          </div>

          <div className={classes.tags}>
            {intersection(singleCase)?.map((tag: DevicePlatform) => {
              if (tag?.image) {
                return (
                  <div className={classes.tag_img} key={tag?.id}>
                    <img
                      className={classes.platform}
                      src={tag?.image?.toLowerCase()}
                      alt=""
                    />
                  </div>
                );
              } else {
                return (
                  <div key={tag?.id} className={classes.tag}>
                    {getLangName(tag, 'name', i18n)}
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div className={classes.body}>
          <div
            className={classes.name}
            dangerouslySetInnerHTML={{
              __html: getLangName(singleCase, 'secondStepDescription', i18n),
            }}
          />

          {!showMore && !isSmallTablet ? (
            <Button
              onClick={() => setShowMore(true)}
              className={classes.button}
              theme="text"
            >
              {t('Show more')}
              <div className={clsx(classes['button-icon'], classes.rotate)}>
                <SmallPurpleArrowDownIcon />
              </div>
            </Button>
          ) : (
            !isSmallTablet && (
              <Button
                onClick={() => setShowMore(false)}
                className={classes.button}
                theme="text"
              >
                {t('Show less')}
                <div className={clsx(classes['button-icon'])}>
                  <SmallPurpleArrowDownIcon />
                </div>
              </Button>
            )
          )}
          {isSmallTablet && (
            <Button
              onClick={() => setReadMore(true)}
              className={classes.button}
              theme="text"
            >
              {t('Show more')}
              <div className={clsx(classes['button-icon'], classes.rotate)}>
                <SmallPurpleArrowDownIcon />
              </div>
            </Button>
          )}
        </div>

        {showMore && !isSmallTablet && (
          <div className={classes.kpises}>
            {singleCase?.kpi?.map((kpi: InternalCaseKPI) => (
              <div className={classes.kpi} key={kpi?.name}>
                <div className={classes.description}>{kpi?.name}</div>

                <div className={classes.amount}>
                  {
                    AMOUNT_TYPE[
                      kpi?.valueType as unknown as keyof typeof AMOUNT_TYPE
                    ]
                  }
                  {kpi?.value &&
                    kpi?.unit &&
                    formatCasesForLocale(kpi?.value, kpi?.unit, 2)}
                </div>
              </div>
            ))}
          </div>
        )}

        <Modal
          title={''}
          isOpen={readMore}
          className={classes.del_modal}
          onClose={() => setReadMore(false)}
        >
          <div className={classes.modalContainer}>
            <div className={classes.modalInner}>
              <span className={classes.modalTitle}>
                {getLangName(singleCase, 'caseName', i18n)}
              </span>
            </div>

            <div className={classes.kpises}>
              {singleCase?.kpi?.map((kpi: InternalCaseKPI, index) => (
                <div className={classes.kpi} key={kpi?.name + index}>
                  <div className={classes.description}>{kpi?.name}</div>
                  <div className={classes.amount}>
                    {
                      AMOUNT_TYPE[
                        kpi?.valueType as unknown as keyof typeof AMOUNT_TYPE
                      ]
                    }
                    {kpi?.value &&
                      kpi?.unit &&
                      formatCasesForLocale(kpi?.value, kpi?.unit, 2)}
                  </div>
                </div>
              ))}
            </div>

            <button
              className={classes.modalClose}
              onClick={() => setReadMore(false)}
            >
              <UiIcon
                name="CrossIcon"
                additionalClassName={classes['close-icon']}
              />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
});

export default SingleCase;
