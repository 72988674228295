import classes from './ProjectGoals.module.scss';
import { Project } from '@type/projects';

type ProjectGoalsProps = {
  project: Project | null;
};

function ProjectGoals({ project }: ProjectGoalsProps) {
  return (
    <ul className={classes['goals-list']}>
      {project?.goals.map((goal, index) => (
        <li key={goal.id} className={classes['goals-list__item']}>
          <div className={classes['goals-list__item_number']}>{index + 1}</div>
          <span className={classes['goals-list__item_label']}>{goal.name}</span>
        </li>
      ))}
    </ul>
  );
}

export default ProjectGoals;
