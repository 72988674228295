import clsx from 'clsx';
import classes from './ColorSticker.module.scss';
import { RUSSIAN_LOCALE } from 'utils';
export type StickerColor = 'purple' | 'yellow' | 'green' | 'red';

type ColorStickerProps = {
  color: StickerColor;
  text: string;
};

const getColorClass = function (color: StickerColor) {
  switch (color) {
    case 'purple':
      return RUSSIAN_LOCALE ? 'sticker_purple' : 'sticker_purple_global';
    case 'yellow':
      return 'sticker_yellow';
    case 'green':
      return 'sticker_green';
    case 'red':
      return 'sticker_red';
    default:
      return 'sticker_purple';
  }
};

function ColorSticker({ color, text }: ColorStickerProps) {
  return (
    <div className={clsx(classes.sticker, classes[getColorClass(color)])}>
      <span className={classes.sticker__text}>{text}</span>
    </div>
  );
}

export default ColorSticker;
