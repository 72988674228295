import { useTranslation } from 'react-i18next';
import { SolutionType } from 'types/partner';

export const useServiceInfo = (data: SolutionType) => {
  const { t } = useTranslation();

  const checkDiscount =
    data?.has_active_discounts &&
    Number(data.cost) !== Number(data.cost_with_discounts)
      ? Number(data.cost_with_discounts)
      : Number(data.cost);

  return {
    t,
    checkDiscount,
  };
};
