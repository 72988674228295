import clsx from 'clsx';
import React from 'react'
import { LABELS } from 'constants/onboarding';
import Modals from 'containers/AddSolution/Modals';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddCaseForm } from '../../AddCaseForm';
import classes from '../FormBlock.module.scss';
import EmptyCase from './EmptyCase/EmptyCase';
import { SecondStepProps } from './SecondStep.props';
import { useSecondStep } from './useSecondStep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { completeOnboarding, findOnboardingSteps, formatSteps, OnboardingSteps } from 'tools/onboarding';

const SecondStep = ({
  setOpenCancelModal,
  disabled,
  setCurrentStep,
  defaultValues,
  platforms,
  currentStep,
  setOpenCasesModal,
  openCasesModal,
  isShowHits = false
}: SecondStepProps) => {
  const { t } = useTranslation();
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const fieldRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const {
    fields,
    deleteStep,
    handleExampleLoaded,
    addStep,
    onValidateClick,
  } = useSecondStep(
    disabled,
    setCurrentStep,
    defaultValues,
    setOpenCasesModal,
    openCasesModal
  );
  const { isSmallLaptop: isLaptop } = useWindowWidth();

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);

  const onboardingPath = useMemo(() => {
    if (fields?.length < 1) {
      return 'ADD_CASES_EMPTY_P'
    }
    return 'ADD_CASES_P'
  }, [currentStep, fields]);
  

  const completedOnboarding = useRef<string[]>([]);

  useEffect(() => {
    if (!isShowHits) return
    findOnboardingSteps(onboardingPath).then((res) => {
      setOnboarding(res);
    });
  }, [onboardingPath, isShowHits]);

  const completeCurrentOnboarding = useCallback(() => {
    if (completedOnboarding.current.includes(onboardingPath)) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    completedOnboarding.current.push(onboardingPath);
  }, [onboarding?.id, onboardingPath]);

  return (
    <>
      <div
        className={clsx(
          classes.secondStep,
          fields?.length !== 0 && disabled && classes.disabled
        )}
      >

      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

        <div>
          {fields?.length
            ? fields.map((field, index) => (
                <Controller
                  key={field.id}
                  render={() => (
                    <AddCaseForm
                      key={field.id}
                      caseNumber={index}
                      onRemoveCase={() => {
                        deleteStep(index);
                      }}
                      platforms={platforms}
                      caseItem={field}
                      disabled={disabled}
                      fieldRefs={fieldRefs}
                      currentStep={currentStep}
                    />
                  )}
                  name={`cases.${index}`}
                />
              ))
            : null}
        </div>

        {!disabled && (
          <EmptyCase
            handleExampleLoaded={handleExampleLoaded}
            disabled={disabled}
            addStep={(e) => {
              addStep(e);
            }}
            setCurrentStep={setCurrentStep}
            setOpenCancelModal={setOpenCancelModal}
            onValidateClick={onValidateClick}
          />
        )}

        {disabled && fields?.length === 0 && (
          <EmptyCase
            handleExampleLoaded={handleExampleLoaded}
            disabled={disabled}
            addStep={addStep}
            setCurrentStep={setCurrentStep}
            setOpenCancelModal={setOpenCancelModal}
            onValidateClick={onValidateClick}
            
          />
        )}
      </div>

      <Modals
        openCasesModal={openCasesModal}
        setOpenCasesModal={setOpenCasesModal}
        onSubmitCasesModal={addStep}
      />
    </>
  );
};

export default SecondStep;
