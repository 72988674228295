import React, { FC } from 'react';
import '../styles/main.scss';
import { useTranslation } from 'react-i18next';
import { PartnerContainer } from '../containers/PartnerContainer';

export const PartnerPage: FC = () => {
  const { t } = useTranslation();

  return <PartnerContainer title={t('Solutions')} />;
};
