import React from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const DotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="dots-vertical">
      <g id="Icon">
        <path
          d="M9.99996 10.8335C10.4602 10.8335 10.8333 10.4604 10.8333 10.0002C10.8333 9.53993 10.4602 9.16683 9.99996 9.16683C9.53972 9.16683 9.16663 9.53993 9.16663 10.0002C9.16663 10.4604 9.53972 10.8335 9.99996 10.8335Z"
          stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M9.99996 5.00016C10.4602 5.00016 10.8333 4.62707 10.8333 4.16683C10.8333 3.70659 10.4602 3.3335 9.99996 3.3335C9.53972 3.3335 9.16663 3.70659 9.16663 4.16683C9.16663 4.62707 9.53972 5.00016 9.99996 5.00016Z"
          stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M9.99996 16.6668C10.4602 16.6668 10.8333 16.2937 10.8333 15.8335C10.8333 15.3733 10.4602 15.0002 9.99996 15.0002C9.53972 15.0002 9.16663 15.3733 9.16663 15.8335C9.16663 16.2937 9.53972 16.6668 9.99996 16.6668Z"
          stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
