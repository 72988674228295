import React, { FC } from 'react';
import classes from './Fader.module.scss';
import classnames from 'classnames';

interface IFader {
  children: any;
  active: boolean;
}

export const Fader: FC<IFader> = ({ children, active }) => {
  const modifyChild = (child: any) => {
    const className = classnames(child.props.className, {
      [classes.loading]: active,
      [classes.loading_done]: !active,
    });

    const props = {
      ...child.props,
      className: className,
    };

    return React.cloneElement(child, props);
  };

  return React.Children.map(children, (child) => modifyChild(child));
};
