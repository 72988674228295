import * as React from 'react';
import { SVGProps } from 'react';

export const CheckIconSecond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g id="check">
      <path
        id="Icon"
        d="M16.6667 5L7.49999 14.1667L3.33333 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
