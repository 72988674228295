import { TFunction } from 'i18next';
import classes from './CheckboxFilterList.module.scss';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';

type CheckBoxFilterOption = {
  value: string;
  label: string;
};

type CheckboxFilterListProps = {
  options: CheckBoxFilterOption[];
  additionalClass?: string;
  checked?: string[];
  onCheckedChange: Dispatch<SetStateAction<string[]>>;
  t: TFunction;
};

function CheckboxFilterList(props: CheckboxFilterListProps) {
  const callbacks = {
    onSelectAll: () => {
      if (props.checked?.length === props.options.length) {
        props.onCheckedChange([]);
      } else {
        props.onCheckedChange(props.options.map((option) => option.value));
      }
    },
    onSelect: (val: string) => {
      props.onCheckedChange((prevChecked) => {
        if (prevChecked.includes(val)) {
          return prevChecked.filter((checked) => checked !== val);
        } else {
          return [...prevChecked, val];
        }
      });
    },
  };

  return (
    <ul className={clsx(classes.filter, props.additionalClass)}>
      <li key={-1} className={classes.filter__item}>
        <Checkbox
          className={classes.checkbox}
          onChange={callbacks.onSelectAll}
          checked={props.checked?.length === props.options.length}
          label={props.t('All')}
        />
      </li>
      {props.options?.map((option) => (
        <li key={option.value} className={classes.filter__item}>
          <Checkbox
            className={classes.checkbox}
            onChange={() => callbacks.onSelect(option.value)}
            checked={props.checked?.includes(option.value)}
            label={option.label}
          />
        </li>
      ))}
    </ul>
  );
}

export default CheckboxFilterList;
