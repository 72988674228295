import React, { FC, useEffect } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';
import 'styles/main.scss';
import { AccountContainer } from 'containers/Account';

interface Props {
  section: string;
}

export const Account: FC<Props> = ({ section }) => {
  const navigate = useNavigate();

  const { isMediaTablet } = useWindowWidth();

  useEffect(() => {
    if (!section) {
      !isMediaTablet && navigate('personal');
    }
  }, [isMediaTablet]);

  return <AccountContainer section={section} />;
};
