import { Button } from 'components/shared/Button';
import classes from './PaymentCard.module.scss';
import { MasterCardIcon } from 'components/shared/Icons/MasterCardIcon';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import { VisaIcon } from 'components/shared/Icons/VisaIcon';
import { ApplePayIcon } from 'components/shared/Icons/ApplePayIcon';
import { GooglePayIcon } from 'components/shared/Icons/GooglePayIcon';
import { PaymentCardProps } from './PaymentCard.props';
import { useTranslation } from 'react-i18next';

function PaymentCard({
  infoTitle,
  infoDownloadText,
  infoDesc,
}: PaymentCardProps) {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.info}>
        <div>
          <span className={classes.info__title}>{infoTitle}</span>
          <span className={classes.info__description}>{infoDesc}</span>
        </div>

        <Button theme="default">
          <span>{infoDownloadText}</span>
          <DownloadIcon />
        </Button>
      </div>

      <ul className={classes.paylist}>
        <li key={'card-1'} className={classes.paylist__item}>
          <Button theme="default">
            <span>{t('Pay with card')}</span>
            <div>
              <MasterCardIcon />
              <VisaIcon />
            </div>
          </Button>
        </li>

        <li key={'apple-2'} className={classes.paylist__item}>
          <Button theme="default">
            <span>{t('Pay with')}</span>
            <ApplePayIcon />
          </Button>
        </li>

        <li key={'google-3'} className={classes.paylist__item}>
          <Button theme="default">
            <span>{t('Pay with')}</span>
            <GooglePayIcon />
          </Button>
        </li>
      </ul>
    </div>
  );
}

export default PaymentCard;
