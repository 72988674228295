import { yupResolver } from '@hookform/resolvers/yup';
import { urlCheckReg } from 'constants/matches';
import { useUserStore } from 'contexts/UserContext';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { getLangName } from 'tools/utils';
import * as yup from 'yup';
import { useCommonStore } from 'contexts/CommonContext';
import { onDelete, onSelect } from '../../AddSolution/utils';
import { MultiSelectOption } from 'components/shared/MultiSelect';
import { useAccountContainer } from '../useAccountContainer';
import { BusinessFormValues } from './Business.props';
import { CompanyProduct } from '@type/common';

export const useBusiness = (
  defaultValue: any,
  getParams: () => Promise<void>
) => {
  const { t, i18n } = useTranslation();
  const userStore = useUserStore();
  const { platforms, products } = useCommonStore();
  const { formatDictionaryTypes } = useAccountContainer();
  const { countries } = useCommonStore();

  const productsOptions = formatDictionaryTypes(products);

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('This field is required')),
    companyLink: yup
      .string()
      .matches(urlCheckReg, t('Invalid link'))
      .required(t('This field is required')),
    companyLocation: yup.object().nullable().optional(),
    companyProducts: yup.array().of(yup.string()).optional(),
    companyId: yup.string().optional(),
  });

  const partner = userStore.partners?.results?.[0];

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<BusinessFormValues>({
    resolver: yupResolver(validationSchema as any),
    mode: 'all',
    defaultValues: {
      companyName: defaultValue?.companyName || '',
      companyLink: defaultValue?.companyLink || '',
      companyLocation:
        countries?.find(
          (country) => country?.id === defaultValue?.companyLocation
        ) || null,
      companyProducts: defaultValue?.companyProducts || [],
      companyId: defaultValue?.companyId || '-',
    },
  });

  const nameValue = watch('companyName');
  const linkValue = watch('companyLink');
  const locationValue = watch('companyLocation');
  const productsValue = watch('companyProducts') as unknown as CompanyProduct[];
  const companyIdValue = watch('companyId');

  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const [internalProducts, setInternalProducts] = useState<MultiSelectOption[]>(
    []
  );
  const [selectProducts, setSelectProducts] = useState<any[]>([]);

  const {
    companySize = '',
    businessType = '',
    companyLocation,
  } = defaultValue ?? {};
  const [companyLink, setCompanyLink] = useState<string>(
    defaultValue?.companyLink || ''
  );

  useEffect(() => {
    products && setInternalProducts(productsOptions);
  }, [products]);

  const [businessTypes, setBusinessTypes] = useState<any[]>([]);
  const [selectedCompanyLocation, setSelectedCompanyLocation] =
    useState<any>(companyLocation);

  const [selectedBusinessType, setSelectedBusinessType] = useState<any>();
  const [isFieldEmpty, setIsFieldEmpty] = useState({
    size: false,
    industry: false,
  });
  const [notice, setNotice] = useState({ type: '', text: '' });
  const [disableSubmit, setDisableSubmit] = useState(true);

  const platformsData = platforms.map((item) => ({
    id: item?.id,
    name: getLangName(item, 'name', i18n),
  }));

  useEffect(() => {
    notice && getParams();
  }, []);

  useEffect(() => {
    if (
      defaultValue &&
      nameValue &&
      linkValue &&
      locationValue &&
      companyIdValue &&
      productsValue &&
      (defaultValue.companyName !== nameValue ||
        defaultValue.companyLink !== linkValue ||
        defaultValue.companyLocation !== locationValue ||
        defaultValue.companyProducts.length !== productsValue?.length)
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [
    companyIdValue,
    defaultValue,
    linkValue,
    locationValue,
    nameValue,
    productsValue,
  ]);

  useEffect(() => {
    setIsFieldEmpty({
      size: !selectedCompanyLocation && !companySize,
      industry: !selectedBusinessType && !businessType,
    });
  }, [
    selectedCompanyLocation,
    selectedBusinessType,
    companySize,
    businessType,
  ]);

  const submit = async () => {
    const formValues = new FormData();

    formValues.append('company_name', nameValue);
    formValues.append('company_link', linkValue);
    (selectedCompanyLocation?.id || companyLocation) &&
      formValues.append(
        'company_size',
        selectedCompanyLocation?.id || companySize
      );
    (selectedBusinessType?.id || businessType) &&
      formValues.append(
        'company_industry',
        String(selectedBusinessType?.id || businessType)
      );

    try {
      const result = await Request({
        type: 'PUT',
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      await userStore.updateUser(result?.user);
      ShowNotification({
        type: 'success',
        children: 'Profile successfully changed',
      });
      setDisableSubmit(true);
    } catch {
      ShowNotification({ type: 'error', children: 'Something went wrong' });
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)

      .catch((error) => {
        console.error('Ошибка копирования:', error);
      });
  };

  const callbacks = {
    onSelectProducts: (id: number | string, onChange: any) =>
      onSelect(id, productsOptions, setSelectProducts, onChange),
    onDeleteProducts: (id: number | string, onChange: any) =>
      onDelete(id, setSelectProducts, onChange),
  };

  return {
    t,
    handleSubmit,
    submit,
    errors,
    control,
    setCompanyLink,
    companyLink,
    setSelectedBusinessType,
    businessTypes,
    setSelectedCompanyLocation,
    notice,
    handleCopy,
    platformsData,
    callbacks,
    internalProducts,
    setInternalProducts,
    selectProducts,
    productsOptions,
    isValid,
    disableSubmit,
    partner,
    countries,
    i18n,
  };
};
