import React from 'react'
import exampleCaseImage from 'assets/img/Partner/exampleCase.png';
import exampleCaseImageRu from 'assets/img/Partner/exampleCaseRu.png';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from '../../FormBlock.module.scss';
import { EmptyCaseProps } from './EmptyCase.props';
import { useFormBlock } from '../../useFormBlock';
import { useFormContext } from 'react-hook-form';
import { usePartnerStore } from '../../../../../contexts/PartnerContext';

const EmptyCase = ({
  handleExampleLoaded,
  disabled,
  addStep,
  setCurrentStep,
  setOpenCancelModal,
  onValidateClick,
}: EmptyCaseProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trigger } = useFormContext();
  const { isCompletedFormSteps } = usePartnerStore();

  return (
    <>
      <div data-step-add_cases_empty_p-0 className={classes.exampleBlock} id="emptyCaseId">
        <img
          onLoad={handleExampleLoaded}
          className={
            RUSSIAN_LOCALE
              ? classes.exampleBlock__imageRu
              : classes.exampleBlock__image
          }
          src={RUSSIAN_LOCALE ? exampleCaseImageRu : exampleCaseImage}
          alt="Example case image"
        />
        <div className={classes.addCaseBlock}>
          <span className={classes.exampleBlock__description}>
            {!disabled &&
              t(
                'So that clients can properly evaluate your competencies, add cases that will be displayed in the solution card'
              )}
            {disabled &&
              t(
                'You currently have no cases added. You can prove your expertise by adding cases to this solution.'
              )}
          </span>
          {!disabled && (
            <Button
              theme="primary"
              className={classes.mainButton}
              onClick={(e) => {
                disabled ? navigate('/solutions/add-solution') : addStep(e);
                e.preventDefault();
              }}
            >
              <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
              {t('Add new case')}
            </Button>
          )}
        </div>
      </div>
      {!disabled && (
        <div className={classes.bottomBlock}>
          <div className={classes.left}>
            <Button
              className={classes.button}
              theme="light"
              onClick={async (e: any) => {
                e.preventDefault();
                setCurrentStep?.(1);
                const isValid = await trigger();
                if (!isValid) {
                  isCompletedFormSteps.stepTwo = false;
                }
              }}
            >
              <PurpleArrowLeftIcon />
              {t('Back')}
            </Button>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>
          </div>
          <Button
            className={classes.button}
            theme="default"
            onClick={async (e: any) => {
              e.preventDefault();
              await onValidateClick();
            }}
          >
            {t('Continue')}
          </Button>
        </div>
      )}
    </>
  );
};

export default EmptyCase;
