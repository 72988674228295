import clsx from 'clsx';
import { Link } from 'components/shared/Link';
import { getFormattedDate } from 'tools/formattedDate';
import classes from './DocumentsList.module.scss';
import { DocumentsListProps } from './DocumentsList.props';

function DocumentsList({ documents, additionalClass }: DocumentsListProps) {
  return (
    <ul className={clsx(classes.documents, additionalClass)}>
      {documents.map((doc) => (
        <li key={doc.id} className={classes.documents__item}>
          <Link
            href={process.env.REACT_APP_API + doc.link}
            target="_blank"
            className={classes.documents__title}
          >
            {doc.name}
          </Link>

          <span className={classes.documents__size}>
            {doc.size / 1024 / 1024}Mb
          </span>

          <div className={classes.dashed} />

          <span className={classes.documents__date}>
            {getFormattedDate(new Date(doc.date))}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default DocumentsList;
