import { Button } from 'components/shared/Button';
import { FeedbackModal } from 'components/shared/FeedbackModal';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { Textarea } from 'components/shared/NewInput/TextArea';
import { Logo } from 'components/common/Logo';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './CardManager.module.scss';
import { CardManagerProps } from './CardManager.props';
import { useCardManager } from './useCardManager';
import defaultImageBlue from 'assets/img/Managers/Risa-2.png';
import defaultImageRed from 'assets/img/Managers/Eugeniy.png';
import clsx from 'clsx';

export const CardManager = ({
  alt,
  forAccountPage,
  fullName,
  src,
}: CardManagerProps) => {
  const {
    t,
    confirmFeedback,
    closeModal,
    openModal,
    setOpenModal,
    questionValue,
    bigQuestionLength,
    setQuestionValue,
    questionErrorMessage,
    setSourceValue,
    sourceErrorMessage,
    sourceValue,
    disabled,
    bigSourceLength,
    sendRequest,
  } = useCardManager();

  const defaultImage = RUSSIAN_LOCALE ? defaultImageRed : defaultImageBlue;
  const imageSrc = src || defaultImage;
  const defaultName = fullName || `${RUSSIAN_LOCALE ? 'Евгений' : 'Risa'}`;

  return (
    <>
      <div
        className={clsx(
          classes.container,
          forAccountPage && classes.container_accountPage
        )}
      >
        {forAccountPage ? (
          <>
            <div className={classes.wrapperTitle}>
              <img
                className={clsx(
                  classes.image,
                  forAccountPage && classes.imageForAccountPage
                )}
                src={imageSrc}
                alt={alt || 'default avatar'}
              />
              <div className={classes.wrapperContent}>
                <h4 className={classes.title}>{t(fullName || '')}</h4>
                <p className={classes.text}>{t('Personal manager')}</p>
              </div>
            </div>
            {/*<Button*/}
            {/*  theme={RUSSIAN_LOCALE ? 'primary-light' : 'primary'}*/}
            {/*  className={RUSSIAN_LOCALE ? classes.button : classes.buttonEn}*/}
            {/*  onClick={(e) => {*/}
            {/*    e.preventDefault();*/}
            {/*    setOpenModal(true);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {t('Contact manager')}*/}
            {/*</Button>*/}
          </>
        ) : (
          <>
            <img
              className={classes.image}
              src={defaultImage || ''}
              alt={alt || 'default avatar'}
            />
            <h4
              className={classes.title}
            >{`${t('Hi! My name is')} ${defaultName}`}</h4>
            <p className={classes.text}>
              {t(
                'I am ready to help you to make the right choice. Feel free to ask me any questions about this solution'
              )}
            </p>

            <Button theme="text" className={classes.button}>
              {t('Get in touch')}
            </Button>
          </>
        )}
      </div>

      <FeedbackModal isOpen={openModal} onClose={closeModal}>
        <div className={classes.modal}>
          {!confirmFeedback ? (
            <>
              <div className={classes.header}>
                <div className={classes.wrapper}>
                  <Logo />
                  <div
                    role="button"
                    onClick={closeModal}
                    className={classes.close}
                  >
                    <CrossIcon />
                  </div>
                </div>
              </div>

              <h4 className={classes.modalTitle}>
                {t('Request for a consultation')}
              </h4>

              <div className={classes.modalBlock}>
                <div className={classes.textareaCustomBig}>
                  <Textarea
                    label={t('Your question')}
                    value={questionValue}
                    error={questionErrorMessage}
                    title={t('What would you like to know about this solution')}
                    placeholder={t(
                      'What would you like to know about this solution'
                    )}
                    onChange={setQuestionValue}
                    maxLength={700}
                  />
                  {bigQuestionLength ? (
                    <p className={classes.error}>{questionErrorMessage}</p>
                  ) : null}
                </div>

                <div className={classes.textareaCustomNormal}>
                  <Textarea
                    label={t('Source (optional)')}
                    value={sourceValue}
                    error={sourceErrorMessage}
                    title={t('Link or a source description')}
                    placeholder={t('Link or a source description')}
                    onChange={setSourceValue}
                    maxLength={100}
                  />
                  {bigSourceLength ? (
                    <p className={classes.error}>{sourceErrorMessage}</p>
                  ) : null}
                </div>

                <p className={classes.policy}>
                  {t(
                    'By applying this form You further agree on the terms of our'
                  )}{' '}
                  <a
                    className={classes['policy__link']}
                    rel="noreferrer"
                    href={
                      RUSSIAN_LOCALE
                        ? process.env.REACT_APP_PRIVACY_POLICY_URL
                        : 'https://kit.global/privacy-policy/'
                    }
                    target="_blank"
                  >
                    {t('Privacy Policy')}
                  </a>
                </p>
              </div>

              <Button
                className={classes.modalButton}
                theme="text"
                disabled={disabled}
                onClick={sendRequest}
              >
                {t('Send request')}
              </Button>
            </>
          ) : (
            <div className={classes.modalFeedbackBlock}>
              <div className={classes.modalFeedbackImage} />
              <h4 className={classes.modalFeedbackTitle}>
                {t('Request sent')}
              </h4>
              <p className={classes.modalFeedbackText}>
                {t(
                  'Thank you, we’ll go back to you with more information within 2 working days'
                )}
              </p>

              <Button
                theme="primary"
                className={classes.modalFeedbackButton}
                onClick={closeModal}
              >
                {t('Done')}
              </Button>
            </div>
          )}
        </div>
      </FeedbackModal>
    </>
  );
};
