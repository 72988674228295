import * as React from 'react';
import { SVGProps } from 'react';

export const ApplePayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="20"
    viewBox="0 0 50 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4678_245154)">
      <path
        d="M9.55778 2.5785C8.97801 3.25404 8.05038 3.78687 7.12275 3.71075C7.0068 2.79734 7.46095 1.82683 7.9924 1.2274C8.57217 0.532826 9.58677 0.038059 10.4081 0C10.5047 0.951475 10.1279 1.88392 9.55778 2.5785ZM10.3984 3.89153C9.05532 3.81541 7.90544 4.6432 7.26769 4.6432C6.62028 4.6432 5.64434 3.92959 4.58143 3.94862C3.19964 3.96765 1.91449 4.73834 1.2091 5.96575C-0.240325 8.42055 0.832249 12.0552 2.23336 14.0533C2.91942 15.0428 3.74076 16.1275 4.823 16.0894C5.84726 16.0514 6.25309 15.4329 7.48994 15.4329C8.73644 15.4329 9.09397 16.0894 10.1762 16.0704C11.2971 16.0514 12.0025 15.0809 12.6885 14.0913C13.4712 12.9686 13.7901 11.8744 13.8094 11.8173C13.7901 11.7983 11.645 10.9895 11.6256 8.55376C11.6063 6.5176 13.3166 5.5471 13.3939 5.49001C12.4276 4.08183 10.9202 3.92959 10.3984 3.89153ZM18.1577 1.13225V15.9657H20.4961V10.8944H23.7331C26.69 10.8944 28.7675 8.89629 28.7675 6.00381C28.7675 3.11132 26.7286 1.13225 23.8105 1.13225H18.1577ZM20.4961 3.07326H23.192C25.2212 3.07326 26.3808 4.13892 26.3808 6.01332C26.3808 7.88773 25.2212 8.96289 23.1824 8.96289H20.4961V3.07326ZM33.0385 16.0799C34.5072 16.0799 35.8697 15.3473 36.4881 14.1865H36.5364V15.9657H38.7009V8.5823C38.7009 6.44148 36.9616 5.06185 34.285 5.06185C31.8016 5.06185 29.9657 6.46051 29.898 8.38249H32.0045C32.1785 7.46908 33.0384 6.86965 34.2173 6.86965C35.6474 6.86965 36.4494 7.52617 36.4494 8.73454V9.55281L33.5313 9.72407C30.816 9.88582 29.3472 10.98 29.3472 12.883C29.3472 14.8049 30.8643 16.0799 33.0385 16.0799ZM33.6665 14.3197C32.42 14.3197 31.6277 13.7298 31.6277 12.8259C31.6277 11.8934 32.391 11.3511 33.8501 11.2655L36.4494 11.1037V11.941C36.4494 13.3302 35.2512 14.3197 33.6665 14.3197ZM41.5901 20C43.8705 20 44.9431 19.1437 45.8803 16.5461L49.987 5.20457H47.61L44.8561 13.9677H44.8078L42.0539 5.20457H39.6092L43.5709 16.0038L43.3583 16.6603C43.0008 17.7736 42.4211 18.2017 41.3871 18.2017C41.2035 18.2017 40.846 18.1827 40.7011 18.1637V19.9429C40.8364 19.981 41.4161 20 41.5901 20Z"
        fill="#323236"
      />
    </g>
    <defs>
      <clipPath id="clip0_4678_245154">
        <rect
          width="49.4737"
          height="20"
          fill="white"
          transform="translate(0.513184)"
        />
      </clipPath>
    </defs>
  </svg>
);
