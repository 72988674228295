import React, { ReactNode } from 'react';
import { CommonStore, commonStore } from 'stores/commonStore';

const CommonContext = React.createContext<CommonStore | null>(null);

export const CommonProvider = ({ children }: { children: ReactNode }) => (
  <CommonContext.Provider value={commonStore}>
    {children}
  </CommonContext.Provider>
);

export const useCommonStore = () => {
  const context = React.useContext(CommonContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with UserProvider'
    );
  }
  return context;
};
