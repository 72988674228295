import { useEffect, useMemo, useState } from 'react';
import { columnsArrayEmployees } from './employeesTableData';
// import { PARTNER_ROLE } from 'types/user';
import { useUserStore } from 'contexts/UserContext';
import { ShowNotification } from 'tools/showNotification';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const useEmployees = () => {
  const { t } = useTranslation();
  const { loadPartnerById, partners } = useUserStore();
  const partner = partners?.results?.[0];

  const columns = useMemo(() => columnsArrayEmployees(), []);
  const [clients, setClients] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    setIsLoading(true);

    try {
      const result = await loadPartnerById(partner?.id);

      setClients(result.data.users);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDeletePermissions = async (id: number, email: string) => {
    // try {
    //   const result = await User.deleteUserPermissions(client.id, email, id);
    //
    //   if (result.data.status === true) {
    //     ShowNotification({
    //       type: 'success',
    //       children: t('User successfully deleted'),
    //     });
    //     setClients(
    //       (prev) => prev?.filter((item) => item.email !== email) || []
    //     );
    //   } else {
    //     ShowNotification({
    //       type: 'error',
    //       children: t('Error deleting user'),
    //     });
    //   }
    // } catch (error) {
    //   console.error('Error deleting user permissions:', error);
    // }
  };

  const handleChangePermissions = async (
    id: number,
    role: '',
    email: string
  ) => {
    try {
      // const result = await User.changeUserPermissions(
      //   client.id,
      //   role,
      //   email,
      //   id
      // );
      //
      // if (result.data.status === true) {
      //   setClients(
      //     (prev) =>
      //       prev?.map((item) =>
      //         item.email === email
      //           ? { ...item, permission: { ...item.permission, type: role } }
      //           : item
      //       ) || []
      //   );
      // }
    } catch (error) {
      console.error('Error deleting user permissions:', error);
    }
  };

  return {
    partner,
    isLoading,
    clients,
    columns,
    handleDeletePermissions,
    getUsers,
    handleChangePermissions,
  };
};
