import classes from './Cases.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Case } from 'types/partner';
import SingleCase from './SingleCase';

interface CasesProps {
  data: Case[];
}

export const Cases = observer(({ data }: CasesProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.title}>{t('Cases')}</div>

      <div className={classes.cases}>
        {data?.map((item: Case, index: number) => (
          <SingleCase singleCase={item} key={index} />
        ))}
      </div>
    </div>
  );
});
