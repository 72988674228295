import * as React from 'react';
import { SVGProps } from 'react';

export const SortOneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8 13.3332V2.6665M8 2.6665L4 6.6665M8 2.6665L12 6.6665"
      stroke="#8362F3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
