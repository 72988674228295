import React, { FC } from 'react';
import { Modal } from '../../../components/shared/Modal';
import classes from '../FormBlock/FormBlock.module.scss';
import { Button } from '../../../components/shared/Button';
import { useTranslation } from 'react-i18next';
import MobileModal from '../../PartnerContainer/Mobile';
import { useWindowWidth } from '../../../hooks/useWindowWidth';

interface ModalsProps {
  openCancelModal?: boolean;
  setOpenCancelModal?: (val: boolean) => void;
  openSubmitModal?: boolean;
  setOpenSubmitModal?: (val: boolean) => void;
  openCasesModal?: boolean;
  setOpenCasesModal?: (val: boolean) => void;
  onSubmitCasesModal?: (e?: any) => void;
  onSubmit?: (e?: any) => void;
  onCancel?: (e?: any) => void;
  currentStep?: number;
}

const Modals: FC<ModalsProps> = ({
  currentStep,
  onCancel,
  onSubmit,
  openCancelModal,
  setOpenCancelModal,
  openSubmitModal,
  setOpenSubmitModal,
  openCasesModal,
  setOpenCasesModal,
  onSubmitCasesModal,
}) => {
  const { t } = useTranslation();
  const { isMediaTablet } = useWindowWidth();
  const submitButtonOptions = {
    submit: t('Yes, send for review'),
    cancel: t('Cancel'),
    onCancel: (e?: any) => setOpenSubmitModal?.(false),
    onSubmit: onSubmit && onSubmit,
  };
  const cancelButtonOptions = {
    submit: t('Yes, abort'),
    cancel: t('Cancel'),
    onCancel: (e?: any) => setOpenCancelModal?.(false),
    onSubmit: (e?: any) => {
      setOpenCancelModal?.(false);
      onCancel && onCancel(e);
    },
  };
  const casesButtonOptions = {
    submit: t('Add use case'),
    cancel: t('Cancel'),
    hideCancel: true,
    onSubmit: (e?: any) => {
      setOpenCasesModal?.(false);
      onSubmitCasesModal?.(e);
    },
  };

  const mobileModals = () => {
    if (openSubmitModal) {
      return (
        <MobileModal
          title={t(
            'Are you sure your product is ready to be submitted for review?'
          )}
          setOpen={setOpenSubmitModal}
          open={openSubmitModal}
          isPriceTag={false}
          buttonTextOptions={submitButtonOptions}
        />
      );
    }
    if (openCancelModal) {
      return (
        <MobileModal
          title={t(
            'Are you sure you want to abort the creation of the solution?'
          )}
          setOpen={setOpenCancelModal}
          open={openCancelModal}
          isPriceTag={false}
          buttonTextOptions={cancelButtonOptions}
        />
      );
    }
    if (openCasesModal) {
      return (
        <MobileModal
          title={t(
            'You cannot proceed with completing the solution – you must add at least one use case'
          )}
          setOpen={setOpenCasesModal}
          open={openCasesModal}
          isPriceTag={false}
          buttonTextOptions={casesButtonOptions}
        />
      );
    }
  };

  return (
    <div className="">
      {isMediaTablet ? (
        mobileModals()
      ) : (
        <>
          <Modal
            title=""
            isOpen={openCancelModal}
            onClose={() => setOpenCancelModal?.(false)}
          >
            <div className={classes.modalContainer}>
              <span>
                {t(
                  'Are you sure you want to abort the creation of the solution?'
                )}
              </span>
              <div className={classes.bottomBlock}>
                <Button
                  className={classes.button}
                  theme="light"
                  onClick={cancelButtonOptions.onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  className={classes.button}
                  theme="default"
                  onClick={cancelButtonOptions.onSubmit}
                >
                  {t('Yes, abort')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            title=""
            isOpen={openSubmitModal}
            className={classes.submitModal}
            onClose={() => setOpenSubmitModal?.(false)}
          >
            <div className={classes.modalContainer}>
              <span>
                {t(
                  'Are you sure your product is ready to be submitted for review?'
                )}
              </span>
              <div className={classes.bottomBlock}>
                <Button
                  className={classes.button}
                  theme="light"
                  onClick={() => setOpenSubmitModal?.(false)}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  className={classes.button}
                  theme="default"
                  type="button"
                  onClick={onSubmit}
                >
                  {t('Yes, send for review')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            title=""
            isOpen={openCasesModal}
            className={classes.submitModal}
            onClose={() => setOpenCasesModal?.(false)}
          >
            <div className={classes.modalContainer}>
              <span>
                {t(
                  'You cannot proceed with completing the solution – you must add at least one use case'
                )}
              </span>
              <div className={classes.bottomBlock}>
                <Button
                  className={classes.button}
                  theme="default"
                  type="button"
                  onClick={(e) => {
                    onSubmitCasesModal?.(e);
                    setOpenCasesModal?.(false);
                  }}
                >
                  {t('Add use case')}
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Modals;
