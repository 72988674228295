import React, { FC } from 'react';
import { RestoreContainer } from 'containers/Restore';
import '../styles/main.scss';

// todo добавить функцию локализации
export const Restore: FC = () => (
  <>
    <RestoreContainer />
  </>
);
