import classes from './InvoicesTable/InvoicesTable.module.scss';
import { QueryParamTypes } from 'types/global';
import { formatPrice } from 'components/shared/FormatPriceAndCurrency';
import { TFunction } from 'i18next';
import { TFinancesResponse, TInvoice } from '@type/finance';
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';

export const getPaymentDateMessage = (
  invoice: TInvoice,
  paidDate: Date,
  diffDays: TInvoice['diff_days'],
  t: TFunction
) => {
  if (invoice.status === 'PAID' && paidDate) {
    return t('Paid {{ dateString }}', {
      dateString: getFormattedDate(paidDate),
    });
  } else if (invoice.status === 'PARTIALLY_PAID' && paidDate) {
    return t('Partially paid {{ dateString }}', {
      dateString: getFormattedDate(paidDate),
    });
  } else if (!diffDays?.overdue && diffDays?.difference) {
    return t('{{ dateString }} days for payment', {
      dateString: diffDays?.difference,
    });
  } else if (diffDays?.overdue) {
    return t('Overdue by {{ dateString }} days', {
      dateString: diffDays?.difference,
    });
  } else if (!diffDays?.overdue && !diffDays?.difference) {
    return t('Due today');
  }
};

export const columnsArray = (t: TFunction, incoming: boolean) => {
  const columns = [
    {
      header: '',
      accessor: 'serialNumber',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Invoices'),
      accessor: 'invoices',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Client'),
      accessor: 'client',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Issued'),
      accessor: 'issued',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Amount'),
      accessor: 'amount',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Action'),
      accessor: 'action',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
  ];
  return incoming
    ? columns.filter((column) => column.accessor !== 'client')
    : columns;
};

export const tableData = (invoices: TInvoice[], t: TFunction) =>
  invoices?.map((invoice) => {
    const invoiceDate = new Date(invoice?.created);
    const paidDate = invoice.paid_date ? new Date(invoice.paid_date) : null;
    const diffDays = invoice.diff_days;
    const invoiceTotalAmount = formatPrice(invoice?.total);

    return {
      serialNumber: {
        value: invoice.serial_number,
        className: classes.table__header,
      },
      invoices: {
        value: invoice.invoice?.number,
        className: classes.table__header,
        filePath: invoice.file,
        description: {
          text: invoice.invoice?.description,
          className: classes.table__text,
        },
      },
      client: {
        value: invoice.client?.name,
        className: classes.table__header,
        description: {
          text: `ID ${invoice.client?.number}`,
          className: classes.table__text,
        },
      },
      issued: {
        value: getFormattedDate(invoiceDate),
        className: classes.table__header,
        description: {
          text: getFormattedTime(invoiceDate),
          className: classes.table__text,
        },
      },
      amount: {
        value: invoiceTotalAmount,
        currency: invoice.currency,
        className: classes.table__header,
        description: {
          text: getPaymentDateMessage(
            invoice,
            paidDate || new Date(),
            diffDays,
            t
          ),
          className: classes.table__text,
        },
      },
      action: {
        button: {
          text: invoice.status === 'PAID' ? t('Paid') : t('Pay now'),
          disabled: invoice.status === 'PAID',
          className: classes.table__button,
          filePath: invoice.file,
        },
      },
    };
  });

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    'quick-filter': quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};

export const MOCK_RESPONSE: TFinancesResponse = {
  next: '',
  previous: '',
  count: 8,
  total_due: {
    count: 1,
    sum_in: 120000,
  },
  legal_entity: {
    id: 999,
    name: 'Company YNAPMOC',
    tax_id: '345-234',
    kit_global_bank_details: '2374982749827428',
    legal_entity_bank_details: '3974029742833924',
    files: [
      {
        id: 1,
        name: 'NDA',
        size: 398458.88,
        date: '2024-07-14T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 2,
        name: 'IO',
        size: 2097152,
        date: '2024-07-03T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 3,
        name: 'document with long denotation',
        size: 209715.2,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 4,
        name: 'Document_456',
        size: 3145728,
        date: '2024-07-22T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 5,
        name: 'Document 93',
        size: 524288,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 6,
        name: 'Document 6',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 7,
        name: 'Document 7',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 8,
        name: 'Document 8',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 9,
        name: 'Document 9',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 10,
        name: 'Document 10',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
      {
        id: 11,
        name: 'Document 11',
        size: 2097152,
        date: '2024-07-18T20:07:36.711Z',
        link: '/media/files/TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_TEST_erDGjGY.docx',
      },
    ],
  },
  results: {
    sub_invoices: [
      {
        id: 111,
        serial_number: '1',
        status: 'CREATED',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 5,
        },
        total: 120000,
        payment_made: '992395053893474014',
        is_paid: false,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 100,
          number: '74892374982',
          description: 'Make a brief description of the project',
        },
      },
      {
        id: 222,
        serial_number: '2',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-29T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 7,
        },
        total: 140000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 200,
          number: '2374982420',
          description: 'Make a description of the project',
        },
      },
      {
        id: 300,
        serial_number: '3',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-30T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 6,
        },
        total: 90000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 300,
          number: '342972903402',
          description: 'Make a brief of the project',
        },
      },
      {
        id: 444,
        serial_number: '4',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 2,
        },
        total: 11000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 1211,
          number: '74892374982',
          description: 'Make a brief description of the project',
        },
      },
      {
        id: 555,
        serial_number: '5',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 10,
        },
        total: 9000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 23133,
          number: '74892374982',
          description: 'Make a brief description of the project',
        },
      },
      {
        id: 666,
        serial_number: '1',
        status: 'CREATED',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 10,
        },
        total: 120000,
        payment_made: '992395053893474014',
        is_paid: false,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 445,
          number: '94892374982',
          description: 'Make a brief description of the project',
        },
        client: {
          id: 123,
          name: 'Name of client',
          number: '234-134',
        },
      },
      {
        id: 777,
        serial_number: '2',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 10,
        },
        total: 28000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 331133,
          number: '74892374982',
          description: 'Make a brief description',
        },
        client: {
          id: 212121,
          name: 'Name of client',
          number: '284-34',
        },
      },
      {
        id: 888,
        serial_number: '3',
        status: 'PAID',
        created: '2024-07-18T20:07:36.711Z',
        date: '2024-07-18T20:07:36.711Z',
        due_date: '2024-07-25T20:07:36.711Z',
        paid_date: '2024-07-18T20:07:36.711Z',
        diff_days: {
          overdue: false,
          difference: 10,
        },
        total: 31000,
        payment_made: '992395053893474014',
        is_paid: true,
        file: 'somefile.pdf',
        currency: {
          id: 1,
          char_code: 'USD',
          name: 'United States dollar',
          round_until: undefined,
          symbol: '$',
        },
        invoice: {
          id: 885588,
          number: '74892374982',
          description: 'Make a brief description of the project',
        },
        client: {
          id: 444333444,
          name: 'Pablo Diego Jose Francisco de Paula Juan',
          number: '134-139',
        },
      },
    ],
  },
};