import {
  lettersCheckReg,
  numbersCheckReg,
  symbolsCheckReg,
} from 'constants/matches';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useStepOne = (password: string) => {
  const { t, i18n } = useTranslation();

  const [lengthCheck, setLengthCheck] = useState(false);
  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [letterCheck, setLetterCheck] = useState(false);
  const [invSymbCheck, setInvSymbCheck] = useState(false);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
    if (lettersCheckReg.test(password)) {
      setLetterCheck(true);
    } else {
      setLetterCheck(false);
    }
    if (password.length >= 8) {
      setLengthCheck(true);
    } else {
      setLengthCheck(false);
    }
    if (!password.includes('.')) {
      setInvSymbCheck(true);
    } else {
      setInvSymbCheck(false);
    }
  }, [password, setNumbersCheck, setSymbolsCheck, setLengthCheck]);

  const passwordRules = [
    {
      label: t('At least 8 characters long'),
      check: lengthCheck,
    },
    {
      label: t('Contains numbers'),
      check: numbersCheck,
    },
    {
      label: t('Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।'),
      check: symbolsCheck,
    },
    {
      label: t('Password must contain at least 1 letter'),
      check: letterCheck,
    },
  ];

  return { t, invSymbCheck, passwordRules, i18n };
};
