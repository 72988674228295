import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { HeartIcon } from 'components/shared/Icons/HeartIcon';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classes from './Price.module.scss';
import { PriceProps } from './Price.props';
import { RUSSIAN_LOCALE } from 'utils';

export const Price = observer(({ data, className }: PriceProps) => {
  const { t } = useTranslation();

  const formattedPrice = (price?: number | string, withMinus?: boolean) => {
    if (price) {
      if (typeof price === 'string') {
        price = Number(price.replace(/\s/g, ''));
      }

      return (
        <FormatPrice
          currency={RUSSIAN_LOCALE ? '₽' : '$'}
          price={price}
          withMinus={withMinus}
        />
      );
    } else {
      return 0;
    }
  };

  return (
    <>
      <div
        className={clsx(
          className && classes[className],
          classes['price-block']
        )}
      >
        <div className={classes.price}>
          {!data?.is_free && (
            <div className={classes.text}>{t('Budget from')}</div>
          )}

          {data?.is_free ? (
            <div className={classes.cost}>{t('Free')}</div>
          ) : (
            <div className={clsx(classes.cost)}>
              <span>{formattedPrice(data?.price, false) || 0}</span>
            </div>
          )}
        </div>
        <div className={classes.buttons}>
          <Button className={classes.addToCartBtn} theme="primary">
            {t('Add to cart')}
          </Button>

          <div className={classes.favored}>
            <Button className={classes.favorite} theme="icon-default">
              <HeartIcon />
            </Button>
          </div>
        </div>
        {!data?.is_free && <span className={classes.divider} />}
        {!data?.is_free && (
          <div className={classes.priceDesciption}>
            *{' '}
            {t(
              "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our "
            )}{' '}
            <span className={classes.disclaimer}>
              {t('Pricing Disclaimer')}
            </span>
          </div>
        )}
      </div>
    </>
  );
});
