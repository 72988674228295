import * as React from 'react';
import { SVGProps } from 'react';

export const H2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Text editor">
      <g id="H2">
        <path
          d="M3.32626 14.0909V4.54541H4.76647V8.69358H9.52522V4.54541H10.9701V14.0909H9.52522V9.92871H4.76647V14.0909H3.32626Z"
          fill="currentColor"
        />
        <path
          d="M12.6713 15.7315V15.0044L14.8526 13.0655C15.0701 12.8728 15.2519 12.7004 15.3979 12.5481C15.5439 12.3959 15.6543 12.2483 15.7288 12.1053C15.8034 11.9624 15.8407 11.8101 15.8407 11.6486C15.8407 11.3689 15.7335 11.1468 15.5191 10.9821C15.3047 10.8174 15.039 10.735 14.7221 10.735C14.3865 10.735 14.1162 10.8252 13.9111 11.0054C13.706 11.1825 13.6035 11.4155 13.6035 11.7045H12.634C12.634 11.1732 12.8313 10.7459 13.2259 10.4228C13.6206 10.0965 14.1255 9.93337 14.7407 9.93337C15.1509 9.93337 15.5113 10.0079 15.822 10.1571C16.1328 10.3031 16.3751 10.5051 16.5491 10.763C16.7231 11.0178 16.8102 11.3068 16.8102 11.6299C16.8102 11.8847 16.7589 12.1209 16.6563 12.3384C16.5538 12.5559 16.3938 12.7812 16.1763 13.0142C15.9588 13.2472 15.6791 13.5129 15.3373 13.8112L14.1255 14.8739V14.9112H16.9779V15.7315H12.6713Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
