import React, { FC, ReactNode } from 'react';
import classes from './classes.module.scss';
import clsx from 'clsx';

export interface GridProps {
  children?: ReactNode;
  direction?: string;
  className?: string;
  jc?: string;
}

export interface GridItemProps {
  col?: ColsProps;
  children?: ReactNode;
  className?: string;
}

export interface ColsProps {
  default?: number;
  xxs?: number;
  xsup?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  extra?: number;
}

export const Grid: FC<GridProps> = ({
  children,
  direction,
  className: additionalClassName,
  jc,
}) => {
  const className = clsx(
    classes.gridContainer,
    direction && classes[`direction-${direction}`],
    jc && classes[`jc-${jc}`],
    additionalClassName
  );

  return <div className={className}>{children}</div>;
};

export const GridItem: FC<GridItemProps> = ({
  col,
  children,
  className: additionalClassName,
  ...props
}) => {
  const className = clsx(
    classes.gridItem,
    col?.default && classes[`default-${col.default}`],
    col?.xxs && classes[`xxs-${col.xxs}`],
    col?.xsup && classes[`xsUp-${col.xsup}`],
    col?.xs && classes[`xs-${col.xs}`],
    col?.sm && classes[`sm-${col.sm}`],
    col?.md && classes[`md-${col.md}`],
    col?.lg && classes[`lg-${col.lg}`],
    col?.xl && classes[`xl-${col.xl}`],
    col?.xxl && classes[`xxl-${col.xxl}`],
    col?.extra && classes[`extra-${col.extra}`],
    additionalClassName
  );

  return <div className={className} {...props}>{children}</div>;
};
