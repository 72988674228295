import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import classes from './RegistrationForm.module.scss';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { RegistrationFormProps } from './RegistrationForm.props';
import { useRegistrationForm } from './useRegistrationForm';
import React from 'react';

export const RegistrationForm = observer(
  ({ step, setStep, setSendEmail, setSuccessData }: RegistrationFormProps) => {
    const {
      handleSubmit,
      onSubmit,
      control,
      errors,
      password,
      setPassword,
      isValid,
      recaptchaRef,
      setRecaptcha,
      recaptcha,
      selectedProducts,
      setSelectedProducts,
      commonStore,
      onLoadProducts,
      selectedLocation,
      setSelectedLocation,
    } = useRegistrationForm(step, setStep, setSendEmail, setSuccessData);

    const [check, setCheck] = useState(false);
    const formRef = useRef(null);
    const [isStepTwoValid, setIsStepTwoValid] = useState(false);

    return (
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <div className={clsx(classes.step, step === 1 && classes.active)}>
          <StepOne
            control={control}
            errors={errors}
            password={password}
            setPassword={setPassword}
            setStep={setStep}
            isValid={isValid}
          />
        </div>

        <div className={clsx(classes.step, step === 2 && classes.active)}>
          <StepTwo
            ref={recaptchaRef}
            check={check}
            setCheck={setCheck}
            setRecaptcha={setRecaptcha}
            recaptcha={recaptcha}
            control={control}
            errors={errors}
            isValid={isValid}
            isStepTwoValid={isStepTwoValid}
            setIsStepTwoValid={setIsStepTwoValid}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            productsOptions={commonStore.products.map((product) => ({
              value: product.id,
              label: product.name,
            }))}
            isLoadingProducts={commonStore.loadProductsStatus.isLoading}
            onMoreProducts={onLoadProducts}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locationOptions={commonStore.countries.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
          />
        </div>
      </form>
    );
  }
);
